import { Outlet } from "react-router-dom";
import { useParams } from "react-router-dom";
// import FormCreate from "./FormCreate";
import { NavBar } from "../Components/NavBar";
// import { RoleValidationComponent } from "./Utils";
import DashboardAdmin from "./DashboardAdmin";
import DashboardFaculty from "./DashboardFaculty";
import DashboardStudent from "./DashboardStudent";
import { useEffect, useState } from "react";
import Home from "./Home";
import { isCampusLead, isHubLead } from "../Fixed Sources/accountTypes";
const RenderComponent = ({ userData }) => {
    if (userData) {
        // console.log('userData', userData)
        console.log('Role: ', userData.atype)
        const role = userData.atype;
        if (isHubLead(role)) {
            return <DashboardAdmin role={'hub-lead'} />
        }
        if (isCampusLead(role)) {
            return <DashboardAdmin role={role} />
        }
        else if (role == 'faculty') {
            return <DashboardStudent role={role} />
        }
        else if (role == 'student' || role == 'student-mentor') {
            return <DashboardStudent role={role} />
        }
    }
    return <Home />

}
const DefaultHomePage = () => {
    // const { role } = useParams();
    // console.log(role);
    // console.log('hello')
    const [userData, setUserData] = useState(null);
    const [role, setRole] = useState(null);

    useEffect(() => {
        if (userData)
            console.log('userData', userData)
    }, [userData])
    return (
        <>
            <NavBar setUserDataExternal={setUserData} />
            <RenderComponent userData={userData} />
        </>
    );
};
export default DefaultHomePage;