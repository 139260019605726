import React, { Component, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { AiOutlineHolder, AiFillCloseCircle } from "react-icons/ai";
import { makeid, processID } from "./Utils";
import { Button } from "react-bootstrap";
import { MultipleChoiceOption } from "./MultipleChoiceOption";
import theme from "../Theme/theme";
import { useFormContext } from "react-hook-form";
import { CheckBoxOption } from "./CheckBoxOption";

// fake data generator
const getItems = count =>
    Array.from({ length: count }, (v, k) => k).map(k => ({
        id: `item-${k}`,
        content: `item ${k}`
    }));

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    // padding: grid * 2,
    margin: `0 0 ${grid}px 0`,

    // change background colour if dragging
    backgroundColor: isDragging ? "white" : "#f5f7f5",
    border: `2px ${isDragging ? 'dashed' : 'solid'} ${theme.highlightColor}`,
    // borderColor: 'red',

    // styles we need to apply on draggables
    ...draggableStyle
});
const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? "lightblue" : "lightgrey",
    padding: grid,
    // width: 250,
    position: "relative"
});

const queryAttr = "data-rbd-drag-handle-draggable-id";

export const MultipleChoiceDnD = ({ question, 
    options, updateOptions, requiredOptions, 
    currentQuestionList, setCurrentQuestionList, conditionalDict
     }) => {

    // console.log('question', question)
    const [placeholderProps, setPlaceholderProps] = useState({});
    // console.log('options', options);

    let items = options.map(option => {
        const id = option.id;
        const content = option.content
        if (id.toLowerCase().includes('new-option'))
            return { id: processID(content), content: content, defaultValue: '' }
        else {
            let newItem = { id: processID(id), content: content, defaultValue: content }
            // console.log('question', question)
            // console.log('requiredOptions', requiredOptions)
            if (question.question_type == 'checkbox') {
                if (requiredOptions.includes(content)) {
                    newItem.defaultCheckValue = id;
                }
                else {
                    newItem.defaultCheckValue = false;

                }
            }
            return newItem

        }
    })
    // console.log('MultipleChoiceDND', items)
    const onDragEnd = result => {
        // dropped outside the list
        if (!result.destination) {
            return;
        }

        setPlaceholderProps({})
        // setItems(items => reorder(items, result.source.index, result.destination.index));
        // console.log(reorder(items, result.source.index, result.destination.index))
        updateOptions(reorder(items, result.source.index, result.destination.index));
    };

    const onDragUpdate = update => {
        if (!update.destination) {
            return;
        }
        const draggableId = update.draggableId;
        const destinationIndex = update.destination.index;

        const domQuery = `[${queryAttr}='${draggableId}']`;
        const draggedDOM = document.querySelector(domQuery);

        if (!draggedDOM) {
            return;
        }
        const { clientHeight, clientWidth } = draggedDOM;

        const clientY = parseFloat(window.getComputedStyle(draggedDOM.parentNode).paddingTop) + [...draggedDOM.parentNode.children]
            .slice(0, destinationIndex)
            .reduce((total, curr) => {
                const style = curr.currentStyle || window.getComputedStyle(curr);
                const marginBottom = parseFloat(style.marginBottom);
                return total + curr.clientHeight + marginBottom;
            }, 0);

        setPlaceholderProps({
            clientHeight,
            clientWidth,
            clientY,
            clientX: parseFloat(window.getComputedStyle(draggedDOM.parentNode).paddingLeft)
        });
    };

    // Normally you would want to split things out into separate components.
    // But in this example everything is just done in one place for simplicity
    return (
        <div>

            <Button className='col-md-2 button-fill-theme'
                onClick={() => {
                    const newID = makeid(5);
                    const newOptionObj = { id: 'new-option-' + newID, content: `New Option (${newID})`, defaultValue: '' }
                    // setItems([...items, newOptionObj]);
                    updateOptions([...items, newOptionObj]);
                }}
            >Add an option</Button>
            <DragDropContext onDragEnd={onDragEnd} onDragUpdate={onDragUpdate}>
                <Droppable droppableId="droppable">
                    {(provided, snapshot) => (
                        <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            style={getListStyle(snapshot.isDraggingOver)}
                        >
                            {items.map((item, index) => (
                                <Draggable key={item.id} draggableId={item.id} index={index}>
                                    {(provided, snapshot) => (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            // {...provided.dragHandleProps}
                                            style={getItemStyle(
                                                snapshot.isDragging,
                                                provided.draggableProps.style
                                            )}
                                        >
                                            <span {...provided.dragHandleProps}>
                                                {question.question_type == 'checkbox' ? <CheckBoxOption
                                                    name={`${question.questionID}-${item.id}`}
                                                    // label={`(${allQuestionTypes[questionType][0]}) Type your question: `}
                                                    className="col-md-12 my-1"
                                                    // placeholder={`${item.content}`}
                                                    placeholder={item.content.includes('New Option (') ? 'New Option' : ''}
                                                    defaultValue={item.defaultValue}
                                                    defaultCheckValue={item.defaultCheckValue}
                                                    onClick={() => {
                                                        const clickedID = item.id;
                                                        console.log(`delete  ${index}`);
                                                        const filteredList = items.filter(item => item.id != clickedID)
                                                        updateOptions(filteredList)

                                                    }}
                                                /> :
                                                    <MultipleChoiceOption
                                                        name={`${question.questionID}-${item.id}`}
                                                        // label={`(${allQuestionTypes[questionType][0]}) Type your question: `}
                                                        className="col-md-12 my-1"
                                                        // placeholder={`${item.content}`}
                                                        placeholder={item.content.includes('New Option') ? 'New Option' : ''}

                                                        defaultValue={item.defaultValue}
                                                        onClick={() => {
                                                            const clickedID = item.id;
                                                            console.log(`delete  ${index}`);
                                                            const filteredList = items.filter(item => item.id != clickedID)
                                                            updateOptions(filteredList)

                                                        }}
                                                        optionIndex={index}
                                                        q={question}
                                                        currentQuestionList={currentQuestionList}
                                                        setCurrentQuestionList={setCurrentQuestionList}
                                                        conditionalDict = {conditionalDict}
                                                    />
                                                }

                                                {/* {item.content} */}

                                            </span>
                                            {/* {item.content} */}
                                            { }

                                        </div>
                                    )}
                                </Draggable>
                            ))}

                            {provided.placeholder}
                            {/* <CustomPlaceholder snapshot={snapshot} /> */}
                            <div style={{
                                position: "absolute",
                                top: placeholderProps.clientY,
                                left: placeholderProps.clientX,
                                height: placeholderProps.clientHeight,
                                background: "tomato",
                                width: placeholderProps.clientWidth
                            }} />
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        </div>

    );
};

