import { Outlet } from "react-router-dom";
import { useParams } from "react-router-dom";
import { TableChartDashboard } from "./TableChartDashboard";
const DefaultTableCharts = () => {
    const { tableId} = useParams();
    // console.log(formId);
    return (
        <>
            {!tableId && <div>
                <TableChartDashboard />
            </div>}
            {tableId && <Outlet />}
        </>
    );
};
export default DefaultTableCharts;