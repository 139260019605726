import { Outlet } from "react-router-dom";
import { useParams } from "react-router-dom";
import FormCreate from "./FormCreate";
import TableCreate from "./TableCreate";
import { NavBar } from "../Components/NavBar";
import { RoleValidationComponent } from "./Utils";
const RenderComponent = ({ formID, action, type }) => {
  if (formID && formID != '') {
    if (formID.toLowerCase() == 'create') {
      if (type === 'form'){
        return <FormCreate formAction='create' />
      } else if (type === 'table') {
        return <TableCreate formAction='create' />
      }
      
    }
    else {
      if (action) {
        if (action.toLowerCase() == 'edit') {
          if (type === 'form') {
            return <FormCreate formAction='edit' editFormID={formID} />
          } else if (type === 'table') {
            return <TableCreate formAction='edit' editFormID={formID} />
          }
          
        } 
      }
      return <Outlet />
    }
  }
  else {
    return <></>
  }
}
const Forms = ({type = "form"}) => {
  const { formId, action } = useParams();
  console.log(formId, action);


  return (
    <>
      {/* <h2>All Forms</h2> */}
      <NavBar />
      <RenderComponent formID={formId} action={action} />
      {/* {formId && formId.toLowerCase() == 'create' ? <FormCreate /> : <Outlet />} */}
    </>
  );
};

export const Tables = () => {
  const { formId, action } = useParams();
  console.log(formId, action);


  return (
    <>
      {/* <h2>All Forms</h2> */}
      <NavBar />
      <RenderComponent formID={formId} action={action} type="table"/>
      {/* {formId && formId.toLowerCase() == 'create' ? <FormCreate /> : <Outlet />} */}
    </>
  );
};
export default Forms;