//  1. split by empty row
//  2. normalize column names by matching from data dictionary
//  3. transpose
//  4. convert to form_content format

const subset = (myArray, rowLen, colLen) => {
    let results = [];
    for (let i = 0; i < rowLen; i++) {
        results.push(myArray[i].slice(0, colLen));
    }
    return results
}

function replaceNAWithEmptyString(arr) {
    for (let i = 0; i < arr.length; i++) {
        for (let j = 0; j < arr[i].length; j++) {
            if (arr[i][j] === "N/A") {
                arr[i][j] = "";
            }
        }
    }
    return arr;
}

function trimArrayElements(arr) {
    return arr.map(subArray =>
        subArray.map(item =>
            typeof item === 'string' ? item.trim() : item
        )
    );
}

const split = rawArr => {
    let splited = {}
    let lastSplitPoint = 0
    for (let i = 0; i < rawArr.length; i++) {
        let item_name = rawArr[lastSplitPoint][0]
        if (rawArr[i].filter(e => e == "").length === rawArr[i].length) {
            splited[item_name] = rawArr.slice(lastSplitPoint, i)
            lastSplitPoint = i + 1
        }
        if (i === rawArr.length - 1 && lastSplitPoint < rawArr.length)
            splited[item_name] = rawArr.slice(lastSplitPoint, rawArr.length)
    }

    return splited
}

const normalizeColName = (splitedData, dict) => {
    let out = []
    Object.keys(splitedData).map(e => {

        splitedData[e].slice(1).map(row => {
            let temp =
                dict.filter(el => (el[0] == e && el[1] == row[0]))
            // if(!temp) temp = 'Unknown'
            if (temp[0])
                out.push([temp[0][2], ...row.slice(1)])
        })
    })
    return out
}

const transpose = array => {
    const data = array[0].map((_, colIndex) => array.map(row => row[colIndex]));
    return data
}

export const toFormContent = (data) => {
    let header = data[0]
    let form_content = [];
    data.slice(1).forEach((q, index) => {
        let structured_q = {};
        if (q.length >= 2) {
            q.forEach((col, index) => {
                const key = header[index];
                if (key && key !== "") structured_q[index] = col;
                //   Object.keys(extraData).forEach(k => structured_q[k] = extraData[k])
            });
            form_content.push(structured_q);
        }
    });
    return {
        form_content: form_content,
        form_header: header
    }
}

const dict_TIS_IPEDS_arr = [
    ['item_name', 'varname', 'standardized_varname'],
    ["General Student Data", "Students receiving disability services via this institution", "DIABILITY_COUNT"],
    ["General Student Data", "Undergraduate", "UNDER_DISABILITY_COUNT"],
    ["General Student Data", "Graduate", "GRAD_DISABILITY_COUNT"],
    ["General Ethnicity and race", "Hispanic/Latino", "DISABILITY_HISPANIC"],
    ["General Ethnicity and race", "American Indian or Alaskan Native", "DISABILITY_INDIAN"],
    ["General Ethnicity and race", "Asian", "DISABILITY_ASIAN"],
    ["General Ethnicity and race", "Black or African American", "DISABILITY_BLACK"],
    ["General Ethnicity and race", "Native Hawaiian or Other Pacific Islander", "DISABILITY_NATIVE"],
    ["General Ethnicity and race", "White", "DISABILITY_WHITE"],
    ["General Ethnicity and race", "Two or more races", "DISABILITY_MULTIRACE"],
    ["General Ethnicity and race", "Race and ethnicity unknown", "DISABILITY_UNKNOW_RACE"],
    ["General Ethnicity and race", "Not Reported", "DISABILITY_NOREPORT_RACE"],
    ["General Ethnicity and race", "Total", "DISABILITY_RACE"],
    ["All STEM Majors with Disabilities", "Undergraduate", "DISABILITY_UNDER_STEM"],
    ["All STEM Majors with Disabilities", "Graduate", "DISABILITY_GRAD_STEM"],
    ["All STEM Majors with Disabilities", "Total", "DISABILITY_TOTAL_STEM"],
    ["Gender - All STEM Majors with Disabilities", "Women", "DISABILITY_STEM_FEMALE"],
    ["Gender - All STEM Majors with Disabilities", "Men", "DISABILITY_STEM_MALE"],
    ["Gender - All STEM Majors with Disabilities", "Transgender", "DISABILITY_STEM_TRANS"],
    ["Gender - All STEM Majors with Disabilities", "Non-binary/non-conforming", "DISABILITY_STEM_NONBINARY"],
    ["Gender - All STEM Majors with Disabilities", "Not reported", "DISABILITY_STEM_NOREPORT_GENDER"],
    ["Gender - All STEM Majors with Disabilities", "Total", "DISABILITY_STEM_GENDER"],
    ["Ethnicity and race - All STEM Majors with Disabilities", "Hispanic/Latino", "DISABILITY_STEM_HISPANIC"],
    ["Ethnicity and race - All STEM Majors with Disabilities", "American Indian or Alaskan Native", "DISABILITY_STEM_INDIAN"],
    ["Ethnicity and race - All STEM Majors with Disabilities", "Asian", "DISABILITY_STEM_ASIAN"],
    ["Ethnicity and race - All STEM Majors with Disabilities", "Black or African American", "DISABILITY_STEM_BLACK"],
    ["Ethnicity and race - All STEM Majors with Disabilities", "Native Hawaiian or Other Pacific Islander", "DISABILITY_STEM_NATIVE"],
    ["Ethnicity and race - All STEM Majors with Disabilities", "White", "DISABILITY_STEM_WHITE"],
    ["Ethnicity and race - All STEM Majors with Disabilities", "Two or more races", "DISABILITY_STEM_MULTIRACE"],
    ["Ethnicity and race - All STEM Majors with Disabilities", "Race and ethnicity unknown", "DISABILITY_STEM_UNKNOW_RACE"],
    ["Ethnicity and race - All STEM Majors with Disabilities", "Not Reported", "DISABILITY_STEM_NOREPORT_RACE"],
    ["Ethnicity and race - All STEM Majors with Disabilities", "Total", "DISABILITY_STEM_RACE"],

]

let dict_TIS_IPEDS = dict_TIS_IPEDS_arr.map(e => e.map(i => i.toUpperCase()))

const dict_TIS_General_arr = [
    ['item_name', 'varname', 'standardized_varname'],
    ["General Student Data", "Students receiving disability services via this institution", "Students_Receiving_Services_DS"],
    ["General Student Data", "Students enrolled full time", "Full_Time_Enrollment_DS"],
    ["General Student Data", "Students enrolled part time", "Part_Time_Enrollment_DS"],
    ["Academic Level", "Freshmen", "Freshmen_DS"],
    ["Academic Level", "Sophomores", "Sophomores_DS"],
    ["Academic Level", "Juniors", "Juniors_DS"],
    ["Academic Level", "Seniors", "Seniors_DS"],
    ["Academic Level", "Undergraduate", "Undergraduate_DS"],
    ["Academic Level", "Graduate", "Graduate_DS"],
    ["Academic Level", "Master's", "Masters_DS"],
    ["Academic Level", "Doctoral", "Doctoral_DS"],
    ["Academic Level", "Other students", "OtherStudents_DS"],
    ["Academic Level", "Total", "Total_Academic_Level_DS"],
    ["Gender", "Women", "Women_DS"],
    ["Gender", "Men", "Men_DS"],
    ["Gender", "Transgender", "Transgender_DS"],
    ["Gender", "Non-binary/non-conforming", "NonBinary_NonConforming_DS"],
    ["Gender", "Not reported", "Gender_Not_Reported_DS"],
    ["Gender", "Total", "Total_Gender_DS"],
    ["Ethnicity and Race", "Nonresident alien", "Nonresident_Alien_DS"],
    ["Ethnicity and Race", "Hispanic/Latino", "HispanicLatino_DS"],
    ["Ethnicity and Race", "American Indian or Alaskan Native", "American_Indian_Alaskan_Native_DS"],
    ["Ethnicity and Race", "Asian", "Asian_DS"],
    ["Ethnicity and Race", "Black or African American", "Black_DS"],
    ["Ethnicity and Race", "Native Hawaiian or Other Pacific Islander", "Native_Hawaiian_Other_Pacific_Islander_DS"],
    ["Ethnicity and Race", "White", "White_DS"],
    ["Ethnicity and Race", "Two or more races", "Two_or_More_Races_DS"],
    ["Ethnicity and Race", "Race and ethnicity unknown", "Race_Ethnicity_Unknown_DS"],
    ["Ethnicity and Race", "Not Reported", "Not_Reported_Race_Ethnicity_DS"],
    ["Ethnicity and Race", "Total", "Total_Race_Ethnicity_DS"],
    ["Disability", "Asperger's syndrome/autism spectrum disorder", "Aspergers_Autism_DS"],
    ["Disability", "Attention deficit disorder (ADD)/Attention Deficit Hyperactivity Disorder (ADHD)", "ADD_ADHD_DS"],
    ["Disability", "Deaf or Hard-of-Hearing (D/HoH)", "Deaf_HoH_DS"],
    ["Disability", "Physical Disability/Orthopedic/Mobility Impairment", "Physical_Disability_DS"],
    ["Disability", "Systemic Health/Medical Condition", "Systemic_Health_DS"],
    ["Disability", "Psychological/Psychiatric Disability", "Psychological_Disability_DS"],
    ["Disability", "Specific Learning Disability", "Specific_Learning_Disability_DS"],
    ["Disability", "Blind or Visual Impairment", "Blind_Visual_Impairment_DS"],
    ["Disability", "Speech Impairment", "Speech_Impairment_DS"],
    ["Disability", "Acquired/Traumatic Brain Injury", "Acquired_Traumatic_Brain_Injury_DS"],
    ["Disability", "Other Disabilities", "Other_DS"],
    ["Disability", "Not reported", "Not_Reported_Disability_DS"],
    ["Disability", "No disability", "No_Disability_DS"],
    ["Disability", "Total", "Total_Disability_DS"],
    ["U.S. Military Service Status", "Active Duty", "ActiveDuty_DS"],
    ["U.S. Military Service Status", "Active Reserve", "ActiveReserve_DS"],
    ["U.S. Military Service Status", "Veteran", "Veteran_DS"],
    ["U.S. Military Service Status", "Not Reported", "Not_Reported_Military_DS"],
    ["U.S. Military Service Status", "No Military Service", "No_Military_Service_DS"],
    ["U.S. Military Service Status", "Total", "Total_Military_Service_DS"],
]
let dict_TIS_General = dict_TIS_General_arr.map(e => e.map(i => i.toUpperCase()))

const dict_TIS_Stem_arr = [
    ['item_name', 'varname', 'standardized_varname'],
    ["Academic Level - All STEM Majors with Disabilities", "Freshmen", "Freshmen_DS"],
    ["Academic Level - All STEM Majors with Disabilities", "Sophomores", "Sophomores_DS"],
    ["Academic Level - All STEM Majors with Disabilities", "Juniors", "Juniors_DS"],
    ["Academic Level - All STEM Majors with Disabilities", "Seniors", "Seniors_DS"],
    ["Academic Level - All STEM Majors with Disabilities", "Undergraduate", "Undergrad_DS"],
    ["Academic Level - All STEM Majors with Disabilities", "Graduate", "Grad_DS"],
    ["Academic Level - All STEM Majors with Disabilities", "Master's", "Masters_DS"],
    ["Academic Level - All STEM Majors with Disabilities", "Doctoral", "Doctoral_DS"],
    ["Academic Level - All STEM Majors with Disabilities", "Other students", "OtherStudents_DS"],
    ["Academic Level - All STEM Majors with Disabilities", "Total", "Total_Academic_Level_DS"],
    ["Gender - All STEM Majors with Disabilities", "Women", "Women_DS"],
    ["Gender - All STEM Majors with Disabilities", "Men", "Men_DS"],
    ["Gender - All STEM Majors with Disabilities", "Transgender", "Transgender_DS"],
    ["Gender - All STEM Majors with Disabilities", "Non-binary/non-conforming", "NonBinary_DS"],
    ["Gender - All STEM Majors with Disabilities", "Not reported", "Not_Reported_Gender_DS"],
    ["Gender - All STEM Majors with Disabilities", "Total", "Total_Gender_DS"],
    ["Ethnicity and race - All STEM Majors with Disabilities", "Nonresident alien", "Nonresident_Alien_DS"],
    ["Ethnicity and race - All STEM Majors with Disabilities", "Hispanic/Latino", "HispanicLatino_DS"],
    ["Ethnicity and race - All STEM Majors with Disabilities", "American Indian or Alaskan Native", "American_Indian_Alaskan_Native_DS"],
    ["Ethnicity and race - All STEM Majors with Disabilities", "Asian", "Asian_DS"],
    ["Ethnicity and race - All STEM Majors with Disabilities", "Black or African American", "Black_DS"],
    ["Ethnicity and race - All STEM Majors with Disabilities", "Native Hawaiian or Other Pacific Islander", "Native_Hawaiian_Pacific_Islander_DS"],
    ["Ethnicity and race - All STEM Majors with Disabilities", "White", "White_DS"],
    ["Ethnicity and race - All STEM Majors with Disabilities", "Two or more races", "Two_More_Races_DS"],
    ["Ethnicity and race - All STEM Majors with Disabilities", "Race and ethnicity unknown", "Race_Ethnicity_Unknown_DS"],
    ["Ethnicity and race - All STEM Majors with Disabilities", "Not Reported", "Not_Reported_Ethnicity_DS"],
    ["Ethnicity and race - All STEM Majors with Disabilities", "Total", "Total_Ethnicity_Race_DS"],
    ["Disability - All STEM Majors with Disabilities", "Asperger's syndrome/autism spectrum disorder", "Aspergers_Autism_DS"],
    ["Disability - All STEM Majors with Disabilities", "Attention deficit disorder (ADD)/Attention Deficit Hyperactivity Disorder (ADHD)", "ADD_ADHD_DS"],
    ["Disability - All STEM Majors with Disabilities", "Deaf or Hard-of-Hearing (D/HoH)", "Deaf_HoH_DS"],
    ["Disability - All STEM Majors with Disabilities", "Physical Disability/Orthopedic/Mobility Impairment", "Physical_Disability_DS"],
    ["Disability - All STEM Majors with Disabilities", "Systemic Health/Medical Condition", "Systemic_Health_DS"],
    ["Disability - All STEM Majors with Disabilities", "Psychological/Psychiatric Disability", "Psychological_Disability_DS"],
    ["Disability - All STEM Majors with Disabilities", "Specific Learning Disability", "Specific_Learning_Disability_DS"],
    ["Disability - All STEM Majors with Disabilities", "Blind or Visual Impairment", "Blind_Visual_Impairment_DS"],
    ["Disability - All STEM Majors with Disabilities", "Speech Impairment", "Speech_Impairment_DS"],
    ["Disability - All STEM Majors with Disabilities", "Acquired/Traumatic Brain Injury", "Acquired_Traumatic_Brain_Injury_DS"],
    ["Disability - All STEM Majors with Disabilities", "Other Disabilities", "Other_DS"],
    ["Disability - All STEM Majors with Disabilities", "Not reported", "Not_Reported_Disability_DS"],
    ["Disability - All STEM Majors with Disabilities", "No disability", "No_Disability_DS"],
    ["Disability - All STEM Majors with Disabilities", "Total", "Total_Disability_DS"],
    ["U.S. Military Service Status - All STEM Majors with Disabilities", "Active Duty", "ActiveDuty_DS"],
    ["U.S. Military Service Status - All STEM Majors with Disabilities", "Active Reserve", "ActiveReserve_DS"],
    ["U.S. Military Service Status - All STEM Majors with Disabilities", "Veteran", "Veteran_DS"],
    ["U.S. Military Service Status - All STEM Majors with Disabilities", "Not Reported", "Not_Reported_Military_DS"],
    ["U.S. Military Service Status - All STEM Majors with Disabilities", "No Military Service", "No_Military_Service_DS"],
    ["U.S. Military Service Status - All STEM Majors with Disabilities", "Total", "Total_Military_Service_DS"]
]

let dict_TIS_Stem = dict_TIS_Stem_arr.map(e => e.map(i => i.toUpperCase()))

const dict_TIS_Stemgrad_arr = [
    ['item_name', 'varname', 'standardized_varname'],
    ["Academic Level - All STEM Degree Graduates with Disabilities", "Associate's", "Associates_DS"],
    ["Academic Level - All STEM Degree Graduates with Disabilities", "Bachelor's", "Bachelors_DS"],
    ["Academic Level - All STEM Degree Graduates with Disabilities", "Master's", "Masters_DS"],
    ["Academic Level - All STEM Degree Graduates with Disabilities", "Doctoral", "Doctoral_DS"],
    ["Academic Level - All STEM Degree Graduates with Disabilities", "Other students", "OtherStudents_DS"],
    ["Academic Level - All STEM Degree Graduates with Disabilities", "Total", "Total_Academic_Level_DS"],
    ["Gender - All STEM Degree Graduates with Disabilities", "Women", "Women_DS"],
    ["Gender - All STEM Degree Graduates with Disabilities", "Men", "Men_DS"],
    ["Gender - All STEM Degree Graduates with Disabilities", "Transgender", "Transgender_DS"],
    ["Gender - All STEM Degree Graduates with Disabilities", "Non-binary/non-conforming", "NonBinary_DS"],
    ["Gender - All STEM Degree Graduates with Disabilities", "Not reported", "Not_Reported_Gender_DS"],
    ["Gender - All STEM Degree Graduates with Disabilities", "Total", "Total_Gender_DS"],
    ["Ethnicity and race -All STEM Degree Graduates with Disabilities", "Nonresident alien", "Nonresident_Alien_DS"],
    ["Ethnicity and race -All STEM Degree Graduates with Disabilities", "Hispanic/Latino", "HispanicLatino_DS"],
    ["Ethnicity and race -All STEM Degree Graduates with Disabilities", "American Indian or Alaskan Native", "American_Indian_Alaskan_Native_DS"],
    ["Ethnicity and race -All STEM Degree Graduates with Disabilities", "Asian", "Asian_DS"],
    ["Ethnicity and race -All STEM Degree Graduates with Disabilities", "Black or African American", "Black_DS"],
    ["Ethnicity and race -All STEM Degree Graduates with Disabilities", "Native Hawaiian or Other Pacific Islander", "Native_Hawaiian_Pacific_Islander_DS"],
    ["Ethnicity and race -All STEM Degree Graduates with Disabilities", "White", "White_DS"],
    ["Ethnicity and race -All STEM Degree Graduates with Disabilities", "Two or more races", "Two_More_Races_DS"],
    ["Ethnicity and race -All STEM Degree Graduates with Disabilities", "Race and ethnicity unknown", "Race_Ethnicity_Unknown_DS"],
    ["Ethnicity and race -All STEM Degree Graduates with Disabilities", "Not Reported", "Not_Reported_Ethnicity_DS"],
    ["Ethnicity and race -All STEM Degree Graduates with Disabilities", "Total", "Total_Ethnicity_Race_DS"],
    ["Disability - All STEM Degree Graduates with Disabilities", "Asperger's syndrome/autism spectrum disorder", "Aspergers_Autism_DS"],
    ["Disability - All STEM Degree Graduates with Disabilities", "Attention deficit disorder (ADD)/Attention Deficit Hyperactivity Disorder (ADHD)", "ADD_ADHD_DS"],
    ["Disability - All STEM Degree Graduates with Disabilities", "Deaf or Hard-of-Hearing (D/HoH)", "Deaf_HoH_DS"],
    ["Disability - All STEM Degree Graduates with Disabilities", "Physical Disability/Orthopedic/Mobility Impairment", "Physical_Disability_DS"],
    ["Disability - All STEM Degree Graduates with Disabilities", "Systemic Health/Medical Condition", "Systemic_Health_DS"],
    ["Disability - All STEM Degree Graduates with Disabilities", "Psychological/Psychiatric Disability", "Psychological_Disability_DS"],
    ["Disability - All STEM Degree Graduates with Disabilities", "Specific Learning Disability", "Specific_Learning_Disability_DS"],
    ["Disability - All STEM Degree Graduates with Disabilities", "Blind or Visual Impairment", "Blind_Visual_Impairment_DS"],
    ["Disability - All STEM Degree Graduates with Disabilities", "Speech Impairment", "Speech_Impairment_DS"],
    ["Disability - All STEM Degree Graduates with Disabilities", "Acquired/Traumatic Brain Injury", "Acquired_Traumatic_Brain_Injury_DS"],
    ["Disability - All STEM Degree Graduates with Disabilities", "Other Disabilities", "Other_DS"],
    ["Disability - All STEM Degree Graduates with Disabilities", "Not reported", "Not_Reported_Disability_DS"],
    ["Disability - All STEM Degree Graduates with Disabilities", "No disability", "No_Disability_DS"],
    ["Disability - All STEM Degree Graduates with Disabilities", "Total", "Total_Disability_Type_DS"],
    ["U.S. Military Service Status - All STEM Degree Graduates with Disabilities", "Active Duty", "Active_Duty_DS"],
    ["U.S. Military Service Status - All STEM Degree Graduates with Disabilities", "Active Reserve", "Active_Reserve_DS"],
    ["U.S. Military Service Status - All STEM Degree Graduates with Disabilities", "Veteran", "Veteran_DS"],
    ["U.S. Military Service Status - All STEM Degree Graduates with Disabilities", "Not Reported", "Not_Reported_Military_DS"],
    ["U.S. Military Service Status - All STEM Degree Graduates with Disabilities", "No Military Service", "No_Military_Service_DS"],
    ["U.S. Military Service Status - All STEM Degree Graduates with Disabilities", "Total", "Total_Military_Service_DS"],

]
let dict_TIS_Stemgrad = dict_TIS_Stemgrad_arr.map(e => e.map(i => i.toUpperCase()))

function findLastNonEmptyColumn(arr, range=6) {
    let lastNonEmptyCol = -1;

    for (let col = 0; col <= range; col++) {
        for (let row = 0; row < arr.length; row++) {
            if (arr[row][col] !== "") {
                lastNonEmptyCol = col;
                break;
            }
        }
    }

    return lastNonEmptyCol;
}

export const normalizationIPEDSHD = raw => {
    // console.log('raw6: ', raw[5])
    const raw2 = subset(raw, 46, findLastNonEmptyColumn(raw.slice(6)) + 1)
    console.log('raw2: ', raw2)
    const instnm = raw2[0][1]
    const years = raw2[4].slice(1).map(e => e.slice(0, 4))
    console.log('years: ', years)
    // console.log('subsetted: ', subset(raw, 46, 3))
    const raw_data = raw2.slice(4).map(row => {
        return [row[0].toUpperCase(), ...row.slice(1)]
    })
    // console.log(raw_data)
    const splitedData = split(raw_data)
    let out = normalizeColName(splitedData, dict_TIS_IPEDS)
    out = transpose([['INSTNM', ...Array(years.length).fill(instnm)],
    ["YEAR", ...years], ...out])
    console.log("out: ", out)
    return toFormContent(out)
}

export const normalizationTISG = raw => {
    // console.log('raw6: ', raw[5])
    const raw2 = trimArrayElements(replaceNAWithEmptyString(subset(raw, 64, findLastNonEmptyColumn(raw.slice(4)) + 1)))
    console.log('raw2: ', raw2)
    const instnm = raw2[2][0]
    const years = raw2[3].slice(1).map(e => e.slice(0, 4))
    console.log('years: ', years)
    // console.log('subsetted: ', subset(raw, 46, 3))
    const raw_data = raw2.slice(3).map(row => {
        return [row[0].toUpperCase(), ...row.slice(1)]
    })
    // console.log(raw_data)
    const splitedData = split(raw_data)
    let out = normalizeColName(splitedData, dict_TIS_General)
    out = transpose([['INSTNM', ...Array(years.length).fill(instnm)],
    ["YEAR", ...years], ...out])
    console.log("out: ", out)
    return toFormContent(out)
}

export const normalizationTISStem = raw => {
    // console.log('raw6: ', raw[5])
    const raw2 = trimArrayElements(replaceNAWithEmptyString(subset(raw, 59, findLastNonEmptyColumn(raw.slice(4)) + 1)))
    console.log('raw2: ', raw2)
    const instnm = raw2[1][0]
    const years = raw2[2].slice(1).map(e => e.slice(0, 4))
    console.log('years: ', years)
    // console.log('subsetted: ', subset(raw, 46, 3))
    const raw_data = raw2.slice(3).map(row => {
        return [row[0].toUpperCase(), ...row.slice(1)]
    })
    // console.log(raw_data)
    const splitedData = split(raw_data)
    console.log('splitedData: ', splitedData)
    let out = normalizeColName(splitedData, dict_TIS_Stem)
    out = transpose([['INSTNM', ...Array(years.length).fill(instnm)],
    ["YEAR", ...years], ...out])
    console.log("out: ", out)
    return toFormContent(out)
}

export const normalizationTISStemgrad = raw => {
    // console.log('raw: ', raw)
    const raw2 = trimArrayElements(replaceNAWithEmptyString(subset(raw, 54, findLastNonEmptyColumn(raw.slice(3)) + 1)))
    console.log('raw2: ', raw2)
    const instnm = raw2[1][0]
    const years = raw2[2].slice(1).map(e => e.slice(0, 4))
    console.log('years: ', years)
    // console.log('subsetted: ', subset(raw, 46, 3))
    const raw_data = raw2.slice(2).map(row => {
        return [row[0].toUpperCase(), ...row.slice(1)]
    })
    // console.log(raw_data)
    const splitedData = split(raw_data)
    console.log('splitedData: ', splitedData)
    let out = normalizeColName(splitedData, dict_TIS_Stemgrad)
    out = transpose([['INSTNM', ...Array(years.length).fill(instnm)],
    ["YEAR", ...years], ...out])
    console.log("out: ", out)
    return toFormContent(out)
}

export const normalizeIPEDS = raw => {
    const instnm = raw[1][1]
    const unid = raw[1][0]
    const cname = ["UNITID",
        "INSTNM",
        "YEAR",
        "CARNEGIE",
        "UNDER_DISTANCE",
        "GRAD_DISTANCE",
        "UNDER_DISABILITY_PERCENT",
        "UNDER_MALE",
        "UNDER_FEMALE",
        "GRAD_MALE",
        "GRAD_FEMALE",
        "UNDER_INDIAN",
        "UNDER_ASIAN",
        "UNDER_BLACK",
        "UNDER_NATIVE",
        "UNDER_WHITE",
        "UNDER_MULTIRACE",
        "UNDER_UNKNOWN_RACE",
        "UNDER_NONRESIDENT",
        "GRAD_MALE",
        "GRAD_FEMALE",
        "GRAD_INDIAN",
        "GRAD_ASIAN",
        "GRAD_BLACK",
        "GRAD_NATIVE",
        "GRAD_WHITE",
        "GRAD_MULTIRACE",
        "GRAD_UNKNOWN_RACE",
        "GRAD_NONRESIDENT",
    ]
    const out = [cname, [unid, instnm, '2020', ...raw[1].slice(2, 30)],
        [unid, instnm, '2019', ...raw[1].slice(30, 58)],
        [unid, instnm, '2018', ...raw[1].slice(58, 86)]]
    console.log("out: ", out)
    return toFormContent(out)

}

export const needsNorm = ["TIS", "Institutional_data_TIS_IPEDS",
    "TIS_general", "TIS_stem", "TIS_stemgrad"]

export const normalization = (type, raw) => {
    if (type === "TIS") {
        return normalizationIPEDSHD(raw)
    } else if (type === "Institutional_data_TIS_IPEDS") {
        return normalizeIPEDS(raw)
    } else if (type === "TIS_general") {
        return normalizationTISG(raw)
    } else if (type === "TIS_stem") {
        return normalizationTISStem(raw)
    } else if (type === "TIS_stemgrad") {
        return normalizationTISStemgrad(raw)
    }
    else return null
}

export const tableNameList = ['Unspecified',
    'TIS_general',
    'TIS_stem',
    'TIS_stemgrad',
    'TIS',
    'Institutional_data_TIS_IPEDS',
    'IPEDS_HD',
]

export const TableLibrary = {
    "TIS_general":
    {
        "0": "INSTNM",
        "1": "YEAR",
        "2": "Students_Receiving_Services_DS",
        "3": "Full_Time_Enrollment_DS",
        "4": "Part_Time_Enrollment_DS",
        "5": "Freshmen_DS",
        "6": "Sophomores_DS",
        "7": "Juniors_DS",
        "8": "Seniors_DS",
        "9": "Undergraduate_DS",
        "10": "Graduate_DS",
        "11": "Masters_DS",
        "12": "Doctoral_DS",
        "13": "OtherStudents_DS",
        "14": "Total_Academic_Level_DS",
        "15": "Women_DS",
        "16": "Men_DS",
        "17": "Transgender_DS",
        "18": "NonBinary_NonConforming_DS",
        "19": "Gender_Not_Reported_DS",
        "20": "Total_Gender_DS",
        "21": "Nonresident_Alien_DS",
        "22": "HispanicLatino_DS",
        "23": "American_Indian_Alaskan_Native_DS",
        "24": "Asian_DS",
        "25": "Black_DS",
        "26": "Native_Hawaiian_Other_Pacific_Islander_DS",
        "27": "White_DS",
        "28": "Two_or_More_Races_DS",
        "29": "Race_Ethnicity_Unknown_DS",
        "30": "Not_Reported_Race_Ethnicity_DS",
        "31": "Total_Race_Ethnicity_DS",
        "32": "Aspergers_Autism_DS",
        "33": "ADD_ADHD_DS",
        "34": "Deaf_HoH_DS",
        "35": "Physical_Disability_DS",
        "36": "Systemic_Health_DS",
        "37": "Psychological_Disability_DS",
        "38": "Specific_Learning_Disability_DS",
        "39": "Blind_Visual_Impairment_DS",
        "40": "Speech_Impairment_DS",
        "41": "Acquired_Traumatic_Brain_Injury_DS",
        "42": "Other_DS",
        "43": "Not_Reported_Disability_DS",
        "44": "No_Disability_DS",
        "45": "Total_Disability_DS",
        "46": "ActiveDuty_DS",
        "47": "ActiveReserve_DS",
        "48": "Veteran_DS",
        "49": "Not_Reported_Military_DS",
        "50": "No_Military_Service_DS",
        "51": "Total_Military_Service_DS"
    },
    "TIS_stem": {
        "0": "INSTNM",
        "1": "YEAR",
        "2": "Freshmen_DS",
        "3": "Sophomores_DS",
        "4": "Juniors_DS",
        "5": "Seniors_DS",
        "6": "Undergrad_DS",
        "7": "Grad_DS",
        "8": "Masters_DS",
        "9": "Doctoral_DS",
        "10": "OtherStudents_DS",
        "11": "Total_Academic_Level_DS",
        "12": "Women_DS",
        "13": "Men_DS",
        "14": "Transgender_DS",
        "15": "NonBinary_DS",
        "16": "Not_Reported_Gender_DS",
        "17": "Total_Gender_DS",
        "18": "Nonresident_Alien_DS",
        "19": "HispanicLatino_DS",
        "20": "American_Indian_Alaskan_Native_DS",
        "21": "Asian_DS",
        "22": "Black_DS",
        "23": "Native_Hawaiian_Pacific_Islander_DS",
        "24": "White_DS",
        "25": "Two_More_Races_DS",
        "26": "Race_Ethnicity_Unknown_DS",
        "27": "Not_Reported_Ethnicity_DS",
        "28": "Total_Ethnicity_Race_DS",
        "29": "Aspergers_Autism_DS",
        "30": "ADD_ADHD_DS",
        "31": "Deaf_HoH_DS",
        "32": "Physical_Disability_DS",
        "33": "Systemic_Health_DS",
        "34": "Psychological_Disability_DS",
        "35": "Specific_Learning_Disability_DS",
        "36": "Blind_Visual_Impairment_DS",
        "37": "Speech_Impairment_DS",
        "38": "Acquired_Traumatic_Brain_Injury_DS",
        "39": "Substance Abuse",
        "40": "Not_Reported_Disability_DS",
        "41": "No_Disability_DS",
        "42": "Total_Disability_DS",
        "43": "ActiveDuty_DS",
        "44": "ActiveReserve_DS",
        "45": "Veteran_DS",
        "46": "Not_Reported_Military_DS",
        "47": "No_Military_Service_DS",
        "48": "Total_Military_Service_DS"
    },

    "TIS_stemgrad": {
        "0": "INSTNM",
        "1": "YEAR",
        "2": "Associates_DS",
        "3": "Bachelors_DS",
        "4": "Masters_DS",
        "5": "Doctoral_DS",
        "6": "OtherStudents_DS",
        "7": "Total_Academic_Level_DS",
        "8": "Women_DS",
        "9": "Men_DS",
        "10": "Transgender_DS",
        "11": "NonBinary_DS",
        "12": "Not_Reported_Gender_DS",
        "13": "Total_Gender_DS",
        "14": "Nonresident_Alien_DS",
        "15": "HispanicLatino_DS",
        "16": "American_Indian_Alaskan_Native_DS",
        "17": "Asian_DS",
        "18": "Black_DS",
        "19": "Native_Hawaiian_Pacific_Islander_DS",
        "20": "White_DS",
        "21": "Two_More_Races_DS",
        "22": "Race_Ethnicity_Unknown_DS",
        "23": "Not_Reported_Ethnicity_DS",
        "24": "Total_Ethnicity_Race_DS",
        "25": "Aspergers_Autism_DS",
        "26": "ADD_ADHD_DS",
        "27": "Deaf_HoH_DS",
        "28": "Physical_Disability_DS",
        "29": "Systemic_Health_DS",
        "30": "Psychological_Disability_DS",
        "31": "Specific_Learning_Disability_DS",
        "32": "Blind_Visual_Impairment_DS",
        "33": "Speech_Impairment_DS",
        "34": "Acquired_Traumatic_Brain_Injury_DS",
        "35": "Disability Type Data",
        "36": "Not_Reported_Disability_DS",
        "37": "No_Disability_DS",
        "38": "Total_Disability_Type_DS",
        "39": "Active_Duty_DS",
        "40": "Active_Reserve_DS",
        "41": "Veteran_DS",
        "42": "Not_Reported_Military_DS",
        "43": "No_Military_Service_DS",
        "44": "Total_Military_Service_DS"
    },
    "TIS": {
        "0": "INSTNM",
        "1": "YEAR",
        "2": "DIABILITY_COUNT",
        "3": "UNDER_DISABILITY_COUNT",
        "4": "GRAD_DISABILITY_COUNT",
        "5": "DISABILITY_HISPANIC",
        "6": "DISABILITY_INDIAN",
        "7": "DISABILITY_ASIAN",
        "8": "DISABILITY_BLACK",
        "9": "DISABILITY_NATIVE",
        "10": "DISABILITY_WHITE",
        "11": "DISABILITY_MULTIRACE",
        "12": "DISABILITY_UNKNOW_RACE",
        "13": "DISABILITY_NOREPORT_RACE",
        "14": "DISABILITY_RACE",
        "15": "DISABILITY_UNDER_STEM",
        "16": "DISABILITY_GRAD_STEM",
        "17": "DISABILITY_TOTAL_STEM",
        "18": "DISABILITY_STEM_MALE",
        "19": "DISABILITY_STEM_TRANS",
        "20": "DISABILITY_STEM_NONBINARY",
        "21": "DISABILITY_STEM_NOREPORT_GENDER",
        "22": "DISABILITY_STEM_GENDER",
        "23": "DISABILITY_STEM_HISPANIC",
        "24": "DISABILITY_STEM_INDIAN",
        "25": "DISABILITY_STEM_ASIAN",
        "26": "DISABILITY_STEM_BLACK",
        "27": "DISABILITY_STEM_NATIVE",
        "28": "DISABILITY_STEM_WHITE",
        "29": "DISABILITY_STEM_MULTIRACE",
        "30": "DISABILITY_STEM_UNKNOW_RACE",
        "31": "DISABILITY_STEM_NOREPORT_RACE",
        "32": "DISABILITY_STEM_RACE",
    },

    "Institutional_data_TIS_IPEDS": {
        "0": "UNITID",
        "1": "INSTNM",
        "2": "YEAR",
        "3": "CARNEGIE",
        "4": "UNDER_DISTANCE",
        "5": "GRAD_DISTANCE",
        "6": "UNDER_DISABILITY_PERCENT",
        "7": "UNDER_MALE",
        "8": "UNDER_FEMALE",
        "9": "GRAD_MALE",
        "10": "GRAD_FEMALE",
        "11": "UNDER_INDIAN",
        "12": "UNDER_ASIAN",
        "13": "UNDER_BLACK",
        "14": "UNDER_NATIVE",
        "15": "UNDER_WHITE",
        "16": "UNDER_MULTIRACE",
        "17": "UNDER_UNKNOWN_RACE",
        "18": "UNDER_NONRESIDENT",
        "19": "GRAD_MALE",
        "20": "GRAD_FEMALE",
        "21": "GRAD_INDIAN",
        "22": "GRAD_ASIAN",
        "23": "GRAD_BLACK",
        "24": "GRAD_NATIVE",
        "25": "GRAD_WHITE",
        "26": "GRAD_MULTIRACE",
        "27": "GRAD_UNKNOWN_RACE",
        "28": "GRAD_NONRESIDENT",
    },

    "IPEDS_HD": {
        "0": "UNITID",
        "1": "INSTNM",
        "2": "IALIAS",
        "3": "ADDR",
        "4": "CITY",
        "5": "STABBR",
        "6": "ZIP",
        "7": "FIPS",
        "8": "OBEREG",
        "9": "CHFNM",
        "10": "CHFTITLE",
        "11": "WEBADDR",
        "12": "FAIDURL",
        "13": "VETURL",
        "14": "ATHURL",
        "15": "DISAURL",
        "16": "SECTOR",
        "17": "ICLEVEL",
        "18": "CONTROL",
        "19": "INSTSIZE",
        "20": "LONGITUD",
        "21": "LATITUDE",
    }
}

export const TableFormat = {
    "TIS_general":
    {
        "0": "INSTNM",
        "1": "number",
        "2": "number",
        "3": "number",
        "4": "number",
        "5": "number",
        "6": "number",
        "7": "number",
        "8": "number",
        "9": "number",
        "10": "number",
        "11": "number",
        "12": "number",
        "13": "number",
        "14": "number",
        "15": "number",
        "16": "number",
        "17": "number",
        "18": "number",
        "19": "number",
        "20": "number",
        "21": "number",
        "22": "number",
        "23": "number",
        "24": "number",
        "25": "number",
        "26": "number",
        "27": "number",
        "28": "number",
        "29": "number",
        "30": "number",
        "31": "number",
        "32": "number",
        "33": "number",
        "34": "number",
        "35": "number",
        "36": "number",
        "37": "number",
        "38": "number",
        "39": "number",
        "40": "number",
        "41": "number",
        "42": "number",
        "43": "number",
        "44": "number",
        "45": "number",
        "46": "number",
        "47": "number",
        "48": "number",
        "49": "number",
        "50": "number",
        "51": "number"

    },
    "TIS_stem": {
        "0": "INSTNM",
        "1": "number",
        "2": "number",
        "3": "number",
        "4": "number",
        "5": "number",
        "6": "number",
        "7": "number",
        "8": "number",
        "9": "number",
        "10": "number",
        "11": "number",
        "12": "number",
        "13": "number",
        "14": "number",
        "15": "number",
        "16": "number",
        "17": "number",
        "18": "number",
        "19": "number",
        "20": "number",
        "21": "number",
        "22": "number",
        "23": "number",
        "24": "number",
        "25": "number",
        "26": "number",
        "27": "number",
        "28": "number",
        "29": "number",
        "30": "number",
        "31": "number",
        "32": "number",
        "33": "number",
        "34": "number",
        "35": "number",
        "36": "number",
        "37": "number",
        "38": "number",
        "39": "number",
        "40": "number",
        "41": "number",
        "42": "number",
        "43": "number",
        "44": "number",
        "45": "number",
        "46": "number",
        "47": "number",
        "48": "number",
    },

    "TIS_stemgrad": {
        "0": "INSTNM",
        "1": "number",
        "2": "number",
        "3": "number",
        "4": "number",
        "5": "number",
        "6": "number",
        "7": "number",
        "8": "number",
        "9": "number",
        "10": "number",
        "11": "number",
        "12": "number",
        "13": "number",
        "14": "number",
        "15": "number",
        "16": "number",
        "17": "number",
        "18": "number",
        "19": "number",
        "20": "number",
        "21": "number",
        "22": "number",
        "23": "number",
        "24": "number",
        "25": "number",
        "26": "number",
        "27": "number",
        "28": "number",
        "29": "number",
        "30": "number",
        "31": "number",
        "32": "number",
        "33": "number",
        "34": "number",
        "35": "number",
        "36": "number",
        "37": "number",
        "38": "number",
        "39": "number",
        "40": "number",
        "41": "number",
        "42": "number",
        "43": "number",
        "44": "number",
    },

    "TIS": {
        "0": "string",
        "1": "number",
        "2": "number",
        "3": "number",
        "4": "number",
        "5": "number",
        "6": "number",
        "7": "number",
        "8": "number",
        "9": "number",
        "10": "number",
        "11": "number",
        "12": "number",
        "13": "number",
        "14": "number",
        "15": "number",
        "16": "number",
        "17": "number",
        "18": "number",
        "19": "number",
        "20": "number",
        "21": "number",
        "22": "number",
        "23": "number",
        "24": "number",
        "25": "number",
        "26": "number",
        "27": "number",
        "28": "number",
        "29": "number",
        "30": "number",
        "31": "number",
        "32": "number",
    },

    "Institutional_data_TIS_IPEDS": {
        "0": "UNITID",
        "1": "INSTNM",
        "2": "YEAR",
        "3": "CARNEGIE",
        "4": "UNDER_DISTANCE",
        "5": "GRAD_DISTANCE",
        "6": "number",
        "7": "number",
        "8": "number",
        "9": "number",
        "10": "number",
        "11": "number",
        "12": "number",
        "13": "number",
        "14": "number",
        "15": "number",
        "16": "number",
        "17": "number",
        "18": "number",
        "19": "number",
        "20": "number",
        "21": "number",
        "22": "number",
        "23": "number",
        "24": "number",
        "25": "number",
        "26": "number",
        "27": "number",
        "28": "number",
    },

    "IPEDS_HD": {
        "0": "UNITID",
        "1": "INSTNM",
        "2": "IALIAS",
        "3": "ADDR",
        "4": "CITY",
        "5": "STABBR",
        "6": "ZIP",
        "7": "FIPS",
        "8": "OBEREG",
        "9": "CHFNM",
        "10": "CHFTITLE",
        "11": "WEBADDR",
        "12": "FAIDURL",
        "13": "VETURL",
        "14": "ATHURL",
        "15": "DISAURL",
        "16": "SECTOR",
        "17": "ICLEVEL",
        "18": "CONTROL",
        "19": "INSTSIZE",
        "20": "number",
        "21": "number",
    }
}