import React, { useState, useEffect, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import { BarCharts } from './BarCharts';
import { LineChart } from './LineCharts';
import { db } from "../Firebase/firebasedb";
import { doc, setDoc, getDoc, orderBy, serverTimestamp, collection, query, where, onSnapshot, getDocs } from "firebase/firestore";
import { PieChart } from "./PieChart";
import { Form, Button, Dropdown, DropdownButton } from 'react-bootstrap';
import { Input, RadioGroup, Textarea, Checkbox, CheckboxFollowup } from "../Components";
import { BivariableCharts } from "./BivariableCharts";
import { useForm, FormProvider, useFormContext } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { format } from "date-fns";
import { CustomMenu, FormLibrary, generateRandomAnswersBulk, generateRandomNumber, notQuestionTypes } from "../Automatic Forms/Utils";
import { Card } from "react-bootstrap";
import { AiFillCloseCircle, AiOutlinePlusCircle } from "react-icons/ai";
import theme from "../Theme/theme";
import { RandomDefaultCharts } from "./RandomDefaultCharts";
import "./automatic_chart.css"
import { tableNameList } from "../Automatic Forms/Normalization";
import { TableLibrary } from "../Automatic Forms/Normalization";
import { TableFormat } from "../Automatic Forms/Normalization"
import { BivariableTableCharts } from "./BivariableTableCharts";

const todayDate = new Date();
const formSubmissionRef = collection(db, "automatic_form_submissions");

export function TableChartDashboard() {
    const validationSchema = Yup.object().shape({
        // startYear: Yup.string().test('start date vs end date comparison',
        //     'Start date cannot be after end date', (result) => {
        //         const date1 = Number(startYear);
        //         const date2 = Number(endYear);
        //         if (startYear == "" && endYear == "")
        //             return true
        //         return date1 < date2;
        //     }),
        // endDate: Yup.string().test('start date vs end date comparison',
        //     'End date cannot be before Start date!', (result) => {
        //         const date1 = Number(startYear);
        //         const date2 = Number(endYear);

        //         // console.log(endDate);
        //         // console.log(startDate)
        //         if (startYear == "" && endYear == "")
        //             return true

        //         return date1 < date2;
        //     }),
    })
    const formOptions = { resolver: yupResolver(validationSchema) };
    const methods = useForm(formOptions);
    const { handleSubmit, reset, watch, register, setError,
        clearErrors, setValue, getValues, formState: { errors } } = methods;
    // console.log(errors)
    // const startYear = watch('startYear');
    // const endYear = watch('endYear');
    const onSubmit = (data) => console.log("submit data: ", data);
    //Drop-down
    const [chartTitle, setChartTitle] = useState(null);
    const [currentEventKey, setCurrentEventKey] = useState(null)
    const [latestAnswerDict, setLatestAnswerDict] = useState(null)
    const [allData, setAllData] = useState(null)

    const [formSubmissionData, setFormSubmissionData] = useState(null);
    const [customChartCount, setCustomChartCount] = useState(0);
    const [selectedTable, setSelectedTable] = useState("- Select Master Table -");
    const [tableId, setTableId] = useState(null);
    const yearOptions = Array.from({ length: 5 }, (_, i) => 2023 - i);
    const [startYear, setStartYear] = useState(yearOptions[yearOptions.length - 1]);
    const [endYear, setEndYear] = useState(yearOptions[0]);

    // const validateDate = (startYear, endYear) => {
    //     let startDateTemp = startYear;
    //     let endDateTemp = endYear;
    //     if (startYear == '') {  
    //         startDateTemp = todayDate.getFullYear() - 1;
    //     }
    //     else {
    //         startDateTemp = Number(startYear);
    //     }
    //     if (endYear == '') {
    //         endDateTemp = todayDate.getFullYear();
    //     }
    //     else {
    //         endDateTemp = Number(endYear);
    //     }
    //     if (startDateTemp > todayDate) {
    //         setError('startDate', { type: 'custom', message: 'Start date must be before today!' });
    //     }
    //     else if (endDateTemp > todayDate) {
    //         setError('endDate', { type: 'custom', message: 'End date must be before today!' });
    //     }
    //     else if (startDateTemp >= endDateTemp) {
    //         setError('startDate', { type: 'custom', message: 'Start Date must be before End Date!' });
    //     }

    //     else {
    //         clearErrors('startDate');
    //         clearErrors('endDate');
    //         return [startDateTemp, endDateTemp]
    //     }
    //     return [false, false]
    // }

    console.log('tableId: ', tableId)
    const transformData = (raw, tableId) => {
        const colnames = TableLibrary[tableId]
        const out = raw.map(r => {
            let x = {}
            Object.keys(r).forEach(k => {
                let val = r[k]
                if (TableFormat[tableId][k] == 'number') {
                    val = Number(val)
                }
                x = { ...x, [colnames[k]]: val }
            })
            return x
        })
        return out
    }
    function removeDuplicates(arr) {
        return arr.filter((obj, index, self) =>
            index === self.findIndex((el) => JSON.stringify(el) === JSON.stringify(obj))
        );
    }
    function removeEmpty(obj) {
        Object.keys(obj).forEach(key => {
            if (undefined in obj[key]) {
                delete obj[key][undefined];
            }
            if (obj[key]["INSTNM"] === '')
                delete obj[key];

        }
        );
        return obj;
    }
    useEffect(() => {
        const formSubmissionRef = collection(db, "automatic_table_submissions");
        const q2 = query(formSubmissionRef,
            where("tableName", "==", tableId), orderBy("formID"), orderBy('shard'));
        const unsubscribe2 = onSnapshot(q2, querySnapshot => {
            // console.log(querySnapshot.size)
            // let answerDict = {};
            let rawData = [];

            querySnapshot.forEach((docSnapShot) => {
                const data = docSnapShot.data();
                rawData.push(...data.form_content);

            })
            console.log('rawData: ', rawData)
            if (rawData.length > 0) {
                rawData = removeDuplicates(removeEmpty(transformData(rawData, tableId)))
                console.log('transformed_rawData: ', rawData)

                // Object.keys(rawData[0]).map(
                //     k => {
                //         return { [k]: rawData.map(e => e[k]) }
                //     }
                // ).forEach(obj => { answerDict = { ...answerDict, ...obj } })
                // setLatestAnswerDict(answerDict);
                setFormSubmissionData(rawData);
                // console.log(rawData);
            }

        })

    }, [tableId])

    useEffect(() => {
        if (tableId) {
            setSelectedTable(tableId)
        }
    }, [tableId])

    useEffect(() => {
        let answerDict = {};
        if (formSubmissionData  ) {
            console.log("start date", startYear);
            console.log("end date", endYear);
            // let startDateTemp = startDate;
            // let endDateTemp = endDate;
            // let [startDateTemp, endDateTemp] = validateDate(startYear, endYear)
            // if (startDateTemp && endDateTemp) {
                Object.keys(formSubmissionData[0]).map(
                    k => {
                        return {
                            [k]: formSubmissionData
                                .filter(e => e.YEAR >= Number(startYear) && e.YEAR <= Number(endYear))
                                .map(e => e[k])
                        }
                    }
                ).forEach(obj => { answerDict = { ...answerDict, ...obj } })
                setLatestAnswerDict(answerDict);
            // }
        }
        console.log("answerDict: ", answerDict);
    }, [formSubmissionData, startYear, endYear])


    return <div>

        <div className="m-5"></div>

        <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Dropdown
                    onSelect={(eventKey, event) => {
                        setTableId(eventKey);
                    }}>
                    <Dropdown.Toggle variant='primary' className='mb-1'>
                        {selectedTable}
                    </Dropdown.Toggle>

                    <Dropdown.Menu as={CustomMenu} >
                        {tableNameList.slice(1).map(tableName => {
                            return <Dropdown.Item eventKey={tableName}>
                                {tableName}
                            </Dropdown.Item>



                        })}
                    </Dropdown.Menu>
                </Dropdown>

                {
                    tableId && <div>
                        <div className='row'>
                            <div className='col-sm-4 col-md-3 col-lg-2 form-check mb-2'>
                                <DropdownButton
                                    id="dropdown-start-year-selector"
                                    title={`Start Year: ${startYear}`}
                                    onSelect={(year) => setStartYear(Number(year))}
                                >
                                    {yearOptions.map(year => (
                                        <Dropdown.Item key={year} eventKey={year}>
                                            {year}
                                        </Dropdown.Item>
                                    ))}
                                </DropdownButton>
                            </div>
                            <div className=' col-sm-4 col-md-3 col-lg-2 form-check mx-3 mb-2'>
                                <DropdownButton
                                    id="dropdown-end-year-selector"
                                    title={`End Year: ${endYear}`}
                                    onSelect={(year) => setEndYear(Number(year))}
                                >
                                    {yearOptions.map(year => (
                                        <Dropdown.Item key={year} eventKey={year}>
                                            {year}
                                        </Dropdown.Item>
                                    ))}
                                </DropdownButton>
                            </div>
                        </div>

                        <Button variant='outline-success' onClick={() => {
                            console.log('additional charts: ' + (customChartCount + 1))
                            setCustomChartCount(customChartCount + 1);
                        }}>
                            <AiOutlinePlusCircle className='mb-1'
                                style={{ fontSize: 17, marginRight: 3 }} />
                            Create Charts
                        </Button>
                        {formSubmissionData && Array.from(Array(customChartCount), (e, i) => (
                            <BivariableTableCharts
                                latestAnswerDict={latestAnswerDict}
                            // allVariables={allVariables}
                            // rawData={formSubmissionData}
                            />
                        ))}

                    </div>
                }


            </form>
        </FormProvider>

    </div>
}
