import React, { useState, useEffect, useRef } from "react";
import { db } from "../Firebase/firebasedb";
import {
  doc, setDoc, getDocs, onSnapshot,
  serverTimestamp, query, where, collection, orderBy, getDoc, writeBatch,
  deleteDoc
} from "firebase/firestore";
// import { Button } from "react-bootstrap";
import { useForm, FormProvider } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { AutomaticBot } from "./AutomaticChatbot";
import { AutomaticQuestion } from "./AutomaticComponents";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getStorage, ref, uploadBytes } from "firebase/storage";
import cloneDeep from 'lodash/cloneDeep';
import { Modal, Button } from "react-bootstrap";
import './automatic_form.css'
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
// import names from  'random-names-generator'
import { uniqueNamesGenerator, adjectives, colors, animals, names, NumberDictionary } from 'unique-names-generator';
import { loremIpsum, name, surname, fullname, username } from 'react-lorem-ipsum';
import wordsToNumbers from "words-to-numbers";
import { cities, states, majors } from "./fake_sources"
import { faker } from '@faker-js/faker';
import { encryptableQuestionTypes, FormLibrary, generateRandomMajor, generateRandomNumber, HomePageURL, processID, randomDate, RedirectToHomePage } from "./Utils";
import theme from "../Theme/theme";
import { getFirebaseDocumentID, isCampusLead, isHubLead } from "../Fixed Sources/accountTypes";
import { parseMultiple, parseMultiple2 } from '../Automatic Forms/Utils'
import { AiFillQuestionCircle, AiOutlineInfoCircle } from "react-icons/ai";
import ReactTooltip from 'react-tooltip';
import { base64Encode } from "@firebase/util";

const SubmitBtn = ({ formMetadata, currentFormDisplayQuestion, satisfied, prerequisiteError = null }) => {
  let submitBtn = <button
    type="submit"
    //   disabled={botSubmit}
    // onClick={() => handleSubmit(onSubmit)}
    className="btn mr-1 btn-primary submit-btn-normal"
  >
    {"Submit"}
  </button>

  if (currentFormDisplayQuestion && currentFormDisplayQuestion.questionID == 'confirm-question') {
    submitBtn = <button
      // style = {{borderColor: highlightColor, backgroundColor: highlightColor}}
      type="submit"
      //   disabled={botSubmit}
      // onClick={() => handleSubmit(onSubmit)}
      className="btn mr-1 btn-lg submit-btn-highlight"
    >
      {"Submit Now!"}
    </button>
  }
  console.log('formMetadata', formMetadata)
  console.log('prerequisiteError', prerequisiteError)

  if (formMetadata) {
    if (!satisfied) {
      submitBtn = <div>
        <Button disabled>
          Prerequisite Not Satisfied!

        </Button>
        <AiFillQuestionCircle style={{
          marginLeft: '2px', marginBottom: '3px',
          color: theme.highlightColor, fontSize: '17px'
        }} data-tip={prerequisiteError} />

      </div>
    }
    if (formMetadata.startDate) {
      const todayDate = new Date()
      const startDate = new Date(formMetadata.startDate)
      if (todayDate < startDate) {
        submitBtn = <div>
          <Button
            disabled>
            Form not open

          </Button>
          <AiFillQuestionCircle style={{
            marginLeft: '2px', marginBottom: '3px',
            color: theme.highlightColor, fontSize: '17px'
          }} data-tip={`You cannot submit because the form has not officially opened (${parseMultiple2(formMetadata.startDate)}).`} />

        </div>
      }
    }
    if (formMetadata.endDate) {
      const todayDate = new Date()
      const endDate = new Date(formMetadata.endDate)
      // console.log('endDate', endDate, 'todayDate', todayDate)
      if (todayDate > endDate) {
        submitBtn = <div>
          <Button
            disabled>
            Form already closed

          </Button>
          <AiFillQuestionCircle style={{
            marginLeft: '2px', marginBottom: '3px',
            color: theme.highlightColor, fontSize: '17px'
          }} data-tip={`You cannot submit because the deadline already passed (${parseMultiple2(formMetadata.endDate)}).`} />

        </div>
      }
    }


  }
  return submitBtn
}
const SaveBtn = ({ formMetadata, currentFormDisplayQuestion, satisfied, onSaveClick }) => {
  let saveBtn = <Button
    className='mx-1'
    //   disabled={botSubmit}
    onClick={onSaveClick}
    variant='info'
  >
    {"Save"}
    <AiFillQuestionCircle style={{
      marginLeft: '2px', marginBottom: '3px',
      color: theme.highlightColor, fontSize: '17px'
    }} data-tip={`Save current answers and return later.`} />
  </Button>
  console.log('formMetadata', formMetadata)
  if (formMetadata) {
    if (!satisfied) {
      saveBtn = null
    }
    if (formMetadata.startDate) {
      const todayDate = new Date()
      const startDate = new Date(formMetadata.startDate)
      if (todayDate < startDate) {
        saveBtn = null
      }
    }
    if (formMetadata.endDate) {
      const todayDate = new Date()
      const endDate = new Date(formMetadata.endDate)
      if (todayDate > endDate) {
        saveBtn = null
      }
    }


  }
  return saveBtn
}
export default function FormRendering() {
  const [renderPage, setRenderPage] = useState(true);
  const highlightColor = '#6E48AA'
  const { formId } = useParams();
  const [formSubmissionID] = useState(makeid(20));

  console.log(formId, formSubmissionID);
  const [formQuestions, setFormQuestions] = useState([]);
  const [formMetadata, setFormMetadata] = useState(null);

  const [validationSchema, setValidationSchema] = useState({})
  // const [newValidationItem, setNewValidationItem] = useState({})
  const [randomAnswers, setRandomAnswers] = useState(null);
  const navigate = useNavigate();
  const normalizeInput = (value) => {
    // console.log(value)
    // return nothing if no value
    if (!value) return value;

    // only allows 0-9 inputs
    const currentValue = value.replace(/[^\d]/g, '');
    const cvLength = currentValue.length;
    let formattedString = ''
    if (value.length > 0) {

      // returns: "x", "xx", "xxx"
      if (cvLength < 4) return currentValue;

      // returns: "(xxx)", "(xxx) x", "(xxx) xx", "(xxx) xxx",
      if (cvLength < 7) return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`;

      // returns: "(xxx) xxx-", (xxx) xxx-x", "(xxx) xxx-xx", "(xxx) xxx-xxx", "(xxx) xxx-xxxx"
      formattedString = `(${currentValue.slice(0, 3)}) ${currentValue.slice(3, 6)}-${currentValue.slice(6, 10)}`;
    }
    return formattedString
  };
  const numberOfBulks = 200;
  useEffect(() => {
    if (formQuestions) {
      if (randomAnswers === true) {
        generateRandomAnswers(formQuestions);
      }
      else if (randomAnswers === false) {
        reset();
        formQuestions.forEach(q => {
          if (q.question_type == 'phone_number') {
            const resetValue = latestRetrievedForm ? normalizeInput(latestRetrievedForm[q.questionID]) : ''
            setValue(q.questionID, resetValue)
          }
          else if (q["question_type"] === "checkbox") {
            // console.log(q)
            q.options.forEach(option => {
              if (option.toLowerCase() == 'other') {
                setValue(q.questionID + '.check' + option, false)
                setValue(q.questionID + '.other' + option, '')
              }
              else {
                console.log(q.questionID + '.' + option)
              }
            })
          }
        })
        generateValidationSchema(formQuestions)
      }
    }

  }, [randomAnswers, formQuestions])
  useEffect(() => {
    // console.log(validationSchema)
  }, [validationSchema])
  const methods = useForm({ resolver: yupResolver(Yup.object().shape(validationSchema)) });

  const { handleSubmit, watch, reset, setValue, getValues, formState: { errors } } = methods;
  console.log('errors', errors)
  const processEncryption = (submittedData, formQuestions) => {
    formQuestions.forEach(q => {
      if (q.encrypted == true) {
        const encryptedValue = base64Encode(submittedData[q.questionID])
        console.log('Encrypted Answer: ', encryptedValue)
        submittedData[q.questionID] = encryptedValue
      }
    })
    return submittedData
  }
  const filterResponse = (submittedData, formQuestions) => {
    formQuestions.forEach(q => {
      if (q.parent_questionID) {
        if (q.parent_conditions) {
          if (Object.keys(q.parent_conditions).length > 0) {
            const parentQuestionID = q.parent_questionID;
            const parentCheckedValue = submittedData[parentQuestionID]

            // console.log('parentQuestionID', parentQuestionID)
            // console.log('q.parent_conditions', q.parent_conditions)
            // console.log('parentChecked', submittedData[parentQuestionID])


            const parentConditionValue = q.parent_conditions[parentQuestionID][1];
            // const parentConditionID = q.parent_conditions[parentQuestionID][0];

            // console.log('parentConditionID', parentConditionID)
            if (parentCheckedValue != parentConditionValue) {
              // console.log('null', q.questionID, ' due to parent not checked' )
              submittedData[q.questionID] = null;
            }
          }
        }
      }
    })
    return submittedData
  }
  const onSubmit = data => {
    console.log('data', data);

    const filteredData = filterResponse(data, formQuestions)

    const encryptedData = processEncryption(filteredData, formQuestions)
    console.log('encryptedData', encryptedData);

    writeToFirebaseAsync(encryptedData, formSubmissionID);

  }
  const onSaveClick = data => {
    console.log(data);

    // writeToFirebase(data);
    writeToFirebaseTempAsync(data, formSubmissionID);
    // reset()
  }
  function makeid(l) {
    var text = "";
    var char_list = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    for (var i = 0; i < l; i++) {
      text += char_list.charAt(Math.floor(Math.random() * char_list.length));
    }
    return text;
  }
  const storage = getStorage();

  const [submitSuccessfully, setSubmitSuccessfully] = useState(null);
  useEffect(() => {
    if (submitSuccessfully) {
      // setShow(true);
    }
  }, [submitSuccessfully]);
  const [showLatestFormConsent, setShowLatestFormConsent] = useState(false);
  const [formRetrieveConsent, setFormRetrieveConsent] = useState(null);
  const handleNoLatestForm = () => {
    setShowLatestFormConsent(false);
    setFormRetrieveConsent(false);
  }
  const handleYesLatestForm = () => {
    setShowLatestFormConsent(false);
    setFormRetrieveConsent(true);

  }




  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
    // navigate("/dashboardstudent");
    console.log('HomePageURL(userData)', HomePageURL(userData))
    navigate(HomePageURL(userData));
  };
  const handleShow = () => {
    setShow(true);
  };
  const publishForm = async (formMetadata, status) => {
    await setDoc(doc(db, "form_library", formMetadata.formID), { status: status }, { merge: true })
    console.log('Form is now ' + status);
  }


  const writeToFirebaseAsync = async (data, formSubmissionID) => {
    data.submitMode = 'web'
    data.formSubmissionID = formSubmissionID;
    data.userID = user.uid ? user.uid : 'anonymous'
    data.formID = formId;
    data.createdAt = serverTimestamp();
    let specialQuestions = {}
    const clonedData = cloneDeep(data);
    formQuestions.forEach(q => {
      // console.log(q.questionID)
      if (q.question_type == 'file_upload') {
        console.log(data[`${q.questionID}`])
        if (data[`${q.questionID}`]) {
          if (data[`${q.questionID}`].length > 0) {
            specialQuestions[q.questionID] = q; //only pay attention if there's a file
            const fileName = data[`${q.questionID}`][0].name;
            var filepath = `automatic_form_submissions/${formId}/${formSubmissionID}/${fileName}`
            console.log(filepath)
            data[`${q.questionID}`] = {
              bucketPath: filepath,
              storageURL: '',
              uploadSuccess: false
            }
          }
          else {
            data[`${q.questionID}`] = null;
          }
        }
        else if (data[`${q.questionID}-reused`]) {
          console.log(latestRetrievedForm[`${q.questionID}`])
          if (latestRetrievedForm[`${q.questionID}`].uploadSuccess === true) {
            let filepath = latestRetrievedForm[`${q.questionID}`].bucketPath;
            data[`${q.questionID}`] = {
              bucketPath: filepath,
              storageURL: filepath,
              uploadSuccess: true
            }
            console.log(`reused storageURL ${filepath} to firebase`);

          }
        }

      }
    })
    console.log(clonedData);
    await setDoc(doc(db, "automatic_form_submissions", data.formSubmissionID), data)
    const formUploadTempRef = collection(db, "automatic_form_submissions_temp");
    const qTemp = query(formUploadTempRef, where("userID", "==", user.uid), where('formID', '==', formId), orderBy("createdAt", "desc"))
    getDocs(qTemp).then(querySnapshotTemp => { //Check latest saves,
      if (querySnapshotTemp.size > 0) {
        querySnapshotTemp.forEach((docSnapshot) => {
          // const data = doc.data();
          console.log('delete ', docSnapshot.id)
          deleteDoc(doc(db, 'automatic_form_submissions_temp', docSnapshot.id))
        })
      }
    })//if yes, pull save
    // Upload to Firebase(if there is new file)

    for (const [key, value] of Object.entries(specialQuestions)) {
      if (value.question_type == 'file_upload') {
        let specialItemInfo = data[`${key}`]
        if (specialItemInfo) { //contains a file to upload
          let filepath = specialItemInfo.bucketPath;
          const file = clonedData[`${key}`][0]
          const storageRef = ref(storage, filepath);

          let uploadSnapshot = await uploadBytes(storageRef, file);
          console.log("Uploaded a blob or file!");
          console.log(uploadSnapshot)
          console.log(uploadSnapshot.metadata.fullPath);
          await setDoc(
            doc(db, "automatic_form_submissions", data.formSubmissionID),
            {
              [key]: {
                bucketPath: filepath,
                storageURL: uploadSnapshot.metadata.fullPath,
                uploadSuccess: true
              }
            },
            { merge: true }
          );
          console.log(`add storageURL ${filepath} to firebase`);
        }
        else {
          console.log(data[`${key}-reused`])
          if (data[`${key}-reused`]) {
            if (latestRetrievedForm[`${key}`].uploadSuccess === true) {
              let filepath = latestRetrievedForm[`${key}`].bucketPath;
              console.log(filepath)
              await setDoc(
                doc(db, "automatic_form_submissions", data.formSubmissionID),
                {
                  [key]: {
                    bucketPath: filepath,
                    storageURL: filepath,
                    uploadSuccess: true
                  }
                },
                { merge: true }
              );
              console.log({
                [key]: {
                  bucketPath: filepath,
                  storageURL: filepath,
                  uploadSuccess: true
                }
              })
              console.log(`reused storageURL ${filepath} to firebase`);
            }

          }
          else {

          }
        }

      }
    }

    // alert(`Form Submission ID ${data.formSubmissionID} for Form ${data.formID} written to Firebase successfully`);
    setSubmitSuccessfully('completed');
    setShowLatestFormConsent(false);
    handleShow();


    return;

  }
  const writeToFirebaseTempAsync = async (data, formSubmissionID) => {
    data.formSubmissionID = formSubmissionID;
    data.userID = user.uid ? user.uid : 'anonymous'
    data.formID = formId;
    data.createdAt = serverTimestamp();
    let specialQuestions = {}
    const clonedData = cloneDeep(data);
    formQuestions.forEach(q => {
      // console.log(q.questionID)
      if (q.question_type == 'file_upload') {
        console.log(data[`${q.questionID}`])
        if (data[`${q.questionID}`]) {
          if (data[`${q.questionID}`].length > 0) {
            specialQuestions[q.questionID] = q; //only pay attention if there's a file
            const fileName = data[`${q.questionID}`][0].name;
            var filepath = `automatic_form_submissions_temp/${formId}/${formSubmissionID}/${fileName}`
            console.log(filepath)
            data[`${q.questionID}`] = {
              bucketPath: filepath,
              storageURL: '',
              uploadSuccess: false
            }
          }
          else {
            data[`${q.questionID}`] = null;
          }
        }
        else if (data[`${q.questionID}-reused`]) {
          console.log(latestRetrievedForm[`${q.questionID}`])
          if (latestRetrievedForm[`${q.questionID}`].uploadSuccess === true) {
            let filepath = latestRetrievedForm[`${q.questionID}`].bucketPath;
            data[`${q.questionID}`] = {
              bucketPath: filepath,
              storageURL: filepath,
              uploadSuccess: true
            }
            console.log(`reused storageURL ${filepath} to firebase`);

          }
        }

      }
    })
    console.log(clonedData);
    await setDoc(doc(db, "automatic_form_submissions_temp", data.formSubmissionID), data)

    //Upload to Firebase (if there is new file)

    // for (const [key, value] of Object.entries(specialQuestions)) {
    //   if (value.question_type == 'file_upload') {
    //     let specialItemInfo = data[`${key}`]
    //     if (specialItemInfo) { //contains a file to upload
    //       let filepath = specialItemInfo.bucketPath;
    //       const file = clonedData[`${key}`][0]
    //       const storageRef = ref(storage, filepath);

    //       let uploadSnapshot = await uploadBytes(storageRef, file);
    //       console.log("Uploaded a blob or file!");
    //       console.log(uploadSnapshot)
    //       console.log(uploadSnapshot.metadata.fullPath);
    //       await setDoc(
    //         doc(db, "automatic_form_submissions", data.formSubmissionID),
    //           { [key]: {
    //             bucketPath: filepath,
    //             storageURL: uploadSnapshot.metadata.fullPath,
    //             uploadSuccess: true }
    //           },
    //           { merge: true }
    //         );
    //         console.log(`add storageURL ${filepath} to firebase`);
    //     }
    //     else {
    //       console.log(data[`${key}-reused`])
    //       if (data[`${key}-reused`]) {
    //         if (latestRetrievedForm[`${key}`].uploadSuccess === true) {
    //           let filepath = latestRetrievedForm[`${key}`].bucketPath;
    //           console.log(filepath)
    //           await setDoc(
    //             doc(db, "automatic_form_submissions", data.formSubmissionID),
    //               { [key]: {
    //                 bucketPath: filepath,
    //                 storageURL: filepath,
    //                 uploadSuccess: true }
    //               },
    //               { merge: true }
    //             );
    //           console.log({ [key]: {
    //                   bucketPath: filepath,
    //                   storageURL: filepath,
    //                   uploadSuccess: true }
    //                 },)
    //           console.log(`reused storageURL ${filepath} to firebase`);
    //         }

    //       }
    //       else {

    //       }
    //     }

    //   }
    // }

    // alert(`Form Submission ID ${data.formSubmissionID} for Form ${data.formID} written to Firebase successfully`);
    // setSubmitSuccessfully(true);
    // setShowLatestFormConsent(false);
    // handleShow();
    setSubmitSuccessfully('saved');
    setShowLatestFormConsent(false);
    handleShow();

    return;

  }
  const writeToFirebaseAsyncSilent = async (data, formSubmissionID) => {
    data.formSubmissionID = formSubmissionID;
    data.userID = user.uid ? user.uid : 'anonymous'
    data.formID = formId;
    data.createdAt = serverTimestamp();
    let specialQuestions = {}
    const clonedData = cloneDeep(data);
    formQuestions.forEach(q => {
      // console.log(q.questionID)
      if (q.question_type == 'file_upload') {
        console.log(data[`${q.questionID}`])
        if (data[`${q.questionID}`]) {
          if (data[`${q.questionID}`].length > 0) {
            specialQuestions[q.questionID] = q; //only pay attention if there's a file
            const fileName = data[`${q.questionID}`][0].name;
            var filepath = `automatic-form-submissions/${formId}/${formSubmissionID}/${fileName}`
            console.log(filepath)
            data[`${q.questionID}`] = {
              bucketPath: filepath,
              storageURL: '',
              uploadSuccess: false
            }
          }
          else {
            data[`${q.questionID}`] = null;
          }
        }
        else if (data[`${q.questionID}-reused`]) {
          console.log(latestRetrievedForm[`${q.questionID}`])
          if (latestRetrievedForm[`${q.questionID}`].uploadSuccess === true) {
            let filepath = latestRetrievedForm[`${q.questionID}`].bucketPath;
            data[`${q.questionID}`] = {
              bucketPath: filepath,
              storageURL: filepath,
              uploadSuccess: true
            }
            console.log(`reused storageURL ${filepath} to firebase`);

          }
        }

      }
    })
    console.log(clonedData);
    await setDoc(doc(db, "automatic_form_submissions", data.formSubmissionID), data)
    return;

  }
  const uploadToFirebase = (file, bucketPath) => {

  }
  const [latestRetrievedForm, setLatestRetrievedForm] = useState(null);
  const [previousSubmissions, setPreviousSubmissions] = useState({});
  const [satisfied, setSatisfied] = useState(false)
  const [currentFormOrder, setCurrentFormOrder] = useState([]);
  const [prerequisiteError, setPrerequisiteError] = useState('');

  const [formLibrary, setFormLibrary] = useState([]);





  const [formRetrieved, setFormRetrieved] = useState(null);

  //Authentication Parameters
  const [userData, setUserData] = useState(null);

  const auth = getAuth();
  const [user, setUser] = useState(null);
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        // const uid = user.uid;
        // ...
        setUser(user);
      } else {
      }
    });
    return () => unsubscribe();
  }, [auth]);
  useEffect(() => {
    if (user) {
      const docRef = doc(db, "Users", user.uid);
      getDoc(docRef).then((docSnap) => {
        if (docSnap.exists()) {
          console.log("Document data:", docSnap.data());
          const data = docSnap.data();
          setUserData(data);
          console.log(data.atype)
          const firebaseDocID = getFirebaseDocumentID(data.atype)
          const formOrderRef = doc(db, 'automatic_form_order', firebaseDocID);
          const unsub = onSnapshot(formOrderRef, (doc) => {
            // console.log('calling from unsub')
            const data = doc.data();
            console.log('orderData', data)
            if (data && data.formOrder) {
              setCurrentFormOrder(data.formOrder);
              // setOriginalFormOrder(data.formOrder);
            }
            else {
              setCurrentFormOrder([]);

            }
          })
        }
      });
    }
  }, [user]);
  //Bot parameters

  const [botSubmit, setBotSubmit] = useState(false);
  const [waitAction, setWaitAction] = useState(false);
  const [botTriggerNext, setBotTriggerNext] = useState(false);
  const [currentFormDisplayQuestion, setFormCurrentDisplayQuestion] = useState(null);

  const thisform = useRef();

  useEffect(() => {
    // console.log(botSubmit);
    if (botSubmit === true) {
      // console.log("TRUE!!!");
      thisform.current &&
        thisform.current.dispatchEvent(
          new Event("submit", { cancelable: true, bubbles: true })
        );
      // setBotSubmit(false);
    }
  }, [botSubmit]);

  //
  // console.log(watch("short_answer")); // watch input value by passing the name of it
  useEffect(() => {
    if (waitAction === true)
      console.log("waitAction!!!")
  }, [waitAction])
  useEffect(() => {
    if (currentFormDisplayQuestion) {
      console.log("Current Bot Progress");
      console.log(currentFormDisplayQuestion);
    }
  }, [currentFormDisplayQuestion])
  useEffect(() => {
    if (botTriggerNext === true)
      console.log("file is attached! Move On!")
  }, [botTriggerNext])




  const removeSpecialCharacters = (str) => {
    return str.replace(/[^.,a-zA-Z0-9]/g, '')
  }
  const generateRandomAnswersBulkAndSubmit = (formQuestions, repeat = 5) => {
    const batch = writeBatch(db);

    for (let i = 0; i < repeat; i++) {
      let randomAnswers = generateRandomAnswersSilent(formQuestions);
      const randomID = makeid(20) + '-bot';

      randomAnswers = {
        ...randomAnswers,
        formSubmissionID: randomID,
        createdAt: randomDate(),
        userID: `automatic-bot-${Math.floor(Math.random() * 100)}`,
      }
      // console.log('randomAnswers', randomAnswers);

      // Get a new write batch
      // writeToFirebaseAsyncSilent(randomAnswers, randomID);
      const firestoreRef = doc(db, "automatic_form_submissions_fake", randomID);
      batch.set(firestoreRef, randomAnswers);
      // break;
    }
    batch.commit();
  }

  const generateRandomAnswersSilent = (formQuestions) => {

    let randomAnswers = { formID: formId }
    // console.log(formQuestions)
    formQuestions.forEach(q => {
      let randomAnswer = ''

      if (q.question_type == 'short_answer') {

        if (q.questionID.toLowerCase().includes('name')) {
          // randomAnswer = uniqueNamesGenerator({
          //   dictionaries: [names],
          //   length: 1
          // });
          if (q.questionID.toLowerCase().includes('first'))
            randomAnswer = name()
          else if (q.questionID.toLowerCase().includes('last'))
            randomAnswer = surname()

          else
            randomAnswer = name()
        }
        else if (q.questionID.toLowerCase() == 'gpa')
          randomAnswer = generateRandomNumber(1.0, 4.0);
        else if (q.questionID.toLowerCase() == 'city')
          // randomAnswer = generateRandomCity();
          randomAnswer = faker.address.city()
        else if (q.questionID.toLowerCase() == 'state')
          randomAnswer = faker.address.stateAbbr();
        else if (q.questionID.toLowerCase() == 'major')
          randomAnswer = generateRandomMajor();
        else if (q.questionID.toLowerCase() == 'zipcode') {
          randomAnswer = faker.address.zipCode('#####');
        }
        else if (q.questionID.toLowerCase() == 'email') {
          const domains = [
            '@gmail.com',
            '@example.com',
            '@yahoo.com',
            '@umsystem.edu',
            '@soarai.com',
            '@hotmail.com'
          ];
          const numberDictionary = NumberDictionary.generate({ min: 10, max: 9999 });
          randomAnswer = uniqueNamesGenerator({
            dictionaries: [[username()], numberDictionary, domains],
            length: 3,
            separator: '',
          });
          randomAnswer = faker.internet.email();

        }
        else if (q.question_text.toLowerCase().includes("address")) {
          randomAnswer = faker.address.streetAddress()

        }
        else if (q.question_text.toLowerCase().includes('telephone')
          || q.question_text.toLowerCase().includes('phone number')
          || q.question_text.toLowerCase().includes('call number')) {
          const numberDictionary = NumberDictionary.generate({ length: 10 });
          const customConfig = {
            dictionaries: [numberDictionary],
          };
          console.log(numberDictionary)

          randomAnswer = uniqueNamesGenerator(customConfig);
        }
        else {
          const customConfig = {
            dictionaries: [adjectives, colors],
            separator: ' ',
            length: 2,
            style: 'capital'
          };

          randomAnswer = uniqueNamesGenerator(customConfig);

        }
        // console.log(q.questionID, randomAnswer)
        // setValue(q.questionID, randomAnswer)
        randomAnswers[q.questionID] = randomAnswer
      }


      else if (q.question_type == 'phone_number') {
        let randomAnswer = ''
        const numberDictionary = NumberDictionary.generate({ length: 10 });
        const customConfig = {
          dictionaries: [numberDictionary],
        };
        console.log(numberDictionary)

        randomAnswer = uniqueNamesGenerator(customConfig);
        // setValue(q.questionID, randomAnswer)
        randomAnswers[q.questionID] = randomAnswer


      }
      else if (q.question_type == 'email') {

        const domains = [
          '@gmail.com',
          '@example.com',
          '@yahoo.com',
          '@umsystem.edu',
          '@soarai.com'
        ];
        const numberDictionary = NumberDictionary.generate({ min: 10, max: 9999 });
        const randomAnswer = uniqueNamesGenerator({
          dictionaries: [[username()], numberDictionary, domains],
          length: 3,
          separator: '',
        }); // DangerousSnake123
        randomAnswers[q.questionID] = randomAnswer


      }
      else if (q["question_type"] === "long_answer") {
        // let randomAnswer = loremIpsum(1, 5, 1, false, true)
        // let randomAnswer = loremIpsum({ p: 1, avgSentencesPerParagraph: 3, avgWordsPerSentence: 6 })
        // randomAnswers[q.questionID] = randomAnswer.toString();
        let randomLength = faker.datatype.number({ min: 10, max: 30 })
        let randomAnswer = faker.random.words(randomLength)
        randomAnswers[q.questionID] = randomAnswer.toString();



      }
      else if (q["question_type"] === "multiple_choice") {
        const randomOptionDictionary = NumberDictionary.generate({ min: 1, max: q.options.length });
        const randomAnswer = uniqueNamesGenerator({ dictionaries: [q.options] }); // DangerousSnake123
        randomAnswers[q.questionID] = randomAnswer


      }
      else if (q["question_type"] === "checkbox") {
        // console.log(q)
        const randomNumber = uniqueNamesGenerator({ dictionaries: [NumberDictionary.generate({ min: 0, max: q.options.length })] });
        for (var i = 0; i < randomNumber; i++) {
          const randomProb = uniqueNamesGenerator({
            dictionaries: [NumberDictionary.generate({ min: 0, max: 10 })]
          });
          randomAnswers[q.questionID] = {}

          // const randomNumber = 0
          if (randomProb < 65) {
            // let randomRange = Math.floor(Math.random() * q.options.length);
            const temp = Math.random() * q.options.length;
            // console.log('rantomTemp', temp)
            let randomInd = Math.floor(temp);
            // console.log('randomRange', randomRange)
            // console.log('randomInd', randomInd)

            let randomAnswer = q.options[0]
            if (randomInd < q.options.length) {
              randomAnswer = q.options[randomInd]
            }
            else {
              randomAnswer = q.options.slice(-1)[0]
            }
            console.log(randomAnswer)
            if (randomAnswer.toLowerCase() == 'other') {
              const randomString = loremIpsum({ avgSentencesPerParagraph: 1, avgWordsPerSentence: 3, p: 1 }).toString()
              // setValue(q.questionID + '.check' + randomAnswer, true)
              // setValue(q.questionID + '.other' + randomAnswer, randomString)
              randomAnswers[q.questionID]['.check' + randomAnswer] = true
              randomAnswers[q.questionID]['.other' + randomAnswer] = randomString


            }
            else {
              // setValue(q.questionID + '.' + randomAnswer, randomAnswer)
              randomAnswers[q.questionID][randomAnswer] = randomAnswer

            }
          }
          else {

          }

        }


      }
      else if (q["question_type"] === "date") {
        const yearDictionary = NumberDictionary.generate({ min: 1900, max: 2022 });
        const monthDictionary = NumberDictionary.generate({ min: 1, max: 12 });
        const dayDictionary = NumberDictionary.generate({ min: 1, max: 30 });

        const customConfig = {
          dictionaries: [monthDictionary, dayDictionary, yearDictionary],
          separator: '/'
        };
        const randomAnswer = uniqueNamesGenerator(customConfig); // DangerousSnake123
        console.log(randomAnswer)
        // setValue(q.questionID, parseMultiple(randomAnswer, DateFormats))
        randomAnswers[q.questionID] = parseMultiple(randomAnswer)


      }
      else if (q['question_type'] === 'checkbox_followup') {
        const randomNumber = uniqueNamesGenerator({
          dictionaries: [NumberDictionary.generate({ min: 0, max: 100 })]
        });
        // const randomNumber = 0
        if (randomNumber < 65) {
          // setValue(q.questionID, true)
          randomAnswers[q.questionID] = true

        }
        else {
          // setValue(q.questionID, false)
          randomAnswers[q.questionID] = false


        }

      }
      // else if (q.question_type === 'rating_bubble') {

      // }
      // else if (q.question_type === 'file_upload') {


      // }

    })
    return randomAnswers
  }
  const generateRandomAnswers = (formQuestions) => {

    let randomAnswers = {}
    // console.log(formQuestions)
    formQuestions.forEach(q => {
      let randomAnswer = ''

      if (q.question_type == 'short_answer') {

        if (q.questionID.toLowerCase().includes('name')) {
          // randomAnswer = uniqueNamesGenerator({
          //   dictionaries: [names],
          //   length: 1
          // });
          if (q.questionID.toLowerCase().includes('first'))
            randomAnswer = name()
          else if (q.questionID.toLowerCase().includes('last'))
            randomAnswer = surname()

          else
            randomAnswer = name()
        }
        else if (q.questionID.toLowerCase() == 'gpa')
          randomAnswer = generateRandomNumber(1.0, 4.0);
        else if (q.questionID.toLowerCase() == 'city')
          // randomAnswer = generateRandomCity();
          randomAnswer = faker.address.city()
        else if (q.questionID.toLowerCase() == 'state')
          randomAnswer = faker.address.stateAbbr();
        else if (q.questionID.toLowerCase() == 'major')
          randomAnswer = generateRandomMajor();
        else if (q.questionID.toLowerCase() == 'zipcode') {
          randomAnswer = faker.address.zipCode('#####');
        }
        else if (q.questionID.toLowerCase() == 'email') {
          const domains = [
            '@gmail.com',
            '@example.com',
            '@yahoo.com',
            '@umsystem.edu',
            '@soarai.com',
            '@hotmail.com'
          ];
          const numberDictionary = NumberDictionary.generate({ min: 10, max: 9999 });
          randomAnswer = uniqueNamesGenerator({
            dictionaries: [[username()], numberDictionary, domains],
            length: 3,
            separator: '',
          });
          randomAnswer = faker.internet.email();

        }
        else if (q.question_text.toLowerCase().includes("address")) {
          randomAnswer = faker.address.streetAddress()

        }
        else if (q.question_text.toLowerCase().includes('telephone')
          || q.question_text.toLowerCase().includes('phone number')
          || q.question_text.toLowerCase().includes('call number')) {
          const numberDictionary = NumberDictionary.generate({ length: 10 });
          const customConfig = {
            dictionaries: [numberDictionary],
          };
          console.log(numberDictionary)

          randomAnswer = uniqueNamesGenerator(customConfig);
        }
        else {
          const customConfig = {
            dictionaries: [adjectives, colors],
            separator: ' ',
            length: 2,
            style: 'capital'
          };

          randomAnswer = uniqueNamesGenerator(customConfig);

        }
        // console.log(q.questionID, randomAnswer)
        setValue(q.questionID, randomAnswer)
        randomAnswers[q.questionID] = randomAnswer
      }


      else if (q.question_type == 'phone_number') {
        let randomAnswer = ''
        const numberDictionary = NumberDictionary.generate({ length: 10 });
        const customConfig = {
          dictionaries: [numberDictionary],
        };
        console.log(numberDictionary)

        randomAnswer = uniqueNamesGenerator(customConfig);
        setValue(q.questionID, randomAnswer)

      }
      else if (q.question_type == 'email') {

        const domains = [
          '@gmail.com',
          '@example.com',
          '@yahoo.com',
          '@umsystem.edu',
          '@soarai.com'
        ];
        const numberDictionary = NumberDictionary.generate({ min: 10, max: 9999 });
        const randomAnswer = uniqueNamesGenerator({
          dictionaries: [[username()], numberDictionary, domains],
          length: 3,
          separator: '',
        }); // DangerousSnake123
        setValue(q.questionID, randomAnswer)
      }
      else if (q["question_type"] === "long_answer") {
        // let randomAnswer = loremIpsum(1, 5, 1, false, true)
        // let randomAnswer = loremIpsum({ p: 1, avgSentencesPerParagraph: 3, avgWordsPerSentence: 6 })
        // console.log(randomAnswer)
        let randomLength = faker.datatype.number({ min: 10, max: 30 })
        let randomAnswer = faker.random.words(randomLength)
        randomAnswers[q.questionID] = randomAnswer.toString();

        setValue(q.questionID, randomAnswer.toString())

      }
      else if (q["question_type"] === "multiple_choice") {
        const randomAnswer = uniqueNamesGenerator({ dictionaries: [q.options] });
        setValue(q.questionID, randomAnswer)

      }
      else if (q["question_type"] === "dropdown") {
        const randomAnswer = uniqueNamesGenerator({ dictionaries: [q.options] });
        setValue(q.questionID, randomAnswer)

      }
      else if (q["question_type"] === "checkbox") {
        // console.log(q)
        const randomNumber = uniqueNamesGenerator({ dictionaries: [NumberDictionary.generate({ min: 0, max: q.options.length })] });
        for (var i = 0; i < randomNumber; i++) {
          const randomProb = uniqueNamesGenerator({
            dictionaries: [NumberDictionary.generate({ min: 0, max: 10 })]
          });
          // const randomNumber = 0
          if (randomProb < 65) {
            // let randomRange = NumberDictionary.generate({ min: 0, max: q.options.length })[0]
            // let randomInd = uniqueNamesGenerator({ dictionaries: [randomRange] }); // DangerousSnake123
            const temp = Math.random() * q.options.length;
            // console.log('rantomTemp', temp)
            let randomInd = Math.floor(temp);

            // console.log('randomRange', randomRange)
            // console.log('randomInd', randomInd)

            let randomAnswer = q.options[0]
            if (randomInd < q.options.length) {
              randomAnswer = q.options[randomInd]
            }
            else {
              randomAnswer = q.options.slice(-1)[0]
            }
            // console.log(randomAnswer)
            if (randomAnswer.toLowerCase() == 'other') {
              const randomString = loremIpsum({ avgSentencesPerParagraph: 1, avgWordsPerSentence: 3, p: 1 }).toString()
              setValue(q.questionID + '.check' + processID(randomAnswer), true)
              setValue(q.questionID + '.other' + processID(randomAnswer), randomString)

            }
            else {
              setValue(q.questionID + '.' + processID(randomAnswer), randomAnswer)
            }
          }
          else {

          }

        }


      }
      else if (q["question_type"] === "date") {
        const yearDictionary = NumberDictionary.generate({ min: 1900, max: 2022 });
        const monthDictionary = NumberDictionary.generate({ min: 1, max: 12 });
        const dayDictionary = NumberDictionary.generate({ min: 1, max: 30 });

        const customConfig = {
          dictionaries: [monthDictionary, dayDictionary, yearDictionary],
          separator: '/'
        };
        const randomAnswer = uniqueNamesGenerator(customConfig); // DangerousSnake123
        console.log(randomAnswer)
        setValue(q.questionID, parseMultiple(randomAnswer))

      }
      else if (q['question_type'] === 'checkbox_followup') {
        const randomNumber = uniqueNamesGenerator({
          dictionaries: [NumberDictionary.generate({ min: 0, max: 100 })]
        });
        // const randomNumber = 0
        if (randomNumber < 65) {
          setValue(q.questionID, true)
        }
        else {
          setValue(q.questionID, false)

        }

      }

    })
    return randomAnswers
  }
  const generateValidationSchema = (formQuestions) => {
    let validationSchema = {}
    // console.log(formQuestions)
    formQuestions.forEach(q => {
      if (q.question_type == 'short_answer' || q.question_type == 'long_answer'
        || q.question_type == 'phone_number' || q.question_type == 'email' || q.question_type == 'date') {
        if (q.required) {
          console.log('here', q)

          if (q['parent_questionID'] != null) {
            validationSchema[q.questionID] = Yup.string().nullable(true).test('parentsChecked',
              "Responses cannot be empty!", (result) => {
                let satisfied = false;
                if (q.parent_questionID && getValues(q['parent_questionID']) == true) {
                  if (result) {
                    satisfied = true;
                  }

                }
                else {
                  if (q.parent_conditions) {
                    if (Object.keys(q.parent_conditions).length > 0) {
                      const parentQuestionID = q.parent_questionID;
                      // console.log('parentQuestionID', parentQuestionID)
                      const parentCheckedValue = getValues(parentQuestionID)
                      const parentConditionValue = q.parent_conditions[parentQuestionID][1];
                      // console.log('parentConditionValue', parentConditionValue, 'parentCheckedValue', parentCheckedValue)
                      if (parentCheckedValue != parentConditionValue) {
                        satisfied = true;
                      }
                      else {
                        console.log('result', result)
                        if (result) {
                          satisfied = true;
                        }
                        else {
                          satisfied = false;
                        }
                      }
                    }
                  }

                }

                return satisfied
              })
          }
          else {
            // console.log('no parent!')

            if (q.question_type == 'short_answer' || q.question_type == 'long_answer') {
              // validationSchema[q.questionID] = Yup.string().typeError("Required")

              validationSchema[q.questionID] = Yup.string().nullable(true).test('parentsChecked',
                "Responses cannot be empty!", (result) => {
                  if (result.trim().length == 0) {
                    return false
                  }
                  return true;
                })
            }
            else if (q.question_type == 'phone_number') {
              if (q.question_type == 'short_answer' || q.question_type == 'long_answer') {
                // validationSchema[q.questionID] = Yup.string().typeError("Required")

                validationSchema[q.questionID] = Yup.string().nullable(true).test('parentsChecked',
                  "Responses cannot be empty!", (result) => {
                    if (result.trim().length == 0) {
                      return false
                    }
                    return true;
                  })
              }
            }
            else if (q.question_type == 'email') {
              validationSchema[q.questionID] = Yup.string().required("Required").email("Email is invalid")
            }
            else if (q["question_type"] === "date") {
              if (q.required)
                validationSchema[q.questionID] = Yup.string()
                  .required("Required")
                  .matches(
                    /^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/,
                    "Date of Birth must be a valid date format!"
                  )
            }
          }


        }
      }
      // else if (q.question_type == 'phone_number') {
      //   if (q.required)
      //     validationSchema[q.questionID] = Yup.string().required("Phone Number Required")
      // }
      // else if (q.question_type == 'email') {
      //   if (q.required)
      //     validationSchema[q.questionID] = Yup.string().required("Required").email("Email is invalid")
      // }
      // else if (q["question_type"] === "long_answer") {
      //   if (q.required)
      //     validationSchema[q.questionID] = Yup.string().required("Required")
      // }
      else if (q["question_type"] === "multiple_choice") {
        if (q['parent_questionID'] != null) {
          validationSchema[q.questionID] = Yup.string().nullable(true).test('parentsChecked',
            "Please choose an option!", (result) => {
              let satisfied = false;

              console.log('result', result)
              if (q.parent_questionID && getValues(q['parent_questionID']) == true) {
                if (result) {
                  satisfied = true;
                }

              }
              else {
                if (q.parent_conditions) {
                  if (Object.keys(q.parent_conditions).length > 0) {
                    const parentQuestionID = q.parent_questionID;
                    // console.log('parentQuestionID', parentQuestionID)
                    const parentCheckedValue = getValues(parentQuestionID)
                    const parentConditionValue = q.parent_conditions[parentQuestionID][1];
                    // console.log('parentConditionValue', parentConditionValue, 'parentCheckedValue', parentCheckedValue)
                    if (parentCheckedValue != parentConditionValue) {
                      satisfied = true;
                    }
                    else {
                      // console.log('result', result)
                      // console.log('satisfied', satisfied)

                      if (result) {
                        satisfied = true;
                      }
                    }
                  }
                }

              }
              return satisfied
            })
        }
        else {
          validationSchema[q.questionID] = Yup.string().typeError("Required")
        }

      }
      else if (q["question_type"] === "dropdown") {
        // console.log('here')
        if (q.required)
          validationSchema[q.questionID] = Yup.string().required("Required")

      }
      else if (q["question_type"] === "checkbox") {
        const options = q.options;
        // validationSchema[q.questionID]= yupObject
        validationSchema[q.questionID] = Yup.object().test('allRequirementsPassed', "You must check all of required fields!", (result) => {
          // console.log(result)
          let allRequiredCheck = true;
          if (result) {
            console.log(result)
            // console.log(getValues);
            if (q.requiredOptions && q.requiredOptions.length > 0) {
              console.log(q.requiredOptions)
              q.requiredOptions.forEach(rOption => {
                // console.log(result[])
                if (result[processID(rOption)] == false) {
                  allRequiredCheck = false;
                }
              })
            }
          }
          return allRequiredCheck;
        })
          .test('oneOfRequired',
            'At least one option must be checked!', (result) => {
              let satisfied = false;

              console.log('result', result)
              if (q.parent_questionID && getValues(q['parent_questionID']) == true) {
                if (result) {

                  for (const [key, value] of Object.entries(result)) {
                    if (value != false) {
                      // atLeastChecked = true;
                      satisfied = true;
                    }
                  }
                }

              }
              else {
                if (q.parent_conditions) {
                  if (Object.keys(q.parent_conditions).length > 0) {
                    const parentQuestionID = q.parent_questionID;
                    // console.log('parentQuestionID', parentQuestionID)
                    const parentCheckedValue = getValues(parentQuestionID)
                    const parentConditionValue = q.parent_conditions[parentQuestionID][1];
                    // console.log('parentConditionValue', parentConditionValue, 'parentCheckedValue', parentCheckedValue)
                    if (parentCheckedValue != parentConditionValue) {
                      satisfied = true;
                    }
                    else {
                      console.log('result', result)

                      if (result) {
                        for (const [key, value] of Object.entries(result)) {
                          if (value != false) {
                            // atLeastChecked = true;
                            satisfied = true;
                          }
                        }
                      }
                      console.log('satisfied', satisfied)

                    }
                  }
                }
                else {
                  if (result) {

                    for (const [key, value] of Object.entries(result)) {
                      if (value != false) {
                        // atLeastChecked = true;
                        satisfied = true;
                      }
                    }
                  }
                }

              }
              return satisfied
            })

        // validationSchema[q.questionID] = Yup.boolean().oneOf([true],'Message');
      }
      // else if (q["question_type"] === "date") {
      //   if (q.required)
      //     validationSchema[q.questionID] = Yup.string()
      //       .required("Required")
      //       .matches(
      //         /^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/,
      //         "Date of Birth must be a valid date format!"
      //       )
      // }
      // else if (q['question_type'] === 'checkbox_followup') {
      //   validationSchema[q.questionID]= Yup.string()
      // }
      else if (q.question_type === 'matrix') {
        validationSchema[q.questionID] = Yup.object()
          .test('allRequired',
            'Responses cannot be empty!', (result) => {
              console.log('matrix result: ', result)
              //user did not specify requirements for any particular options
              let satisfied = true;

              if (result) {
                for (const [key, value] of Object.entries(result)) {
                  if (value == null) {
                    console.log('null value in matrix!');
                    satisfied = false;
                    // break;
                  }
                }
                if (q.parent_questionID) { //if checkbox belongs to a parent questionID
                  console.log('q.parent_questionID', getValues(q.parent_questionID))
                  if (getValues(q.parent_questionID) == false) {
                    console.log('optional because parent is not checked');

                    satisfied = true;
                  }
                  if (q.parent_conditions) {
                    if (Object.keys(q.parent_conditions).length > 0) {
                      const parentQuestionID = q.parent_questionID;
                      // console.log('parentQuestionID', parentQuestionID)
                      const parentCheckedValue = getValues(parentQuestionID)
                      const parentConditionValue = q.parent_conditions[parentQuestionID][1];
                      console.log('parentConditionValue', parentConditionValue, 'parentCheckedValue', parentCheckedValue)
                      if (parentCheckedValue != parentConditionValue) {
                        satisfied = true;
                      }
                    }
                  }
                }
                // console.log('satisfied: ', satisfied);
              }

              return satisfied
            })

      }
      else if (q.question_type === 'file_upload') {
        const maxLimit = 10000000
        if (q.required) {
          validationSchema[q.questionID] = Yup.mixed()
            .test("required", "Please attach a file!", (file) => {
              // return file && file.size <-- u can use this if you don't want to allow empty files to be uploaded;

              if (file && file.length > 0) return true;
              return false;
            })
            .test("fileSize", `The file is too large (Limit: <=${maxLimit / 1000000} MB)`, (file) => {
              //if u want to allow only certain file sizes
              console.log(file)
              // console.log(file[0].size);
              if (file && file.length > 0) {
                return file[0].size <= maxLimit;
              }
              return false;
            })
        }


      }

    })
    return validationSchema
  }
  useEffect(() => {
    const docRef = doc(db, "form_library", formId);
    onSnapshot(docRef, (doc) => {
      // console.log("Current data: ", doc.data());
      if (doc.exists()) {
        const data = doc.data();
        const formDomain = data.formDomain ? data.formDomain : 'Common'
        const allowedRoles = data.allowedRoles ? data.allowedRoles : []
        const allowedInstitutions = data.allowedInstitutions ? data.allowedInstitutions : []
        let renderPage = false;
        if (formDomain == 'Specific') {
          if (userData) {
            if (isHubLead(userData.atype)) {
              renderPage = true;
              //Not Hub Lead error
            }
            else if (data.allowedInstitutions && data.allowedInstitutions.length > 0 && userData.institution) {
              data.allowedInstitutions.forEach(institution => {
                if (institution.includes(userData.institution)) {
                  renderPage = true;
                  console.log('Allowed Institutions error');

                  //Allowed Institutions Error;

                }
              })
            }

          }
        }
        else {
          // console.log('renderPage here', renderPage)
          // console.log('renderPage excludedInstitutions', data.excludedInstitutions)
          if (data.excludedInstitutions && data.excludedInstitutions.length > 0) {
            if (userData && !data.excludedInstitutions.includes(userData.institution)) {
              renderPage = true;
            }
          }
          else {
            renderPage = true;

          }
        }
        console.log('renderPage', renderPage)
        setRenderPage(renderPage);
        console.log("Document data:", data);


        setFormQuestions(data['form_questions']);

        setFormMetadata(data);

        const newValidationSchema = generateValidationSchema(data['form_questions'])
        // console.log(newValidationSchema)
        // const newRandomAnswers = generateRandomAnswers(data['form_questions'])
        // setRandomAnswers(newRandomAnswers);
        setValidationSchema(newValidationSchema)
      } else {
        // doc.data() will be undefined in this case
        console.log("No such document!");
        setFormQuestions(null)
      }
    });
    // getDoc(docRef).then((docSnap) => {});
    if (user) {
      const formUploadRef = collection(db, "automatic_form_submissions");
      const q = query(formUploadRef, where("userID", "==", user.uid), where('formID', '==', formId), orderBy("createdAt", "desc"))
      const qPrev = query(formUploadRef, where("userID", "==", user.uid), orderBy("createdAt", "desc"))

      const formUploadTempRef = collection(db, "automatic_form_submissions_temp");
      const qTemp = query(formUploadTempRef, where("userID", "==", user.uid), where('formID', '==', formId), orderBy("createdAt", "desc"))

      const latestRetrievedForm = []
      const previousSubmissionsServer = {}
      getDocs(qPrev).then(querySnapshot => {
        if (querySnapshot.size > 0) {
          querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            const rawData = doc.data();
            console.log('Latest Form: ' + rawData.formSubmissionID)
            if (rawData.createdAt) {
              const createdDate = rawData.createdAt.toDate().toDateString()
              const createdTime = rawData.createdAt.toDate().toLocaleTimeString('en-US');
              rawData.createdDateTime = `${createdDate}, ${createdTime}`
            }
            if (!Object.keys(previousSubmissionsServer).includes(rawData.formID)) {
              previousSubmissionsServer[rawData.formID] = rawData;
            }

            // console.log(rawData);
          });

          setPreviousSubmissions({ ...previousSubmissions, ...previousSubmissionsServer })
        }
      })
      getDocs(qTemp).then(querySnapshotTemp => { //Check latest saves,
        if (querySnapshotTemp.size > 0) { //if yes, pull save
          querySnapshotTemp.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            const rawData = doc.data();
            console.log('Latest Saved Form: ' + rawData.formSubmissionID)
            if (rawData.createdAt) {
              const createdDate = rawData.createdAt.toDate().toDateString()
              const createdTime = rawData.createdAt.toDate().toLocaleTimeString('en-US');
              rawData.createdDateTime = `${createdDate}, ${createdTime}`
            }

            latestRetrievedForm.push(rawData)
            if (!Object.keys(previousSubmissionsServer).includes(rawData.formID)) {
              previousSubmissionsServer[rawData.formID] = rawData;
            }

            // console.log(rawData);
          });
          // formUploadHistData.sort(function(x, y){
          //     return y.timeStamp - x.timeStamp; //sort y before x
          // })
          setLatestRetrievedForm(latestRetrievedForm[0])
          console.log('latestRetrievedForm', latestRetrievedForm[0]);
          setFormRetrieved('saved');
          setPreviousSubmissions({ ...previousSubmissions, ...previousSubmissionsServer })
          if (submitSuccessfully == null) {
            console.log(submitSuccessfully, formRetrieved, latestRetrievedForm)
            setShowLatestFormConsent(true);
            // console.log('setShowLatestTrue')
          }
          else {
            setShowLatestFormConsent(false);
          }
        }
        else { //if not, pull previous completed 
          getDocs(q).then(querySnapshot => {
            if (querySnapshot.size > 0) {
              querySnapshot.forEach((doc) => {
                // doc.data() is never undefined for query doc snapshots
                const rawData = doc.data();
                console.log('Latest Form: ' + rawData.formSubmissionID)
                if (rawData.createdAt) {
                  const createdDate = rawData.createdAt.toDate().toDateString()
                  const createdTime = rawData.createdAt.toDate().toLocaleTimeString('en-US');
                  rawData.createdDateTime = `${createdDate}, ${createdTime}`
                }

                latestRetrievedForm.push(rawData)
                if (!Object.keys(previousSubmissionsServer).includes(rawData.formID)) {
                  previousSubmissionsServer[rawData.formID] = rawData;
                }

                // console.log(rawData);
              });
              // formUploadHistData.sort(function(x, y){
              //     return y.timeStamp - x.timeStamp; //sort y before x
              // })
              setLatestRetrievedForm(latestRetrievedForm[0])
              console.log('latestRetrievedForm', latestRetrievedForm[0]);
              setFormRetrieved('completed');
              setPreviousSubmissions({ ...previousSubmissions, ...previousSubmissionsServer })
              if (submitSuccessfully == null) {
                console.log(submitSuccessfully, formRetrieved, latestRetrievedForm)
                setShowLatestFormConsent(true);
                // console.log('setShowLatestTrue')
              }
              else {
                setShowLatestFormConsent(false);
              }
            }
            else {
              setFormRetrieveConsent(false);
              setFormRetrieved(false)
            }

          })
        }
        //Check Form Order




      })


    }
  }, [formId, user, userData, formRetrieved, submitSuccessfully]);
  useEffect(() => {

    if (formQuestions && formQuestions.length > 0) {
      // console.log(formQuestions);
    }
  }, [formQuestions])
  useEffect(() => {
    console.log('previousSubmissions', previousSubmissions)
    console.log('currentFormOrder', currentFormOrder)
    const excludedFormIDs = []
    const revisedFormOrder = [];
    const revisedFormLibrary = [];


    formLibrary.forEach(form => {
      // console.log('form.formDomain', form.formDomain)
      if (form.formDomain == 'Common') {

        if (userData && form.excludedInstitutions && form.excludedInstitutions.includes(userData.institution)) {
          excludedFormIDs.push(form.formID)
        }
      }
      else if (form.formDomain == 'Specific') {
        console.log('form.allowedInstitutions', form.allowedInstitutions)
        if (userData && !form.allowedInstitutions.includes(userData.institution)) {
          excludedFormIDs.push(form.formID)
        }
      }


    })
    currentFormOrder.forEach(formInfo => {
      if (!excludedFormIDs.includes(formInfo.id)) {
        revisedFormOrder.push(formInfo);
      }
    })
    console.log('excludedFormIDs', excludedFormIDs)
    const allOrderID = revisedFormOrder.map(form => form.id);
    console.log('allOrderID', allOrderID)
    if (currentFormOrder.length > 0) {
      formLibrary.forEach((formData, index) => {

        const foundIndex = allOrderID.indexOf(formData.formID);
        if (foundIndex >= 0) {
          formData.sortIndex = foundIndex + 1
        }
        else {
          formData.sortIndex = 10000 + index
        }
        if (!excludedFormIDs.includes(formData.formID)) {
          revisedFormLibrary.push(formData)
        }
      })
    }
    revisedFormLibrary.sort(function (x, y) {
      // console.log(y.currentPrerequisites.length, x.currentPrerequisites.length)
      return x.sortIndex - y.sortIndex; //sort y before x
    })
    console.log('revised formLibrary', revisedFormLibrary)
    console.log('revised revisedFormOrder', revisedFormOrder)





    if (formMetadata && revisedFormLibrary.length > 0) {
      const orderOfForm = allOrderID.indexOf(formMetadata.formID)
      console.log('orderOfForm', allOrderID[orderOfForm - 1], revisedFormOrder[orderOfForm - 1])
      const currentPrerequisites = orderOfForm > 1 ? [allOrderID[orderOfForm - 1]] : []
      const currentPrerequisitesFull = orderOfForm > 1 ? [revisedFormOrder[orderOfForm - 1].content] : []

      let satisfied = false;
      if (currentPrerequisites && currentPrerequisites.length > 0) {
        // console.log('currentPrerequisites (Common Domain)', formMetadata.currentPrerequisites,  )
        console.log('currentPrerequisites (Re-ordered)', currentPrerequisites, ' - ', currentPrerequisitesFull)

        Object.keys(previousSubmissions).forEach(formID => {
          if (currentPrerequisites.includes(formID)) {
            satisfied = true //satisfied if prerequisites are found/completed before
          }
        })

      }
      else {
        satisfied = true;
      }
      if (!satisfied) {
        setPrerequisiteError(`You cannot submit because you have not completed ${currentPrerequisitesFull.join(', ')}.`)
      }
      // console.log('satisfied', satisfied)
      setSatisfied(satisfied)

    }
  }, [previousSubmissions, formMetadata, currentFormOrder, formLibrary, userData])
  useEffect(() => {

    if (formQuestions) {
      // console.log(latestRetrievedForm);
    }
  }, [latestRetrievedForm])

  useEffect(() => {

    console.log('satisfied', satisfied)
  }, [satisfied])

  useEffect(() => {
    // console.log(showLatestFormConsent, submitSuccessfully)
  }, [showLatestFormConsent, submitSuccessfully])
  useEffect(() => {
    if (submitSuccessfully) {
      setShowLatestFormConsent(false);
    }
  }, [showLatestFormConsent, submitSuccessfully])
  const StatusControlComponent = ({ formMetadata, userData }) => {
    if (user && formMetadata && userData) {
      // console.log(formMetadata.userID, user.uid)

      if (formMetadata.status == 'unpublished') {
        if (isHubLead(userData.atype)) {
          return <div style={{ width: 'fit-content', alignContent: 'center', textAlign: 'center' }}>
            <h3 style={{ borderRadius: '25px', fontStyle: "italic", backgroundColor: highlightColor, color: 'white' }}>Form is still in Preview mode (Unpublished). Please double-check the form layout and press "Publish" to make it accessible to other users</h3>
            <button

              //   disabled={botSubmit}
              onClick={() => { publishForm(formMetadata, 'published') }}
              className="btn btn-primary mr-1 btn-lg submit-btn-highlight"
            >
              {"Publish"}
            </button>
          </div>
        }
        else if (isCampusLead(userData.atype)) {
          return <div style={{ width: 'fit-content', alignContent: 'center', textAlign: 'center' }}>
            <h3 style={{ borderRadius: '25px', fontStyle: "italic", backgroundColor: highlightColor, color: 'white' }}>
              &nbsp; Form is set to "Unpublished" by Hub Leads. Please request approval in order to expedite the process. &nbsp;</h3>
            <button

              //   disabled={botSubmit}
              onClick={() => { publishForm(formMetadata, 'awaiting-approval') }}
              className="btn btn-primary mr-1 btn-lg submit-btn-highlight"
            >
              {"Request Approval"}
            </button>
          </div>
        }

      }
      else if (formMetadata.status == 'awaiting-approval') {

        if (isHubLead(userData.atype)) {
          return <div style={{ width: 'auto', alignContent: 'center', textAlign: 'center' }}>
            <h3 style={{ borderRadius: '25px', fontStyle: "italic", backgroundColor: theme.warningColor, color: 'white' }}>&nbsp;&nbsp;Form is awaiting approval from Hub Leads. Click "Approve and Publish" to publish the forms to all corresponding users.&nbsp;&nbsp; </h3>
            <Button

              //   disabled={botSubmit}
              onClick={() => { publishForm(formMetadata, 'published') }}
              // className="btn btn-outline-success mr-1 "
              variant='outline-success'
              size='lg'
            >
              {"Approve and Publish"}
            </Button>
            <Button

              //   disabled={botSubmit}
              onClick={() => { publishForm(formMetadata, 'unpublished') }}
              className="mx-1 "
              variant='secondary'
              size='lg'
            >
              {"Unpublish"}
            </Button>
          </div>
        }
        else if (isCampusLead(userData.atype)) {
          return <div style={{ width: 'auto', alignContent: 'center', textAlign: 'center' }}>
            <h3 style={{ borderRadius: '25px', fontStyle: "italic", backgroundColor: theme.warningColor, color: 'white' }}>&nbsp;&nbsp;Form is awaiting approval from Hub Leads.&nbsp;&nbsp; </h3>

          </div>
        }

      }
      else if (!formMetadata.status || formMetadata.status == 'published') {
        if (isHubLead(userData.atype)) {
          return <div id='container'>
            <div className="mb-3 col-md-12" style={{ textAlign: 'center' }}>
              <h3 style={{ borderRadius: '25px', fontStyle: "italic", backgroundColor: 'limegreen', color: 'white' }}>
                This form is currently published and accessible to all users.</h3>
              <button

                //   disabled={botSubmit}
                onClick={() => { publishForm(formMetadata, 'unpublished') }}
                className="btn btn-warning mr-1 btn-lg"
              >
                {"Unpublish"}
              </button>
            </div>
          </div>
        }
        else if (isCampusLead(userData.atype)) {
          return <div id='container'>
            <div className="mb-3 col-md-12" style={{ textAlign: 'center' }}>
              <h3 style={{ borderRadius: '25px', fontStyle: "italic", backgroundColor: 'limegreen', color: 'white' }}>
                This form is currently published and accessible to all users.</h3>
            </div>
          </div>
        }




      }

    }
    return <></>
  }
  function validatePermission(userData) {
    let passed = false;
    if (formMetadata.status == 'published') {
      if (formMetadata.allowedRoles && formMetadata.allowedRoles.includes(userData.atype)) {
        if (formMetadata.formDomain == 'Common') {
          passed = true;
        }
        else if (formMetadata.formDomain == 'Specific') {
          console.log('formMetadata.allowedInstitutions', formMetadata.allowedInstitutions)
          if (formMetadata.allowedInstitutions && userData.institution) {
            formMetadata.allowedInstitutions.forEach(institution => {
              if (institution.includes(userData.institution)) {
                passed = true;

              }
            })
          }
        }

      }
      else if (isHubLead(userData.atype) || isCampusLead(userData.atype)) {
        if (formMetadata.formDomain == 'Common') {
          passed = true;
        }
        else if (formMetadata.formDomain == 'Specific') {
          console.log('formMetadata.allowedInstitutions', formMetadata.allowedInstitutions)

          if (formMetadata.allowedInstitutions && userData.institution) {
            if (formMetadata.allowedInstitutions && userData.institution) {
              formMetadata.allowedInstitutions.forEach(institution => {
                if (institution.includes(userData.institution)) {
                  passed = true;

                }
              })
            }
          }
        }
      }
      // console.log('condition 1', passed)
    }
    else if (formMetadata.status == 'awaiting-approval' || formMetadata.status == 'unpublished') {
      if (isHubLead(userData.atype)) {
        passed = true
      }
      else if (isCampusLead(userData.atype)) {
        passed = true
      }
      // console.log('condition 2', passed)

    }
    if (formMetadata.userID == user.uid) {
      passed = true;
    }

    // // console.log('passed', passed)
    return passed;
  }
  return (
    <>
      <ReactTooltip backgroundColor={highlightColor} />
      {userData && <FormLibrary setFormLibraryExternal={setFormLibrary} accountType={userData.atype} />}

      {renderPage == true ? <main>


        {submitSuccessfully == null && formRetrieved && latestRetrievedForm &&
          <Modal
            size="lg"
            show={showLatestFormConsent}
            onHide={handleNoLatestForm}
            backdrop="static"
            keyboard={false}
          >
            <Modal.Header closeButton>
              <Modal.Title>
                {formRetrieved == 'completed' && 'Previous Submission Detected!'}
                {formRetrieved == 'saved' && 'Previous Answers Saved!'}

              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {formRetrieved == 'completed' && <p>Your last submission was <b>{latestRetrievedForm.createdDateTime}</b>. Would you like to fill out a new form or edit the submitted form?</p>}
              {formRetrieved == 'saved' && <p>Your answers were saved on <b>{latestRetrievedForm.createdDateTime}</b>. Would you like to fill out a new form or continue with the saved form?</p>}

            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleNoLatestForm}>
                Fill Out A New Form
              </Button>
              <Button variant="primary" onClick={handleYesLatestForm}>
                {formRetrieved == 'completed' && "Edit Your Previous Response"}
                {formRetrieved == 'saved' && "Continue the Form"}

              </Button>
            </Modal.Footer>
          </Modal>
        }
        <Modal show={show} onHide={handleClose} size="lg">
          <Modal.Header closeButton>
            <Modal.Title>
              {submitSuccessfully == 'completed' && 'Submission Successful!'}
              {submitSuccessfully == 'saved' && 'Form Saved Successfully'}

            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Your form has been {submitSuccessfully == 'completed' ? 'submitted' : 'saved'} successfully!
          </Modal.Body>
          <Modal.Footer>
            {submitSuccessfully == 'completed' && formMetadata && formMetadata.currentNextForm && formMetadata.currentNextForm.length > 0 && <Button variant="success" href={`/forms/${formMetadata.currentNextForm[0]}`}>
              Proceed to {formMetadata.currentNextFormFull[0]}
            </Button>}
            {submitSuccessfully == 'saved' && <Button variant="primary" onClick={() => { setShow(false) }}>
              Stay on this page
            </Button>}
            <Button variant="secondary" onClick={handleClose}>
              Go to the Homepage
            </Button>
          </Modal.Footer>
        </Modal>
        {/* <h2>Automatic Form (ID: <i>{formId}</i>)</h2> */}

        <FormProvider {...methods}>
          {!user && <h2>YOU ARE NOT LOGGED IN! Please Login to fill out the form!</h2>}
          <StatusControlComponent formMetadata={formMetadata} userData={userData} />


          {
            // true
            user
              && formQuestions
              && formMetadata
              && userData
              && validatePermission(userData)
              ?
              <form onSubmit={handleSubmit(onSubmit)} ref={thisform}>

                {/* register your input into the hook by invoking the "register" function */}
                {/* <div>
                  <h3><b>For Development Purposes Only!</b></h3>
                  <Button variant="danger"
                    href=""
                    ref={ref}
                    onClick={(e) => {
                      e.preventDefault();
                      generateRandomAnswersBulkAndSubmit(formQuestions, numberOfBulks);

                    }}>{`Add ${numberOfBulks} Random Answers to Firebase`}
                  </Button>
                </div> */}


                {/* <div className="form-check form-switch" style={{ float: 'right' }} >

                  <input
                    style={{ width: "45px", height: '22px', backgroundColor: randomAnswers ? highlightColor : 'gray' }}
                    className="form-check-input"
                    type="checkbox"
                    role="switch"
                    id="generate-random-answers"
                    onClick={() => { setRandomAnswers(!randomAnswers) }}
                  ></input>
                  <label style={{ padding: '5px', fontSize: '16px', fontWeight: randomAnswers ? 'bold' : 'normal' }} className="form-check-label" htmlFor="speechSwitch">
                    Generate Random Answers
                  </label>
                </div> */}

                {formQuestions.map(q => {
                  // console.log('question', q);

                  if (formRetrieveConsent === true) {
                    return <AutomaticQuestion
                      q={q}
                      formQuestions={formQuestions}
                      latestRetrievedForm={latestRetrievedForm}
                      currentFormDisplayQuestion={currentFormDisplayQuestion}
                      setBotTriggerNext={setBotTriggerNext}
                      waitAction={waitAction}
                    />
                  }
                  else if (formRetrieveConsent === false) {
                    return <AutomaticQuestion
                      q={q}
                      formQuestions={formQuestions}
                      latestRetrievedForm={null}
                      currentFormDisplayQuestion={currentFormDisplayQuestion}
                      setBotTriggerNext={setBotTriggerNext}
                      waitAction={waitAction}
                    />
                    // return <></>
                  }


                  // return;
                })}
                {/* include validation with required or other standard HTML validation rules */}
                {/* <input {...register("exampleRequired", { required: true })} /> */}
                {/* errors will return when field validation fails  */}
                {/* {errors['long_answer'] && <span>This field is required</span>} */}

                {/* <input type="submit" /> */}
                <div className="form-group">

                  <SubmitBtn
                    formMetadata={formMetadata}
                    currentFormDisplayQuestion={currentFormDisplayQuestion}
                    satisfied={satisfied}
                    prerequisiteError={prerequisiteError} />

                  <SaveBtn
                    formMetadata={formMetadata}
                    currentFormDisplayQuestion={currentFormDisplayQuestion}
                    satisfied={satisfied}
                    onSaveClick={() => { onSaveClick(getValues()) }} />

                </div>
              </form> : <h2>FORM DOES NOT EXIST OR YOU DON'T HAVE PERMISSION TO VIEW THIS FORM! <br></br> <small>PLEASE CONTACT YOUR INSTITUTION ADMINISTRATOR FOR MORE INFORMATION! </small></h2>}

          {/* <AutomaticChatBot /> */}

          {formRetrieveConsent == true ?
            (formQuestions && formQuestions.length > 0 && <AutomaticBot
              //   validationSchema={validationSchema_m}
              setFormEnd={setBotSubmit}
              setFormCurrentDisplayQuestion={setFormCurrentDisplayQuestion}
              setWaitAction={setWaitAction}
              formQuestions={formQuestions}
              botTriggerNext={botTriggerNext}
              latestRetrievedForm={latestRetrievedForm}
            />)
            : (formRetrieveConsent == false && formQuestions && formQuestions.length > 0 && <AutomaticBot
              //   validationSchema={validationSchema_m}
              setFormEnd={setBotSubmit}
              setFormCurrentDisplayQuestion={setFormCurrentDisplayQuestion}
              setWaitAction={setWaitAction}
              formQuestions={formQuestions}
              botTriggerNext={botTriggerNext}
              latestRetrievedForm={null}
            />)
          }

        </FormProvider>

      </main> : <main>Error: Domain-Institution do not match Form's requirements. Please double-check the system and try again.</main>
      }




    </>)
}
