import React, { useState, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import '../Components/Input.css'
import ReactTooltip from 'react-tooltip';
import { AiFillQuestionCircle, AiOutlineHolder, AiFillCloseCircle } from "react-icons/ai";
import theme from '../Theme/theme'

export const MatrixOption = ({
    labelStyle,
    name,
    label,
    className,
    type,
    labelClass,
    inputClass = "form-control",
    inputPlaceholder,
    instructions,
    onClick,
    ...rest
}) => {
    const { watch,
        unregister,
        register,
        formState: { errors }
    } = useFormContext();
    // let labelStyle = {}
    // if ({...rest}.style && Object.keys({...rest}.style).length > 0 ) {
    //   labelStyle = {color: 'aqua', fontWeight: 'bold'}
    // }
    const highlightColor = theme.highlightColor
    const normalizeInput = (value) => {
        // console.log(value)
        // return nothing if no value
        if (!value) return value;

        // only allows 0-9 inputs
        const currentValue = value.replace(/[^\d]/g, '');
        const cvLength = currentValue.length;
        let formattedString = ''
        if (value.length > 0) {

            // returns: "x", "xx", "xxx"
            if (cvLength < 4) return currentValue;

            // returns: "(xxx)", "(xxx) x", "(xxx) xx", "(xxx) xxx",
            if (cvLength < 7) return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`;

            // returns: "(xxx) xxx-", (xxx) xxx-x", "(xxx) xxx-xx", "(xxx) xxx-xxx", "(xxx) xxx-xxxx"
            formattedString = `(${currentValue.slice(0, 3)}) ${currentValue.slice(3, 6)}-${currentValue.slice(6, 10)}`;
        }
        return formattedString
    };
    if (type == 'tel') {
        // console.log(rest.defaultValue,  normalizeInput(rest.defaultValue))
        rest.defaultValue = normalizeInput(rest.defaultValue)
    }
    const [phoneNum, setPhoneNum] = useState(rest.defaultValue)
    useEffect(() => {
        const passedName = name
        const passedType = type;
        const subscription = watch((value, { name, type }) => {
            // console.log(passedName)
            // console.log(value, name, type);

            if (passedName == name && passedType == 'tel') {
                // console.log(normalizeInput(value[name]))
                setPhoneNum(normalizeInput(value[name]))
            }
        })
        return () => subscription.unsubscribe();
    }, [watch]);
    return (
        <div className={className}>
            <ReactTooltip backgroundColor={highlightColor} />

            {/* <label htmlFor={name} style={errors[name] ? { color: 'red', fontWeight: 'bold' } : labelStyle} className={`form-label ${labelClass}`}>
                {label}
                {instructions && <AiFillQuestionCircle style={{ marginLeft: '2px', marginBottom: '3px', color: highlightColor, fontSize: '17px' }} data-tip={instructions} />}
            </label> */}
            <div className='row'>

                <AiOutlineHolder className='col-md-1 mt-2'

                />
                {type == 'tel' ?
                    <input
                        type={'tel'}
                        style={{ width: '80%' }}
                        className={`${inputClass} ${errors[name] ? "is-invalid" : ""}`}
                        id={name}
                        placeholder={inputPlaceholder}
                        value={phoneNum}
                        {...register(name)}
                        {...rest}
                    // autoFocus
                    /> : <input
                        type={type || "text"}
                        style={{ width: '80%' }}

                        className={`${inputClass} ${errors[name] ? "is-invalid" : ""}`}
                        id={name}
                        placeholder={inputPlaceholder}
                        {...register(name)}
                        {...rest}
                    // autoFocus
                    />
                }
                <AiFillCloseCircle className='col-md-1 mt-2' style={{
                    color: 'gray', fontSize: '18px'
                }} onClick={() => {
                    console.log('unregister ' + name)
                    unregister(name);
                    onClick();
                }} />
            </div>


            <div className="is-invalid"></div>
            <div className="invalid-feedback mx-5">{errors[name]?.message}</div>
        </div>
    );
};
//Fix errors with Auto Focus!