import React, { useState, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { processID } from "../Automatic Forms/Utils";
export function Matrix({ q, latestRetrievedForm }) {
    const {
        register,
        formState: { errors },
    } = useFormContext();
    let rowText = []
    let columnsText = []
    let answerDictTemp = {};
    q.options.forEach(option => {
        rowText.push(option)
        answerDictTemp[option] = null
    })
    q.columns_options.forEach(option => {
        columnsText.push(option)
    })
    const header = ''
    // console.log('latestRetrievedForm', latestRetrievedForm)
    let initialAnswers = new Array(q.options.length).fill(new Array(q.columns_options.length).fill(false))
    if (latestRetrievedForm) {
        if (latestRetrievedForm[q.questionID]) {
            for (const [rowValue, columnValue] of Object.entries(latestRetrievedForm[q.questionID])) {
                console.log(rowValue, columnValue);
                let rowIndex = rowText.indexOf(rowValue);
                let columnIndex = columnsText.indexOf(columnValue);
                console.log(rowIndex, columnIndex);
                if (rowIndex != -1 && columnIndex != -1) {
                    initialAnswers[rowIndex] = initialAnswers[rowIndex].map((value, index) => index === columnIndex);

                }
            }
        }
    }
    // console.log('initialAnswers', initialAnswers)
    const answers = initialAnswers;
    return <>
        <p className={`${errors[q.questionID] ? 'error-message' : ''}`} style={{ marginBottom: 2 }}>{q.question_text}</p>
        <i className={`${errors[q.questionID] ? 'error-message' : ''}`} >{q.question_description ? q.question_description : ''}</i>

        <table className="matrix">
            <thead>
                <tr>
                    <th >{header}</th>
                    {columnsText.map((text, i) => (
                        <th key={i}>{text}</th>
                    ))}
                </tr>
            </thead>
            <tbody>
                {answers.map((row, rowIndex) => (
                    <tr key={rowIndex}>
                        <td className="matrix-row-label">{rowText[rowIndex]}</td>
                        {row.map((cell, columnIndex) => {
                            // console.log('${q.questionID}.${rowText[rowIndex]}', `${q.questionID}.${rowText[rowIndex]}`)

                            return <td key={`${rowIndex}-${columnIndex}`}>
                                <input
                                    type="radio"
                                    name={`row-${rowIndex}`}
                                    id={`${q.questionID}-${rowText[rowIndex]}-${columnsText[columnIndex]}`}
                                    value={columnsText[columnIndex]}
                                    defaultChecked={cell}
                                    // checked={answers[rowIndex][columnIndex]}
                                    // onChange={() => updateAnswer(rowIndex, columnIndex)}
                                    {...register(`${q.questionID}.${processID(rowText[rowIndex])}`)}
                                />
                            </td>
                        }
                        )}
                    </tr>
                ))}
            </tbody>
        </table>
        <div className="error-message">{errors[q.questionID]?.message}</div>
    </>

}
export function MatrixPreview({ q }) {
    const {
        watch,
        formState: { errors },
    } = useFormContext();
    let rowText = []
    let columnsText = []
    // console.log('matrixPreview q', q)
    q.options.forEach(option => {
        rowText.push(option)
    })
    q.columns_options.forEach(option => {
        columnsText.push(option)
    })
    const header = ''
    let initialAnswers = new Array(q.options.length).fill(new Array(q.columns_options.length).fill(false))

    // console.log('initialAnswers', initialAnswers)
    const answers = initialAnswers;
    return <>
        <p style={{ marginBottom: 2 }}>{watch(`${q.questionID}-question`)}</p>
        <i>{watch(`${q.questionID}-description`)}</i>

        <table className="matrix">
            <thead>
                <tr>
                    <th >{header}</th>
                    {columnsText.map((text, i) => (
                        <th key={i}>{text}</th>
                    ))}
                </tr>
            </thead>
            <tbody>
                {answers.map((row, rowIndex) => (
                    <tr key={rowIndex}>
                        <td className="matrix-row-label">{rowText[rowIndex]}</td>
                        {row.map((cell, columnIndex) => (
                            <td key={`${rowIndex}-${columnIndex}`}>
                                <input
                                    type="radio"
                                    name={`row-${rowIndex}`}
                                    id={`${q.questionID}-${rowText[rowIndex]}-${columnsText[columnIndex]}`}
                                    value={columnsText[columnIndex]}

                                // checked={answers[rowIndex][columnIndex]}
                                // onChange={() => updateAnswer(rowIndex, columnIndex)}

                                />
                            </td>
                        ))}
                    </tr>
                ))}
            </tbody>
        </table>
    </>

}
