import React, { useState, useEffect, useRef } from "react";
import { db } from "../Firebase/firebasedb";
import {
  doc,
  setDoc,
  getDoc,
  orderBy,
  serverTimestamp,
  collection,
  query,
  where,
  onSnapshot
} from "firebase/firestore";
import { Link, useNavigate } from "react-router-dom";
import {
  useCSVReader,
  lightenDarkenColor,
  formatFileSize,
  usePapaParse
} from "react-papaparse";
import { Modal, Button } from "react-bootstrap";
import { NavBar } from "../Components/NavBar";
import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";
import styled from "styled-components";
import { Table } from "./SampleTable";
import * as XLSX from "xlsx";
import { FileDrop } from "react-file-drop";
import "./filedrop.css";
import theme from "../Theme/theme";
import { NewFormSummary } from "./NewFormSummary";
import { makeid, processID } from "./Utils";
import { isCampusLead, isHubLead } from "../Fixed Sources/accountTypes";
const GREY = '#CCC';
const GREY_LIGHT = 'rgba(255, 255, 255, 0.4)';
const DEFAULT_REMOVE_HOVER_COLOR = '#A01919';
const REMOVE_HOVER_COLOR_LIGHT = lightenDarkenColor(
  DEFAULT_REMOVE_HOVER_COLOR,
  40
);

const GREY_DIM = "#686868";
const Styles = styled.div`
  padding: 1rem;

  table {
    border-spacing: 0;
    border: 1px solid black;

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid black;
      border-right: 1px solid black;

      :last-child {
        border-right: 0;
      }
    }
  }

  .pagination {
    padding: 0.5rem;
  }
`;
const styles = {
  zone: {
    alignItems: "center",
    border: `2px dashed`,
    borderRadius: 20,
    display: "flex",
    flexDirection: "column",
    height: "100%",
    justifyContent: "center",
    padding: 20,
    borderColor: `${GREY}`
  },
  file: {
    background: "linear-gradient(to bottom, #EEE, #DDD)",
    borderRadius: 20,
    display: "flex",
    height: 120,
    width: 120,
    position: "relative",
    zIndex: 10,
    flexDirection: "column",
    justifyContent: "center"
  },
  info: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    paddingLeft: 10,
    paddingRight: 10
  },
  size: {
    backgroundColor: GREY_LIGHT,
    borderRadius: 3,
    marginBottom: "0.5em",
    justifyContent: "center",
    display: "flex"
  },
  name: {
    backgroundColor: GREY_LIGHT,
    borderRadius: 3,
    fontSize: 12,
    marginBottom: "0.5em"
  },
  progressBar: {
    bottom: 14,
    position: "absolute",
    width: "100%",
    paddingLeft: 10,
    paddingRight: 10
  },
  zoneHover: {
    borderColor: GREY_DIM
  },
  default: {
    borderColor: GREY
  },
  remove: {
    height: 23,
    position: "absolute",
    right: 6,
    top: 6,
    width: 23
  }
};
const dropZoneStyles = {
  borderRadius: 20,
  border: "2px dashed lightgray",
  color: "black",
  padding: 20
};

export default function CSVUpload() {
  const allRoles = {
    student: "Student",
    "student-mentor": "Student Mentor",
    faculty: "Faculty",
    administrator: "Administrator"
  };
  //Handles Authentication and Redirection
  const auth = getAuth();
  const [user, setUser] = useState(null);
  const [userData, setUserData] = useState(false);
  // const [userRole, setUserRole] = useState("administrator");
  const userRole = "administrator";
  const [shouldRedirect, setShouldRedirect] = useState(false);
  const [redirectDestination, setRedirectDestination] = useState("/");
  const [fileProcessedSuccessfully, setFileProcessedSuccessfully] = useState(
    "None"
  );
  const [latestUnuploadedForm, setLatestUnuploadedForm] = useState(null);
  const [blinkingFormID, setBlinkingFormID] = useState(null);
  const [formID, setFormID] = useState(null);

  useEffect(() => {
    if (blinkingFormID) {
      const timer = setTimeout(() => {
        setBlinkingFormID(null);
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [blinkingFormID]);
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, user => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        // const uid = user.uid;
        // ...
        setUser(user);
      } else {
        setRedirectDestination("/");
        setShouldRedirect(true);
      }
    });
    return () => unsubscribe();
  }, [auth]);

  useEffect(() => {
    if (user) {
      const docRef = doc(db, "Users", user.uid);
      getDoc(docRef).then(docSnap => {
        if (docSnap.exists()) {
          console.log("Document data:", docSnap.data());
          const data = docSnap.data();
          setUserData(data);
          if (!isHubLead(data.atype) && !isCampusLead(data.atype)) {
            setRedirectDestination("/");
            setShouldRedirect(true);
          }
          //  else if (data.atype === "faculty") {
          //   setRedirectDestination("/");
          //   setShouldRedirect(true);
          // } else if (data.atype === "student") {
          //   setRedirectDestination("/");
          //   setShouldRedirect(true);
          // } else if (data.atype === "student-mentor") {
          //   setRedirectDestination("/");
          //   setShouldRedirect(true);
          // } else {
          //   setRedirectDestination("/");
          //   setShouldRedirect(true);
          // }
        } else {
          // doc.data() will be undefined in this case
          console.log("No such document!");
          setRedirectDestination("/");
          setShouldRedirect(true);
        }
      });
      //Form Upload History Retrieval
      const formUploadRef = collection(db, "form_library");
      // const q = query(formUploadRef, where("userID", "==", user.uid))
      const q = query(
        formUploadRef,
        where("userID", "==", user.uid),
        orderBy("createdAt", "desc")
      );

      const unsubscribe = onSnapshot(q, querySnapshot => {
        const formUploadHistData = [];
        querySnapshot.forEach(doc => {
          // doc.data() is never undefined for query doc snapshots
          const rawData = doc.data();
          if (formID) {
            if (formID == rawData.formID) {
              setLatestUnuploadedForm(rawData);
            }
          }
          const createdDate = rawData.createdAt.toDate().toDateString();
          const createdTime = rawData.createdAt
            .toDate()
            .toLocaleTimeString("en-US");
          const formFormat = rawData.formFormat ? rawData.formFormat : "csv";
          const formName =
            rawData.form_questions[0].question_type == "form_title"
              ? rawData.form_questions[0].question_text
              : "Untitled Form";
          const status = rawData.status ? rawData.status : "published";
          const action = status == "published" ? "Unpublish" : "Publish";
          const allowedRoles = rawData.allowedRoles ? rawData.allowedRoles : [];
          let allowedRolesString = "None";

          if (allowedRoles.length > 0) {
            let tempList = [];
            allowedRoles.forEach(role => {
              tempList.push(allRoles[role]);
            });
            allowedRolesString = tempList.join(", ");
          }
          const formDomain = rawData.formDomain ? rawData.formDomain : "Common";
          const allowedInstitutions = rawData.allowedInstitutions
            ? rawData.allowedInstitutions
            : [];
          formUploadHistData.push({
            formID: rawData.formID,
            formTitle: formName,
            createdAt: `${createdDate}, ${createdTime}`,
            timeStamp: rawData.createdAt,
            formFormat: formFormat,
            status: status.toUpperCase(),
            action: action,
            reupload: "reupload",
            allowedRoles: allowedRoles,
            allowedRolesString: allowedRolesString,
            deleteAction: "deleteAction",
            formDomain: formDomain,
            allowedInstitutions: allowedInstitutions,
            allowedInstitutionsString:
              formDomain == "Common"
                ? formDomain
                : "Specific: " + allowedInstitutions.join(", "),
            rawData: rawData
          });
        });
        // formUploadHistData.sort(function(x, y){
        //     return y.timeStamp - x.timeStamp; //sort y before x
        // })
        setFormUploadHistory(formUploadHistData);
        console.log(formUploadHistData);
      });
    }
  }, [user, formID]);

  const navigate = useNavigate();
  useEffect(() => {
    if (shouldRedirect) {
      navigate(redirectDestination);
    }
  }, [shouldRedirect, redirectDestination, navigate]);
  //////////////////////////////////////////

  const { CSVReader } = useCSVReader();
  const [zoneHover, setZoneHover] = useState(false);
  const [removeHoverColor, setRemoveHoverColor] = useState(
    DEFAULT_REMOVE_HOVER_COLOR
  );
  // useEffect(() => {
  //   console.log(formID);
  // }, [formID])
  // function makeid(l) {
  //   var text = "";
  //   var char_list = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  //   for (var i = 0; i < l; i++) {
  //     text += char_list.charAt(Math.floor(Math.random() * char_list.length));
  //   }
  //   return text;
  // }
  const fileInputRef = useRef(null);
  const onTargetClick = () => {
    // setFormID(null);
    console.log("here");
    fileInputRef.current.click();
    // setFileProcessedSuccessfully(Date.now())
  };

  // const processID = (rawID) => {
  //   return rawID.replace(/[^a-zA-Z0-9 ]/g, '');
  // }
  const structureCSVText = (csvText, fileName, format) => {
    let parseSuccessfully = true;
    const storedQuestionIDs = [];
    const headers = csvText.data[0];
    let form_questions = [];
    csvText.data.slice(1).forEach((q, index) => {
      let structured_q = {};
      if (q.length >= 3) {
        q.forEach((col, index) => {
          const key = headers[index];
          if (key && key != "") structured_q[key] = col;
        });
        if (!structured_q.questionID) {
          let tempID = processID(structured_q.question_text);
          let i = 0;
          while (storedQuestionIDs.includes(tempID)) {
            tempID += `--${i}`;
            i += 1;
          }
          structured_q.questionID = tempID;
        }

        if (structured_q['options'] !== undefined && structured_q['options'] != '') {
          structured_q['options'] = structured_q['options'].split('||')
        }
        if (structured_q['columns_options'] !== undefined && structured_q['columns_options'] != '') {
          structured_q['columns_options'] = structured_q['columns_options'].split('||')
        }
        form_questions.push(structured_q)
      }
    });
    //Parse Followup
    form_questions.forEach((structured_q, index) => {
      // console.log('structured_q', structured_q);
      if (structured_q.question_type.includes('checkbox_followup')) {
        const numberOfChildren = parseInt(structured_q.question_type.split("checkbox_followup_")[1])
        console.log("numberOfChildren:" + numberOfChildren);
        structured_q.question_type = "checkbox_followup";
        const startOfFirstChildIndex = index + 1; //1 for slice, 1 for current parrent,
        if (
          startOfFirstChildIndex + numberOfChildren <=
          form_questions.length
        ) {
          for (
            let i = startOfFirstChildIndex;
            i < startOfFirstChildIndex + numberOfChildren;
            i++
          ) {
            console.log(form_questions[i]);
            form_questions[i].parent_questionID = structured_q.questionID;
          }
        } else {
          parseSuccessfully = false;
        }
      }
    });
    let data_to_firebase = {};
    if (formID) {
      data_to_firebase = {
        formID: formID,
        form_questions: form_questions,
        userID: user.uid,
        createdAt: serverTimestamp(),
        formFormat: format,
        fileName: fileName
        // status: 'unpublished',
      };
    } else {
      const newFormID = makeid(8);
      data_to_firebase = {
        formID: newFormID,
        form_questions: form_questions,
        userID: user.uid,
        createdAt: serverTimestamp(),
        formFormat: format,
        fileName: fileName,
        status: "unpublished"
      };
    }

    if (parseSuccessfully) {
      // setFileProcessedSuccessfully(true)
      setLatestUnuploadedForm(data_to_firebase);
      // if (formID)
      //   writeToFirebase(data_to_firebase, true)
      // else {
      //   writeToFirebase(data_to_firebase)
      // }
      console.log(data_to_firebase);
    } else {
      // setFileProcessedSuccessfully('no')
      alert(
        "Your uploaded form contains invalid field(s). Please double-check the format."
      );
    }
    // console.log(data_to_firebase)
  };
  const { readString } = usePapaParse();
  const onFileInputChange = async e => {
    console.log(e);
    const { files } = e.target;
    e.preventDefault();
    const reader = new FileReader();
    let fileName = "";
    if (files.length > 0) {
      fileName = files[0].name;
      if (fileName.includes(".csv")) {
        reader.readAsText(files[0]);
      } else if (fileName.includes(".xlsx")) {
        // alert('EXCEL!');
        reader.readAsBinaryString(files[0]);
      } else {
        alert("File Format not supported!");
      }
    }

    reader.onload = async e => {
      const text = e.target.result;
      if (fileName.includes(".csv")) {
        //   alert(text)
        readString(text, {
          worker: true,
          complete: results => {
            console.log("---------------------------");
            console.log(results);
            console.log("---------------------------");
            structureCSVText(results, fileName, "csv");
          }
        });
      } else if (fileName.includes(".xlsx")) {
        const wb = XLSX.read(text, { type: "binary" });
        /* Get first worksheet */
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        /* Convert array of arrays */
        const csvText = XLSX.utils.sheet_to_csv(ws, { header: 1 });
        /* Update state */
        // console.log("Data>>>"+data);
        readString(csvText, {
          worker: true,
          complete: results => {
            console.log("---------------------------");
            console.log(results);
            console.log("---------------------------");
            structureCSVText(results, fileName, "xlsx");
          }
        });
      } else {
      }
    };
  };

  function writeToFirebase(data, merge = false) {
    console.log(data);
    setDoc(doc(db, "form_library", data.formID), data, { merge: merge })
      .then(() => {
        setFormID(data.formID);
        if (merge == false) {
          console.log(`Form ${data.formID} written to Firebase successfully`);
          alert(`Form ${data.formID} written to Firebase successfully`);
        } else {
          console.log(`Form ${data.formID} revised successfully`);
          alert(`Form ${data.formID} revised successfully`);
        }

        // setFileProcessedSuccessfully(Date.now());
      })
      .catch(err => {
        console.log(err);
        alert(err);
        // setFileProcessedSuccessfully(Date.now());
      });
  }
  function toTitleCase(str) {
    return str.replace(/\w\S*/g, function(txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const logOut = () => {
    setShow(false);
    signOut(auth)
      .then(() => {
        // Sign-out successful.
        console.log("Signed out successfully!");
      })
      .catch(error => {
        console.log(error);
      });
  };
  //Table Rendering
  const [formUploadHistory, setFormUploadHistory] = useState([]);

  const columns = React.useMemo(
    () => [
      {
        Header: "Your Uploaded Forms",
        columns: [
          {
            Header: "Status",
            accessor: "status"
          },
          {
            Header: "Form ID",
            accessor: "formID"
          },
          {
            Header: "Form Name",
            accessor: "formTitle"
          },
          {
            Header: "Created",
            accessor: "createdAt"
          },
          {
            Header: "File Format",
            accessor: "formFormat"
          },
          // {
          //   Header: 'Available To',
          //   accessor: 'accessibleTo',
          // },
          {
            Header: "Upload revised version",
            accessor: "reupload"
          },
          {
            Header: "Allowed Roles",
            accessor: "allowedRolesString"
          },
          {
            Header: "Institutions",
            accessor: "allowedInstitutionsString"
          },
          // {
          //   Header: 'Delete Action',
          //   accessor: 'deleteAction',
          // },
          {
            Header: "Action",
            accessor: "action"
          }
        ]
      }
      // {
      //   Header: 'Action',
      //   columns: [
      //     {
      //       Header: '',
      //       accessor: 'action'
      //     }
      //   ]
      // }
    ],
    []
  );
  //////

  return (
    <div>
      {userData && <NavBar handleShow={handleShow} userData={userData} />}
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Logout Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you would like to log out?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="danger" onClick={logOut}>
            Yes!
          </Button>
        </Modal.Footer>
      </Modal>
      <div style={dropZoneStyles}>
        <h3
          style={{
            fontWeight: "bold",
            textAlign: "center",
            color: theme.highlightColor
          }}
        >
          Upload A New Form
        </h3>

        <input
          onChange={onFileInputChange}
          ref={fileInputRef}
          type="file"
          className="hidden"
          // key={fileProcessedSuccessfully}
        />
        <FileDrop
          onFrameDragEnter={event => console.log("onFrameDragEnter", event)}
          onFrameDragLeave={event => console.log("onFrameDragLeave", event)}
          onFrameDrop={event => console.log("onFrameDrop", event)}
          onDragOver={event => console.log("onDragOver", event)}
          onDragLeave={event => console.log("onDragLeave", event)}
          onDrop={(files, event) => console.log("onDrop!", files, event)}
          onTargetClick={onTargetClick}
        >
          <p style={{ fontSize: "17px" }}>
            Drop a CSV (<i>.csv</i>) or Excel file (<i>.xlsx</i>) here to upload
            a <i>new</i> form
          </p>
        </FileDrop>
        <label style={{ backgroundColor: "yellow", width: "auto" }}>
          <i style={{ fontWeight: "bold", color: "red" }}>Note</i>: if you would
          like to revise an existing form, please navigate to the{" "}
          <b>Upload revised version</b> column of the <b>Your Uploaded Forms</b>{" "}
          table below
        </label>
      </div>
      {latestUnuploadedForm && (
        <NewFormSummary
          formID={formID}
          setFormID={setFormID}
          formData={latestUnuploadedForm}
          setLatestUnuploadedForm={setLatestUnuploadedForm}
          fileInputRef={fileInputRef}
          setBlinkingFormID={setBlinkingFormID}
        />
      )}

      {/* {formID && <Link to={`/forms/${formID}`}><h3>Go to form {formID}</h3></Link>} */}
      {formUploadHistory.length > 0 && (
        <Styles>
          <Table
            columns={columns}
            data={formUploadHistory}
            fileInputRef={fileInputRef}
            onTargetClick={onTargetClick}
            setFormID={setFormID}
            setLatestUnuploadedForm={setLatestUnuploadedForm}
            blinkingFormID={blinkingFormID}
            // setFileProcessedSuccessfully={setFileProcessedSuccessfully}
          />
        </Styles>
      )}
    </div>
  );
}
