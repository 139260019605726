import React, { useState, useEffect, useRef } from "react";

import {
    Navbar,
    Nav,
    NavDropdown,
    Container,
    Button,
} from "react-bootstrap";
import { Modal } from "react-bootstrap";
import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";
import { db } from "../Firebase/firebasedb";
import { doc, getDoc, onSnapshot, setDoc } from "firebase/firestore";
import { useNavigate, useLocation } from "react-router-dom";
import { allRolesCompat, atypeOptionsCompat, isHubLead, isStudentOrMentor } from '../Fixed Sources/accountTypes'
import { isCampusLead } from "../Fixed Sources/accountTypes"
import { AiOutlineLogout, AiOutlineProfile } from "react-icons/ai";
import { decode as base64_decode, encode as base64_encode } from 'base-64';
import { FcAssistant } from "react-icons/fc";
import { FaUser, FaUserAlt, FaUserGraduate, FaUserTie } from "react-icons/fa";
import { GrUserManager, GrUserExpert } from "react-icons/gr";
export const NavBar = ({ setUserDataExternal, render = true }) => {
    const location = useLocation();
    const auth = getAuth();

    const [show, setShow] = useState(false);
    const handleCloseMissing = () => setShow(false);
    const handleShowMissing = () => setShow(true);

    const [showNicknameDialog, setShowNicknameDialog] = useState(false);
    const handleCloseNickNameDialog = () => setShowNicknameDialog(false);
    const handleShowNicknameDialog = () => setShowNicknameDialog(true);
    let firstName = '';
    let lastName = '';
    let institution = '';
    let aType = '';
    // console.log(userData)
    const [user, setUser] = useState(null);
    const [userData, setUserData] = useState(null);
    const [shouldRedirect, setShouldRedirect] = useState(false);
    const [redirectDestination, setRedirectDestination] = useState(null);
    const navigate = useNavigate();
    useEffect(() => {
        if (shouldRedirect) {
            navigate(redirectDestination);
        }
    }, [shouldRedirect, redirectDestination, navigate]);
    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                // User is signed in, see docs for a list of available properties
                // https://firebase.google.com/docs/reference/js/firebase.User
                // const uid = user.uid;
                // ...
                setUser(user);
            }
            else {
                // setRedirectDestination("/");
                // setShouldRedirect(true);
            }
        });

        return () => unsubscribe();
    }, [auth]);

    useEffect(() => {
        if (user) {
            const docRef = doc(db, "Users", user.uid);
            onSnapshot(docRef, (docSnap) => {
                if (docSnap.exists()) {
                    console.log("Document data:", docSnap.data());
                    const data = docSnap.data();
                    let role = data.atype
                    let roleFull = 'Administrator'
                    let userRoleFull = 'Student'
                    if (role != '') {
                        data.userRoleFull = allRolesCompat[role]
                    }
                    setUserData(data);
                    if (setUserDataExternal) {
                        setUserDataExternal(data);
                    }
                } else {
                    // doc.data() will be undefined in this case
                    console.log("No such document!");
                    // setRedirectDestination("/");
                    //   setShouldRedirect(true);
                }
            });
        }
    }, [user]);
    const [showLogOut, setShowLogOut] = useState(false);
    const handleClose = () => setShowLogOut(false);
    const handleShow = () => setShowLogOut(true);
    const logOut = () => {
        setShowLogOut(false);
        signOut(auth)
            .then(() => {
                // Sign-out successful.
                console.log("Signed out successfully!");
                if (location.pathname == '/')
                    window.location.reload();
                else {
                    navigate('/')
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };
    useEffect(() => {
        if (userData) {
            console.log(userData)
            if (!userData.institution || userData.institution == '') {
                setShow(true);
            }
            if (isStudentOrMentor(userData.atype)) {
                if (!userData.nickname || userData.nickname == '') {

                    console.log('encrypted userData: ', userData)
                    handleShowNicknameDialog();

                }
                if (!userData.encrypted) {
                    userData.firstName = base64_encode(userData.firstName)
                    userData.lastName = base64_encode(userData.lastName)
                    userData.encrypted = true
                    setDoc(doc(db, "Users", userData.userID), userData).then(() => { console.log('write!!!') });

                }
            }

        }
    }, [userData])
    const ProfileIcon = ({userData}) => {
        if (userData) {
            const position = {marginRight: 2, marginBottom: 2, marginLeft: 3, fontSize: 15}
            const atype = userData.atype
            if (atype == 'student') {
                return <FaUser style = {{...position}}/>
            }
            else if (atype == 'student-mentor') {
                return <GrUserExpert style = {{...position, marginLeft: 4}}/>
            }
            else if (atype == 'faculty') {
                return <FaUserGraduate style = {{...position, marginLeft: 4}}/>
            }
            else if (isCampusLead(atype) || isHubLead(atype)) {
                return <FaUserTie style = {{...position}}/>
            }
        }
        return <></>
    }
    return (
        <div>
            {render && userData && <Navbar bg="light" expand="lg" className="py-3 fs-4">
                <Container fluid>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse
                        id="responsive-navbar-nav"
                        className="justify-content-center"
                    >
                        <Nav className="me-auto">
                            <Nav.Link href="/">Home</Nav.Link>
                            <Nav.Link href="/readme">README</Nav.Link>

                            {/* <Nav.Link href={redirectDestination}>Dashboard</Nav.Link>
                            <NavDropdown title="Forms" id="collasible-nav-dropdown">
                                <NavDropdown.Item href="/understandingform">
                                    Understanding Form
                                </NavDropdown.Item>
                                <NavDropdown.Item href="/mentorform">
                                    Demographic Form
                                </NavDropdown.Item>
                                <NavDropdown.Item href="/transcriptform">
                                    Transcript Update Form
                                </NavDropdown.Item>
                                <NavDropdown.Item href="/participationform">
                                    Participation Form
                                </NavDropdown.Item>
                            </NavDropdown> */}
                        </Nav>
                        <Nav>
                            <Navbar.Text className="fs-6 me-3">
                                {isStudentOrMentor(userData.atype) && userData.nickname && userData.nickname != '' ?
                                    <p><b>{userData.nickname}</b> - {userData.userRoleFull}
                                    <ProfileIcon userData = {userData}/>
                                    <i>({userData.institution})</i></p>
                                    : <p><b>{userData.firstName} {userData.lastName}</b> - {userData.userRoleFull}
                                    <ProfileIcon userData = {userData}/>
                                    <i>
                                        ({userData.institution})
                                        </i></p>}


                            </Navbar.Text>
                        </Nav>
                        <Button className='mx-2' variant="warning" href='/edit-profile' >
                            <AiOutlineProfile style={{ marginRight: 2, marginBottom: 2, fontSize: 16 }} />

                            Edit Profile
                        </Button>
                        <Button variant="danger" onClick={handleShow}>
                            <AiOutlineLogout style={{ marginRight: 2, marginBottom: 2, fontSize: 16 }} />

                            Log Out
                        </Button>

                    </Navbar.Collapse>
                </Container>
            </Navbar>}
            <Modal show={show} onHide={handleCloseMissing}>
                <Modal.Header closeButton>
                    <Modal.Title>Missing Institution</Modal.Title>
                </Modal.Header>
                <Modal.Body>You have not specified your institution. This may affect forms affiliated with "Specific" mode.Please go to the Edit Profile page.</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseMissing}>
                        Okay, I will edit later
                    </Button>
                    <Button variant="danger" href='/edit-profile'>
                        Edit Profile
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={showNicknameDialog} onHide={handleCloseNickNameDialog}>
                <Modal.Header closeButton>
                    <Modal.Title>Missing Nickname</Modal.Title>
                </Modal.Header>
                <Modal.Body>You have not specified your nickname. Please go to the Edit Profile page.</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseNickNameDialog}>
                        Okay, I will edit later
                    </Button>
                    <Button variant="danger" href='/edit-profile'>
                        Edit Profile
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={showLogOut} onHide={handleShow}>
                <Modal.Header closeButton>
                    <Modal.Title>Logout Confirmation</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure you would like to log out?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button variant="danger" onClick={logOut}>
                        Yes!
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>

    );
};