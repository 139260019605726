import React, { useState, useEffect, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import { db } from "../Firebase/firebasedb";
import { doc, setDoc, getDoc, orderBy, serverTimestamp, collection, query, where, onSnapshot } from "firebase/firestore";
// import Dropdown from 'react-bootstrap/Dropdown';
import Button from 'react-bootstrap/Button';
import { Input, RadioGroup, Textarea, Checkbox, CheckboxFollowup } from "../Components";
import { BivariableCharts } from "./BivariableCharts";
import { format } from "date-fns";
// import { CustomMenu, FormLibrary } from "../Automatic Forms/Utils";
// The forwardRef is important!!
// Dropdown needs access to the DOM node in order to position the Menu

export function TableChartRendering() {
    //Drop-down
    const [chartTitle, setChartTitle] = useState(null);
    const [currentEventKey, setCurrentEventKey] = useState(null)
    const [latestAnswerDict, setLatestAnswerDict] = useState(null)
    const [formData, setFormData] = useState(null);
    const [formSubmissionData, setFormSubmissionData] = useState(null);
    const [formSubmissionDataFiltered, setFormSubmissionDataFiltered] = useState([]);

    const [allVariables, setAllVariables] = useState([]);


    const { tableId } = useParams();
    const [chartData, setChartData] = useState(null);
    const transformData = raw => {
        const colnames = raw[0]
        const out = raw.slice(1).map(r => {
            let x = {}
            Object.keys(r).forEach(k => {
                x = { ...x, [colnames[k]]: r[k] }
            })
            return x
        })
        return out
    }
    useEffect(() => {
        const formRef = doc(db, "table_library", tableId)
        const unsubscribe1 = onSnapshot(formRef, (doc) => {
            if (doc.exists()) {
                const data = doc.data();
                console.log('formData', data);
                setFormData({ ...data });
            }

        })

        const formSubmissionRef = collection(db, "automatic_table_submissions");
        const q2 = query(formSubmissionRef, where("formID", "==", tableId));
        const unsubscribe2 = onSnapshot(q2, querySnapshot => {
            console.log(querySnapshot.size)
            let answerDict = {};
            let rawData = [];
            const allKeys = [];

            querySnapshot.forEach((docSnapShot) => {
                const data = docSnapShot.data();
                rawData.push(...transformData(data.form_content));
                console.log(rawData)
            })
            // setLatestAnswerDict({ ...answerDict });
            setFormSubmissionData(rawData);

            console.log(answerDict);
        })

    }, [])
    useEffect(() => {
        if (chartTitle) {
            // console.log(chartData)
            setChartData({
                ...chartData,
                title: chartTitle,

                // labels: Object.keys(aggregatedData),
                // values: Object.values(aggregatedData)
            })
        }
    }, [chartTitle])
    useEffect(() => {
        if (chartData) {
            // console.log(chartData)
        }
    }, [chartData])
    useEffect(() => {
        let answerDict = {};
        const allKeys = [];
        let filteredData = [];
        if (formSubmissionData) {
            formSubmissionData.forEach((data) => {
                // const excludedKeys = ['formID', 'formSubmissionID', 'createdAt']
                const excludedKeys = []
                filteredData.push(data)
                for (const [key, value] of Object.entries(data)) {
                    if (allKeys.length <= 0 && !excludedKeys.includes(key)) {
                        allKeys.push(key);
                    }
                    if (typeof value === 'string' || value instanceof String) {
                        if (key in answerDict) {
                            answerDict[key].push(value);
                        }
                        else {
                            answerDict[key] = [value];
                        }
                    }
                    else {
                        if (typeof value === 'object') {
                            // console.log(value)
                            if (value != null) {
                                for (const [key2, value2] of Object.entries(value)) {
                                    // console.log(key2)
                                    if (key2.includes('checkOther') === false && value2 !== '' && value2 !== false) {
                                        if (key in answerDict) {
                                            answerDict[key].push(value2);
                                        }
                                        else {
                                            answerDict[key] = [value2];
                                        }
                                    }

                                }
                            }

                        }
                    }

                    // answerDict[key] = [...answerDict[key], value]
                }
                allKeys.sort();
            }


            )
            setFormSubmissionDataFiltered([...filteredData])
            setLatestAnswerDict({ ...answerDict });
        }

    }, [formSubmissionData])
    useEffect(() => {
        if (allVariables.length > 0) {
            // console.log(allVariables)
            if (currentEventKey == null) {
                setCurrentEventKey(allVariables[0]);
            }
        }
    }, [allVariables])
    useEffect(() => {
        if (currentEventKey && latestAnswerDict) {
            console.log(currentEventKey)
            const mainKey = currentEventKey

            let aggregatedData = {}
            const uniqueValues = new Set(latestAnswerDict[mainKey])
            uniqueValues.forEach(val => {
                aggregatedData[val] = 0

            })
            latestAnswerDict[mainKey].forEach(val => {
                aggregatedData[val] += 1
            })

            setChartData({
                title: mainKey,
                data: aggregatedData,
                // labels: Object.keys(aggregatedData),
                // values: Object.values(aggregatedData)
            })

        }
    }, [currentEventKey, latestAnswerDict])
    const supportedCharts = ['Bar Chart', 'Pie Chart', 'Line Chart']

    const [currentSelectedChart, setCurrentSelectedChart] = useState(supportedCharts[0])

    const [formLibrary, setFormLibrary] = useState([]);
    useEffect(() => {
        console.log('formLibrary', formLibrary)
    }, [formLibrary])

    return <div>
        {/* <FormLibrary setFormLibraryExternal={setFormLibrary} accountType={'hub-lead-admin'} publishedOnly={false} /> */}
        {formData &&
            <div className='row'>
                <div className='col-md-10'>
                    <h2>{formData.formLongName ? formData.formLongName : formData.formTitle}
                    </h2>
                </div>
                {/* <div className='col-md-2'>
                    {formLibrary.length > 0 && 
                    <Dropdown>
                        <Dropdown.Toggle>
                            View Another Form
                        </Dropdown.Toggle>

                        <Dropdown.Menu as={CustomMenu} >
                            {formLibrary.map(formData => {
                                const formID = formData.formID
                                const formTitle = formData.formLongName ? formData.formLongName : formData.formTitle
                                return <Dropdown.Item href={`/tablecharts/${formID}`}>
                                    <Link to={`/tablecharts/${formID}`} target="_blank" rel="noopener noreferrer"
                                        style={{ textDecoration: "none", color: 'black' }}
                                    >
                                        {formTitle}
                                    </Link>
                                </Dropdown.Item>

                            })}
                        </Dropdown.Menu>
                    </Dropdown>}
                </div> */}

            </div>
        }
        {formSubmissionData &&
            <h3>Number of Records: <b>{formSubmissionData.length}</b></h3>
        }

        {formSubmissionData && <BivariableCharts
            latestAnswerDict={latestAnswerDict}
            // allVariables={allVariables}
            rawData={formSubmissionData}
        />}
    </div>
}
