import { Outlet } from "react-router-dom";
import { useParams } from "react-router-dom";
import { ChartDashboard } from "./ChartDashboard";
const DefaultCharts = () => {
    const { formId } = useParams();
    // console.log(formId);
    return (
        <>
            {!formId && <div>
                <ChartDashboard />
            </div>}
            {formId && <Outlet />}
        </>
    );
};
export default DefaultCharts;