import React, { useState, useEffect, useRef } from "react";

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import { random_rgb } from "../Automatic Forms/Utils";

ChartJS.register(ArcElement, Tooltip, Legend);

export const data = {
    labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange', 'Pink'],
    datasets: [
        {
            label: '# of Votes',
            data: [12, 19, 3, 5, 2, 3],
            backgroundColor: [
                'rgba(255, 99, 132, 0.2)',
                'rgba(54, 162, 235, 0.2)',
                'rgba(255, 206, 86, 0.2)',
                'rgba(75, 192, 192, 0.2)',
                'rgba(153, 102, 255, 0.2)',
                'rgba(255, 159, 64, 0.2)',
                'limegreen', 'pink'
            ],
            borderColor: [
                'rgba(255, 99, 132, 1)',
                'rgba(54, 162, 235, 1)',
                'rgba(255, 206, 86, 1)',
                'rgba(75, 192, 192, 1)',
                'rgba(153, 102, 255, 1)',
                'rgba(255, 159, 64, 1)',
                'limegreen', 'pink'
            ],
            borderWidth: 1,
        },
    ],
};

export const PieChart = ({ chartData, chartRef }) => {
    const [displayChartData, setDisplayChartData] = useState(null);
    const [chartOptions, setChartOptions] = useState({
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: 'Pie Chart',
            },
        },
    })
    const sortArray = (rawDict) => {
        // Create items array
        var items = Object.keys(rawDict).map(function (key) {
            return [key, rawDict[key]];
        });

        // Sort the array based on the second element
        items.sort(function (first, second) {
            return second[1] - first[1];
        });

        // Create a new array with only the first 5 items
        return items.slice(0, 10);
    }


    useEffect(() => {
        if (chartData && chartData.data) {
            // console.log(chartData)
            let rawData = chartData.data;
            let sortedArray = sortArray(rawData);
            let sortedLabels = sortedArray.map(item => item[0])
            let sortedValues = sortedArray.map(item => item[1])

            // console.log(sortedLabels, sortedValues)
            const labels = sortedLabels
            const numberOfRandomColors = 10;
            let randomBackgroundColors = [];
            let randomBorderColors = [];

            console.log('piechart chartData.color', chartData.color)
            if (chartData.color) {
                chartData.color.forEach(color => {
                    const randomAlpha = (Math.random() * (0.4 - 0.8) + 0.4).toFixed(1)

                    randomBackgroundColors.push(color + ',' + randomAlpha + ')')
                    randomBorderColors.push(color + ',1)')
                })
            }
            // else {
            //     [...Array(numberOfRandomColors)].forEach((e, i) => {
            //         const randomColor = random_rgb();
            //         const randomAlpha = (Math.random() * (0.4 - 0.8) + 0.4).toFixed(1)

            //         randomBackgroundColors.push(randomColor + ',' + randomAlpha + ')')
            //         randomBorderColors.push(randomColor + ',1)')

            //     });
            // }
            // console.log('randomBorderColors', randomBorderColors)
            const processedData = {
                labels: labels,
                datasets: [
                    {
                        label: chartData.title ? chartData.title : 'Untitled',
                        data: sortedValues,
                        // backgroundColor: [
                        //     'rgba(255, 99, 132, 0.2)',
                        //     'rgba(54, 162, 235, 0.2)',
                        //     'rgba(255, 206, 86, 0.2)',
                        //     'rgba(75, 192, 192, 0.2)',
                        //     'rgba(153, 102, 255, 0.2)',
                        //     'rgba(255, 159, 64, 0.2)',
                        // ],
                        // borderColor: [
                        //     'rgba(255, 99, 132, 1)',
                        //     'rgba(54, 162, 235, 1)',
                        //     'rgba(255, 206, 86, 1)',
                        //     'rgba(75, 192, 192, 1)',
                        //     'rgba(153, 102, 255, 1)',
                        //     'rgba(255, 159, 64, 1)',
                        // ],
                        backgroundColor: randomBackgroundColors,
                        borderColor: randomBorderColors

                    },
                    // {
                    //     label: 'Dataset 2',
                    //     data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
                    //     backgroundColor: 'rgba(53, 162, 235, 0.5)',
                    // },
                ]
            }
            // console.log(processedData)
            setDisplayChartData(processedData)
            setChartOptions({
                ...chartOptions, plugins: {
                    legend: {
                        position: 'top',
                    },
                    title: {
                        display: true,
                        text: chartData.title,
                    },
                },
            })

        }

    }, [chartData])
    return <div>
        {displayChartData &&
            <Pie data={displayChartData}
                height="300px"
                width="300px"
                options={chartOptions}
                ref={chartRef}
                id='mainChart'
            />}
    </div>
}
