import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { BarCharts } from './BarCharts';
import { LineChart } from './LineCharts';
import { db } from "../Firebase/firebasedb";
import { doc, setDoc, getDoc, orderBy, serverTimestamp, collection, query, where, onSnapshot } from "firebase/firestore";
import { PieChart } from "./PieChart";
import Dropdown from 'react-bootstrap/Dropdown';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { Input, RadioGroup, Textarea, Checkbox, CheckboxFollowup } from "../Components";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar, Line } from 'react-chartjs-2';
import { faker } from '@faker-js/faker';
import { saveAs } from "file-saver";
import { DropdownButton } from "react-bootstrap";
import { useFormContext } from "react-hook-form";
import { makeid, random_rgb, random_rgba } from "../Automatic Forms/Utils";
import { RadarChart } from "./RadarChart";
import { colorFamily, getRandomColor } from "../Fixed Sources/colorScales";

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);
export const options = {
    responsive: true,
    maintainAspectRatio: false,

    plugins: {
        legend: {
            position: 'top',
        },
        title: {
            display: true,
            text: 'Chart.js Bar Chart',
        },
    },
};
// The forwardRef is important!!
// Dropdown needs access to the DOM node in order to position the Menu
const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <Button variant="outline-info"
        href=""
        ref={ref}
        onClick={(e) => {
            e.preventDefault();
            onClick(e);
        }}>{children}
        &#x25bc;</Button>
));
const CustomToggle2 = React.forwardRef(({ children, onClick }, ref) => (
    <Button variant="outline-warning"
        href=""
        ref={ref}
        onClick={(e) => {
            e.preventDefault();
            onClick(e);
        }}>{children}
        &#x25bc;</Button>
));
const CustomToggleChartSelection = React.forwardRef(({ children, onClick }, ref) => (
    <Button variant="outline-secondary"
        href=""
        ref={ref}
        onClick={(e) => {
            e.preventDefault();
            onClick(e);
        }}>{children}
        &#x25bc;</Button>
));
// forwardRef again here!
// Dropdown needs access to the DOM of the Menu to measure it
const CustomMenu = React.forwardRef(
    ({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
        const [value, setValue] = useState('');

        return (
            <div
                ref={ref}
                style={style}
                className={className}
                aria-labelledby={labeledBy}
            >
                <Form.Control
                    autoFocus
                    className="mx-3 my-2 w-auto"
                    placeholder="Type to filter..."
                    onChange={(e) => setValue(e.target.value)}
                    value={value}
                />
                <ul className="list-unstyled">
                    {React.Children.toArray(children).filter(
                        (child) =>
                            !value || child.props.children.toLowerCase().includes(value),
                    )}
                </ul>
            </div>
        );
    },
);
export function BivariableCharts({
    latestAnswerDict,
    rawData, }) {
    //Drop-down
    const chartRef = React.createRef();
    const [chartID] = useState(makeid(3));
    const excludedKeys = ['formID', 'formSubmissionID', 'createdAt', 'userID']

    const {
        register,
        watch,
        formState: { errors },
        getValues,
    } = useFormContext();

    const stacked = watch(`${chartID}-stacked`)
    console.log('stacked', stacked);
    console.log(getValues())
    useEffect(() => {
        console.log('chartRef', chartRef);


        if (chartRef.current) {
            // console.log('chartRef chartInstance',  chartRef.current.toBase64Image());

        }
    }, [chartRef])

    const [chartTitle, setChartTitle] = useState(null);
    const [chartDimension, setChartDimension] = useState({
        width: 500,
        height: 500
    });

    const [currentEventKey, setCurrentEventKey] = useState(null)
    const [currentEventKey2, setCurrentEventKey2] = useState(null)
    const [randomColor, setRandomColor] = useState(getRandomColor())

    const [allVariables, setAllVariables] = useState([]);


    const { formId } = useParams();
    const [chartData, setChartData] = useState(null);
    const [chartOptions, setChartOptions] = useState({
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: 'Bi-Variable Chart',
            },
        },
    })
    useEffect(() => {
        if (chartTitle) {
            // console.log(chartData)
            setChartData({
                ...chartData,
                title: chartTitle,

                // labels: Object.keys(aggregatedData),
                // values: Object.values(aggregatedData)
            })
            setChartOptions({
                ...chartOptions, plugins: {
                    legend: {
                        position: 'top',
                    },
                    title: {
                        display: true,
                        text: chartTitle,
                    },
                },
            })
        }
    }, [chartTitle])
    useEffect(() => {
        if (chartDimension) {
            // console.log(chartData)
            setChartData({
                ...chartData,
                dimension: chartDimension,

                // labels: Object.keys(aggregatedData),
                // values: Object.values(aggregatedData)
            })
        }
    }, [chartDimension])
    useEffect(() => {
        if (chartData) {
            console.log('chartData', chartData)
        }
    }, [chartData])
    useEffect(() => {
        if (allVariables.length > 0) {
            // console.log(allVariables)
            if (currentEventKey == null) {
                setCurrentEventKey(allVariables[0]);
            }
        }
    }, [allVariables])
    useEffect(() => {
        // console.log('latestAnswerDict', latestAnswerDict)
        if (currentEventKey && latestAnswerDict) {
            console.log(currentEventKey, currentEventKey2)

            if (currentEventKey != currentEventKey2 && currentEventKey2 != null && currentEventKey2.toLowerCase() != 'null') {
                // console.log(rawData);
                let allSecondaryValues = new Set(latestAnswerDict[currentEventKey2])
                allSecondaryValues = Array.from(allSecondaryValues)
                let uniquePrimaryXValues = new Set(latestAnswerDict[currentEventKey])
                const aggregateDict = {}
                allSecondaryValues.forEach(val => {
                    aggregateDict[val] = {}
                    uniquePrimaryXValues.forEach(prim_val => {
                        aggregateDict[val][prim_val] = 0;
                    })
                })

                if (rawData && rawData.length > 0) {
                    rawData.forEach(data => {
                        let primaryKeyValue = data[currentEventKey]
                        let secondaryKeyValue = data[currentEventKey2]

                        if (typeof primaryKeyValue === 'object') { //first key is checkbox answer
                            for (const [eachKey, eachValue] of Object.entries(primaryKeyValue)) {
                                if (typeof secondaryKeyValue === 'object') {
                                    for (const [eachKey2, eachValue2] of Object.entries(secondaryKeyValue)) {
                                        if (typeof eachValue2 === 'string' && typeof eachValue === 'string')
                                            aggregateDict[eachValue2][eachValue] += 1 //both are answers to checkboxes
                                    }
                                }
                                else {
                                    if (typeof secondaryKeyValue === 'string' && typeof eachValue === 'string')
                                        aggregateDict[secondaryKeyValue][eachValue] += 1 //just the primary key is checkbox

                                }
                            }
                        }
                        else if (typeof primaryKeyValue === 'string') { //first key is a normal answer
                            if (typeof secondaryKeyValue === 'object') { //second key is checkbox answer
                                for (const [eachKey2, eachValue2] of Object.entries(secondaryKeyValue)) {
                                    // console.log('eachValue2', eachValue2)
                                    if (typeof eachValue2 === 'string')
                                        aggregateDict[eachValue2][primaryKeyValue] += 1 //both are answers to checkboxes
                                }
                            }
                            else { //second key is normal answer
                                if (aggregateDict[secondaryKeyValue])
                                    aggregateDict[secondaryKeyValue][primaryKeyValue] += 1
                                else {

                                    aggregateDict[secondaryKeyValue] = { primaryKeyValue: 0 }
                                }
                            }
                        }
                        else {
                            console.log('not supported', primaryKeyValue)
                        }


                        // console.log('data item', data[currentEventKey])
                        // aggregateDict[secondaryKeyValue][primaryKeyValue] += 1
                    })

                }
                console.log(aggregateDict)
                const backgroundColors = [
                    'rgba(255, 99, 132, 0.2)',
                    'rgba(54, 162, 235, 0.2)',
                    'rgba(255, 206, 86, 0.2)',
                    'rgba(75, 192, 192, 0.2)',
                    'rgba(153, 102, 255, 0.2)',
                    'rgba(255, 159, 64, 0.2)',
                    'limegreen', 'pink'
                ]
                let datasets = []
                const labels = Array.from(uniquePrimaryXValues)
                allSecondaryValues.forEach((label, index) => {
                    datasets.push({
                        label: label,
                        data: Object.values(aggregateDict[label]),
                        backgroundColor: backgroundColors[index % backgroundColors.length]
                    })
                })

                const chartData = {
                    labels,
                    datasets: datasets
                };
                setChartData({
                    ...chartData,
                    title: `${currentEventKey} by ${currentEventKey2}`,
                    data: chartData,
                    dimension: chartDimension
                })
                setChartOptions({
                    ...chartOptions, plugins: {
                        legend: {
                            position: 'top',
                        },
                        title: {
                            display: true,
                            text: `${currentEventKey} by ${currentEventKey2}`,
                        },
                    },
                })
                console.log(datasets)

            }
            else {
                // console.log('here', latestAnswerDict)

                const mainKey = currentEventKey

                let aggregatedData = {}
                const uniqueValues = new Set(latestAnswerDict[mainKey])
                uniqueValues.forEach(val => {
                    aggregatedData[val] = 0

                })
                console.log('mainKey', mainKey)
                console.log('latestAnswerDict', latestAnswerDict)
                if (latestAnswerDict[mainKey]) {
                    latestAnswerDict[mainKey].forEach(val => {
                        aggregatedData[val] += 1
                    })
                }


                setChartData({
                    title: mainKey,
                    data: aggregatedData,
                })

            }
            // console.log('here')
            // const mainKey = currentEventKey

            // let aggregatedData = {}
            // const uniqueValues = new Set(latestAnswerDict[mainKey])
            // uniqueValues.forEach(val => {
            //     aggregatedData[val] = 0

            // })
            // latestAnswerDict[mainKey].forEach(val => {
            //     aggregatedData[val] += 1
            // })

            // setChartData({
            //     title: mainKey,
            //     data: aggregatedData,
            // })


        }

    }, [currentEventKey, currentEventKey2, latestAnswerDict])
    useEffect(() => {
        if (latestAnswerDict) {
            let allKeys = Object.keys(latestAnswerDict);
            allKeys.sort();
            setAllVariables(allKeys)
        }
    }, [latestAnswerDict])
    const supportedCharts = ['Bar Chart', 'Pie Chart', 'Line Chart', 'Radar Chart']
    const supportedChartsDouble = ['Bar Chart']

    const [currentSelectedChart, setCurrentSelectedChart] = useState(supportedCharts[0])
    useEffect(() => {
        if (!supportedChartsDouble.includes(currentSelectedChart)) {
            setCurrentEventKey2(null) //reset secondary key if chart doesn't support 2 dimensions
        }
    }, [currentSelectedChart])
    const saveCanvas = () => {
        //save to png
        const canvasSave = document.getElementById('mainChart');

        // const canvasSave = chartRef.current;
        console.log('canvasSave', canvasSave)

        canvasSave.toBlob(function (blob) {
            saveAs(blob, "testing.png")
        })
    }
    const RenderSelectedChart = ({ chartType, chartData, chartRef }) => {
        if (chartType == 'Pie Chart') {
            const numberOfRandomColors = 10;
            let randomColors = JSON.parse(JSON.stringify(colorFamily));
            [...Array(numberOfRandomColors)].forEach((e, i) => {
                const random_color = random_rgb();
                randomColors.push(random_color)
            });
            chartData.color = randomColors;
            return <PieChart chartData={chartData} chartRef={chartRef} />
        }
        else if (chartType == 'Bar Chart') {
            chartData.color = randomColor;
            return <BarCharts chartData={chartData} chartRef={chartRef} />
        }
        else if (chartType == 'Line Chart') {
            chartData.color = randomColor;

            return <LineChart chartData={chartData} chartRef={chartRef} />
        }
        else if (chartType == 'Radar Chart') {
            chartData.color = randomColor;

            return <RadarChart chartData={chartData} chartRef={chartRef} />
        }
        return <></>
    }

    // const allVariables = ['Red', 'Blue', 'Orange', 'Red-Orange']
    const BiVariableChartsRendering = ({ primaryVariable, secondaryVariable, chartData, chartRef, currentSelectedChart }) => {
        console.log(chartData)
        // console.log('hello')
        if (chartData) {
            if (primaryVariable) {

                if (secondaryVariable && secondaryVariable != primaryVariable) {
                    if (secondaryVariable.toLowerCase() != 'null') {
                        console.log(chartData.data)
                        if (currentSelectedChart == 'Bar Chart') {
                            let localChartOptions = chartOptions;

                            if (stacked) {
                                localChartOptions = {
                                    ...chartOptions, scales: {
                                        x: {
                                            stacked: true,
                                        },
                                        y: {
                                            stacked: true,
                                        },
                                    },
                                }
                            }
                            else {
                                localChartOptions = {
                                    ...chartOptions, scales: {
                                        x: {
                                            stacked: false,
                                        },
                                        y: {
                                            stacked: false,
                                        },
                                    },
                                }
                            }
                            return <div className='col-md-12'>
                                {/* <Line options={chartOptions} data={chartData.data}
                                height="400px"
                                width="500px"
                                ref = {chartRef}
                                id = 'mainChart'
                            /> */}

                                <Bar options={localChartOptions} data={chartData.data}
                                    height="400px"
                                    width="500px"
                                    ref={chartRef}
                                // id='mainChart'
                                />
                            </div>
                        }

                    }
                }
                else {
                    console.log('Single Variable Only!')
                    return <RenderSelectedChart chartType={currentSelectedChart} chartData={chartData} chartRef={chartRef} />
                }
            }

        }
        return <></>

    }
    const MemoizedBiVariableChartsRendering = React.memo(BiVariableChartsRendering);
    return <div style={{ minHeight: 600 }}>
        {/* <LineChart /> */}

        <div className='row'>
            <div className='col-md-3' style={{ textAlign: 'center', alignItems: 'center', display: 'flex' }}>
                <Dropdown onSelect={(eventKey, event) => {
                    setCurrentSelectedChart(eventKey)
                }}>
                    <Dropdown.Toggle as={CustomToggleChartSelection} id="dropdown-custom-components">
                        Select Chart Type {currentSelectedChart ? `(${currentSelectedChart}) ` : ' '}
                    </Dropdown.Toggle>

                    <Dropdown.Menu as={CustomMenu} >
                        {supportedCharts.map(chartType => {
                            return <Dropdown.Item eventKey={chartType} active={chartType == currentSelectedChart}>{chartType}</Dropdown.Item>
                        })}
                    </Dropdown.Menu>
                </Dropdown>

            </div>
            {currentSelectedChart != 'Pie Chart' && <div className='col-md-3' style={{ textAlign: 'center', alignItems: 'center', display: 'flex' }}>
                <Dropdown onSelect={(eventKey, event) => {
                    console.log('EventKey', eventKey)
                    setRandomColor(eventKey)
                }}>
                    <Dropdown.Toggle variant='outline-secondary'>
                        Chart Color: <div class="square-family" style={{ backgroundColor: randomColor }}></div>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        {colorFamily.map(color => {
                            return <Dropdown.Item eventKey={color} active={randomColor == color}>
                                <div class="square-family" style={{ backgroundColor: color }}></div>
                            </Dropdown.Item>
                        })}
                    </Dropdown.Menu>
                </Dropdown>
            </div>}
            {/* <div className={"col-md-4"}  >
                <div className='row' style={{ textAlign: 'center', alignItems: 'center', display: 'flex' }}  >
                    <div className='col-md-3' >
                        <span>Dimension: </span>
                    </div>
                    <div className='col-md-3'>
                        <input
                            id='chart-width'
                            type={'text'}
                            className={'form-control '}
                            placeholder={'500'}
                            onChange={(event) => {
                                if (event.target.value) {
                                    setChartDimension({ ...chartDimension, width: event.target.value });
                                }
                                else {
                                    setChartDimension({ ...chartDimension, width: 500 });

                                }
                            }}

                        />
                    </div>
                    <div className={"col-md-1"}>
                        <h4>x</h4>
                    </div>
                    <div className={"col-md-3"}>

                        <input
                            id='chart-height'
                            type={'text'}
                            className={'form-control '}
                            placeholder={'500'}
                            onChange={(event) => {
                                if (event.target.value) {
                                    setChartDimension({ ...chartDimension, height: event.target.value });
                                }
                                else {
                                    setChartDimension({ ...chartDimension, height: 500 });

                                }

                            }}

                        />
                    </div>

                </div>
            </div> */}
        </div>

        <div className="row">


            <Dropdown className='col-md-4 mt-3' onSelect={(eventKey, event) => { setCurrentEventKey(eventKey) }}>
                <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
                    Select Primary Variable {currentEventKey ? `(${currentEventKey}) ` : ' '}
                </Dropdown.Toggle>

                <Dropdown.Menu as={CustomMenu} >
                    {allVariables.map(primVar => {
                        if (!excludedKeys.includes(primVar))
                            return <Dropdown.Item eventKey={primVar} active={primVar == currentEventKey}>{primVar}</Dropdown.Item>
                    })}
                </Dropdown.Menu>
            </Dropdown>


            {supportedChartsDouble.includes(currentSelectedChart) && <Dropdown className='col-md-4 mt-3' onSelect={(eventKey, event) => {
                if (currentEventKey2 != eventKey) {
                    if (eventKey != 'None')
                        setCurrentEventKey2(eventKey);
                    else {
                        setCurrentEventKey2(null);

                    }
                    setChartData(null)
                }

            }}>
                <Dropdown.Toggle as={CustomToggle2} id="dropdown-custom-components">
                    Select Secondary Variable {currentEventKey2 ? `(${currentEventKey2}) ` : ' (None)'}
                </Dropdown.Toggle>

                <Dropdown.Menu as={CustomMenu} >
                    <Dropdown.Item eventKey={'None'} active={currentEventKey2 == 'None'}>{'None'}</Dropdown.Item>

                    {allVariables.map(variable => {
                        if (!excludedKeys.includes(variable) && variable != currentEventKey) {
                            return <Dropdown.Item eventKey={variable} active={variable == currentEventKey2}>{variable}</Dropdown.Item>
                        }
                    })}
                </Dropdown.Menu>
            </Dropdown>}

        </div>


        <div className='row'>
            <label htmlFor={'chart-name'} className={`form-label`}>
                Enter Chart Name:
            </label>
            <div className={"mb-3 col-md-4"}>
                <input
                    id='chart-name'
                    type={'text'}
                    className={'form-control '}
                    placeholder={'Chart Name Here...'}
                    onChange={(event) => {
                        setChartTitle(event.target.value);
                    }}

                />


            </div>
            {supportedChartsDouble.includes(currentSelectedChart)
                && currentEventKey2 &&
                <div className='col-md-2 form-check mx-3'>

                    <label>Stacked</label>

                    <input
                        className="form-check-input"
                        type="checkbox"
                        id={`${chartID}-stacked`}
                        // value={true}
                        value={`${chartID}-stacked`}
                        // defaultChecked={defaultCheckValue}
                        {...register(`${chartID}-stacked`)}
                    />




                </div>}
        </div>
        <MemoizedBiVariableChartsRendering
            primaryVariable={currentEventKey}
            secondaryVariable={currentEventKey2}
            chartData={chartData}
            chartRef={chartRef}
            currentSelectedChart={currentSelectedChart}
        />

        {/* <RenderSelectedChart chartType={currentSelectedChart} chartData={chartData} /> */}


    </div>
}
