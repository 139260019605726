import React, { useState, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import '../Components/Input.css'
import ReactTooltip from 'react-tooltip';
import { AiFillQuestionCircle, AiOutlineHolder, AiFillCloseCircle, AiFillExclamationCircle } from "react-icons/ai";
import theme from '../Theme/theme'
import { Button, Dropdown } from "react-bootstrap";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { ConditionalMultipleChoice } from "./ConditionalMultipleChoice";
export const MultipleChoiceOption = ({
    labelStyle,
    name,
    label,
    className,
    type,
    labelClass,
    inputClass = "form-control",
    inputPlaceholder,
    instructions,
    onClick,
    currentQuestionList,
    setCurrentQuestionList,
    q,
    optionIndex,
    conditionalDict,
    ...rest
}) => {
    const { watch,
        unregister,
        register,
        formState: { errors }
    } = useFormContext();
    // let labelStyle = {}
    // if ({...rest}.style && Object.keys({...rest}.style).length > 0 ) {
    //   labelStyle = {color: 'aqua', fontWeight: 'bold'}
    // }
    const highlightColor = theme.highlightColor
    const normalizeInput = (value) => {
        // console.log(value)
        // return nothing if no value
        if (!value) return value;

        // only allows 0-9 inputs
        const currentValue = value.replace(/[^\d]/g, '');
        const cvLength = currentValue.length;
        let formattedString = ''
        if (value.length > 0) {

            // returns: "x", "xx", "xxx"
            if (cvLength < 4) return currentValue;

            // returns: "(xxx)", "(xxx) x", "(xxx) xx", "(xxx) xxx",
            if (cvLength < 7) return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`;

            // returns: "(xxx) xxx-", (xxx) xxx-x", "(xxx) xxx-xx", "(xxx) xxx-xxx", "(xxx) xxx-xxxx"
            formattedString = `(${currentValue.slice(0, 3)}) ${currentValue.slice(3, 6)}-${currentValue.slice(6, 10)}`;
        }
        return formattedString
    };
    if (type == 'tel') {
        // console.log(rest.defaultValue,  normalizeInput(rest.defaultValue))
        rest.defaultValue = normalizeInput(rest.defaultValue)
    }
    const [phoneNum, setPhoneNum] = useState(rest.defaultValue)
    useEffect(() => {
        const passedName = name
        const passedType = type;
        const subscription = watch((value, { name, type }) => {
            // console.log(passedName)
            // console.log(value, name, type);

            if (passedName == name && passedType == 'tel') {
                // console.log(normalizeInput(value[name]))
                setPhoneNum(normalizeInput(value[name]))
            }
        })
        return () => subscription.unsubscribe();
    }, [watch]);
    const formOrder = [];
    console.log('currentQuestionList in multiplechoiceoption', currentQuestionList);
    currentQuestionList.forEach(question => {
        if (question.parent_questionID == q.questionID) {
            // q_family.push(q);
            if (question.parent_conditions) {
                // console.log('question', question)
                const parentConditionValue = question.parent_conditions[q.questionID][0];
                if (parentConditionValue) {
                    // console.log('parentConditionValue', parentConditionValue)

                    if (name == parentConditionValue) {
                        // console.log('right!', question)
                        const newOrder = { id: question.questionID, content: question.question_text }
                        formOrder.push(newOrder)
                    }
                }

            }

        }

    })
    const [childrenFormOrder, setChildrenFormOrder] = useState(formOrder); //necessary for bi-directional communication from ConditionalMultipleChoice.js
    const [childrenFormOrderTemp, setChildrenFormOrderTemp] = useState(formOrder); //necessary for bi-directional communication from DragNDrop.js

    useEffect(() => {
        let formOrder = []
        currentQuestionList.forEach(question => {
            if (question.parent_questionID == q.questionID) {
                // q_family.push(q);
                if (question.parent_conditions) {
                    // console.log('question', question)
                    const parentConditionValue = question.parent_conditions[q.questionID][0];
                    if (parentConditionValue) {
                        // console.log('parentConditionValue', parentConditionValue)

                        if (name == parentConditionValue) {
                            // console.log('right!', question)
                            const newOrder = { id: question.questionID, content: question.question_text }
                            formOrder.push(newOrder)
                        }
                    }

                }

            }

        })
        setChildrenFormOrderTemp(formOrder);
    }, [currentQuestionList])
    useEffect(() => {
        // console.log('childrenFormOrderTemp', childrenFormOrderTemp)
        let cFormIDs = [];
        childrenFormOrder.forEach(cForm => {
            cFormIDs.push(cForm.id);
        })
        let cFormIDsTemp = [];
        childrenFormOrderTemp.forEach(cForm => {
            cFormIDsTemp.push(cForm.id);
        })
        if (JSON.stringify(cFormIDsTemp) != JSON.stringify(cFormIDs)) {
            console.log('cFormIDs', cFormIDs, 'cFormIDsTemp', cFormIDsTemp)
            //Change state only if they are different!
            setChildrenFormOrder(childrenFormOrderTemp)
        }
    }, [childrenFormOrderTemp])
    useEffect(() => {

        let cFormIDs = [];
        childrenFormOrder.forEach(cForm => {
            cFormIDs.push(cForm.id);
        })
        let cFormIDsTemp = [];
        childrenFormOrderTemp.forEach(cForm => {
            cFormIDsTemp.push(cForm.id);
        })
        // console.log('cFormIDs', cFormIDs, 'cFormIDsTemp', cFormIDsTemp, JSON.stringify(cFormIDsTemp) == JSON.stringify(cFormIDs))
        if (JSON.stringify(cFormIDsTemp) != JSON.stringify(cFormIDs)) {
            let newItems = JSON.parse(JSON.stringify(currentQuestionList))
            newItems.forEach(item => {
                // console.log('item', item, 'question', q)
                if (!cFormIDs.includes(item.questionID)) {
                    if (item.parent_questionID == q.questionID) { //used to be my parent
                        // console.log('null parent', q.questionID);
                        if (item.parent_conditions) {
                            // console.log('item.parent_conditions', item.parent_conditions);
                            //if current parent that was deleted has the same id as the option id
                            //[0] because we need the option ID, not the value. [1] is used in FormRendering.js
                            if (item.parent_conditions[q.questionID] && item.parent_conditions[q.questionID][0] == name) {
                                item.parent_questionID = null; //not anymore

                                let parentConditions = JSON.parse(JSON.stringify(item.parent_conditions))
                                delete parentConditions[q.questionID];
                                // console.log('new parentConditions', parentConditions);
                                item.parent_conditions = parentConditions;
                            }

                        }
                    }
                }
                else { //now is my parent
                    // console.log('here', item, q.questionID)
                    // const indexOf = cFormIDs.indexOf(q.questionID)
                    // console.log(question.questionID, 'is now parent of ', q.questionID)
                    item.parent_questionID = q.questionID;
                    item.parent_conditions = {
                        // [q.questionID]: q.options[optionIndex]
                        //[name, q.options[optionIndex]] : id of the input and value of input. ID is for creation. Value is used for rendering user end.
                        [q.questionID]: [name, q.options[optionIndex]]
                    }
                    // if (q.options[optionIndex].includes('New Option')) {
                    //     item.parent_conditions = {
                    //         [q.questionID]: [name, q.options[optionIndex]]
                    //     }
                    // }
                    console.log('here', item, q)

                }

            })
            console.log('newItems', newItems)
            setCurrentQuestionList([...newItems])
        }

    }, [childrenFormOrder, childrenFormOrderTemp])
    return (
        <div className={className}>
            <ReactTooltip backgroundColor={highlightColor} />

            {/* <label htmlFor={name} style={errors[name] ? { color: 'red', fontWeight: 'bold' } : labelStyle} className={`form-label ${labelClass}`}>
                {label}
                {instructions && <AiFillQuestionCircle 
                    style={{ marginLeft: '2px', marginBottom: '3px', color: highlightColor, fontSize: '17px' }} data-tip={instructions} />}
            </label> */}
            <Row>
                <Col md='1'>
                    <AiOutlineHolder style={{ marginLeft: '1em', marginTop: '0.5em' }} />
                </Col>


                <Col md='7'>{type == 'tel' ?
                    <input
                        type={'tel'}
                        // style={{ width: '100%' }}
                        className={`${inputClass} ${errors[name] ? "is-invalid" : ""}`}
                        id={name}
                        placeholder={inputPlaceholder}
                        value={phoneNum}
                        {...register(name)}
                        {...rest}
                    // autoFocus
                    /> : <input
                        type={type || "text"}
                        // style={{ width: '100%' }}

                        className={`${inputClass} ${errors[name] ? "is-invalid" : ""}`}
                        id={name}
                        placeholder={inputPlaceholder}
                        {...register(name)}
                        {...rest}
                    // autoFocus
                    />
                }

                </Col>
                <Col md='3'>
                    {/* <Button variant="link"
                        onClick={() => {

                        }}>
                        Link a follow-up question
                        </Button> */}
                    <Dropdown className=''
                        onSelect={(eventKey, event) => {
                            const clickedForm = currentQuestionList[eventKey];
                            console.log('adding ', eventKey, clickedForm)
                            const newCForm = { id: currentQuestionList[eventKey].questionID, content: currentQuestionList[eventKey].question_text }
                            setChildrenFormOrder([...childrenFormOrder, newCForm])
                        }}>

                        <Dropdown.Toggle variant={'link'}
                            // className={'button-outline-theme mb-2'}
                            id="dropdown-basic" disabled={q.parent_questionID}>
                            Add a dependent question
                        </Dropdown.Toggle>
                        <AiFillExclamationCircle
                            style={!q.parent_questionID ? { display: 'none' } : { color: highlightColor, fontSize: '17px', display: 'inline' }}
                            data-tip={'Multi-level condition is not supported. Remove link from parent to add follow-up questions.'} />

                        <Dropdown.Menu>
                            {currentQuestionList && currentQuestionList.map((item, index) => {
                                if (!item.parent_questionID && item.questionID != q.questionID) {
                                    if (item.question_type != 'conditional') {
                                        console.log('item.parent_conditions', !item.parent_conditions || Object.keys(item.parent_conditions).length <= 0)
                                        if (!item.parent_conditions || Object.keys(item.parent_conditions).length <= 0) { //no conditional multiple choice has picked this option!
                                            // console.log(getValues())
                                            let value = watch(`${item.questionID}`);
                                            if (!value) {
                                                value = watch(`${item.questionID}-question`);
                                                if (!value) {
                                                    value = item.question_text;
                                                }
                                            }
                                            // if (item.question_type == 'checkbox' || item.question_type == 'multiple-choice' ) {
                                            // }
                                            return <Dropdown.Item eventKey={index}>{value}</Dropdown.Item>
                                        }

                                    }

                                }
                                else {
                                    // console.log('not chosen item', item)
                                }
                            })}
                        </Dropdown.Menu>
                    </Dropdown >

                </Col>
                <Col md='1'><AiFillCloseCircle style={{
                    color: 'gray', fontSize: '18px', marginTop: '0.5em'
                }} onClick={() => {
                    console.log('unregister ' + name)
                    unregister(name);
                    onClick();
                }} /></Col>


            </Row>
            {conditionalDict && Object.keys(childrenFormOrder).length > 0 && <Row>
                <Col md='2'>

                </Col>
                <Col md='2'>
                    {/* Color Family: {conditionalDict[name]} */}

                    <div>

                        <div><span><i><b>Color Family: </b></i></span>
                            <div class="square-family" style={{ backgroundColor: conditionalDict[name] }}></div>
                        </div>

                    </div>

                </Col>
            </Row>}

            {Object.keys(childrenFormOrder).length > 0 && <>
                <ConditionalMultipleChoice

                    formOrder={childrenFormOrder}
                    setFormOrder={setChildrenFormOrder} />
            </>}

            <div className="is-invalid"></div>
            <div className="invalid-feedback mx-5">{errors[name]?.message}</div>
        </div>
    );
};
