import React, { useEffect, useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { Input } from "../Components";
import {
  getAuth,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signOut,
} from "firebase/auth";
import { useNavigate, Link } from "react-router-dom";
import { db } from "../Firebase/firebasedb";
import { doc, getDoc } from "firebase/firestore";
const LoginForm = () => {
  const [shouldRedirect, setShouldRedirect] = useState(false);
  const [user, setUser] = useState(null);
  const [redirectDestination, setRedirectDestination] =
    useState("/dashboardstudent");
  const navigate = useNavigate();
  const auth = getAuth();
  useEffect(() => {
    if (shouldRedirect) {
      navigate(redirectDestination);
    }
  }, [shouldRedirect, redirectDestination, navigate]);
  // State listener
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        // const uid = user.uid;
        // ...
        setUser(user);
      } else {
      }
    });
    return () => unsubscribe();
  }, [auth]);

  //Firestore Read
  useEffect(() => {
    if (user) {
      const docRef = doc(db, "Users", user.uid);
      getDoc(docRef).then((docSnap) => {
        if (docSnap.exists()) {
          console.log("Document data:", docSnap.data());
          const data = docSnap.data();
          setRedirectDestination("/");
          setShouldRedirect(true);
        } else {
          // doc.data() will be undefined in this case
          alert(
            "User existed! but no data found in database! No role assigned!\nThis is likely an unofficially registered account. Please visit the official registration page to register an account."
          );
          signOut(auth)
            .then(() => {
              // Sign-out successful.
              console.log("Signed out successfully!");
            })
            .catch((error) => {
              console.log(error);
            });
        }
      });
    }
  }, [user, auth]);

  const methods = useForm();
  const { handleSubmit, reset } = methods;

  const handleLogin = (data) => {
    signInWithEmailAndPassword(auth, data.email, data.password)
      .then((userCredential) => {
        // Signed in
        setUser(userCredential.user);
        // ...
      })
      .catch((error) => {
        // const errorCode = error.code;
        const errorMessage = error.message;
        alert(errorMessage);
      });
  };

  return (
    <div className="container">
      <hr />
      <div className="card m-3 mx-auto col-lg-8">
        <h5 className="card-header text-center">Log In</h5>
        <div className="card-body">
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(handleLogin)}>
              <div className="form-row">
                <Input
                  name="email"
                  label="Email"
                  className="form-group col-12 mb-3"
                  required={true}
                />
              </div>
              <div className="form-row">
                <Input
                  name="password"
                  label="Password"
                  type="password"
                  className="form-group col-12 mb-3"
                  required={true}

                />
              </div>
              <div className="form-row m-3 d-flex justify-content-center">
                <div className="form-group">
                  <button type="submit" className="btn btn-primary mr-1">
                    Login
                  </button>
                  <button
                    type="button"
                    onClick={() => reset()}
                    className="btn btn-secondary"
                  >
                    Reset
                  </button>
                </div>
              </div>
            </form>
          </FormProvider>

          <div className="forgot-password text-right">
            Forget <Link to="/forgotpassword">password?</Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginForm;

// // Import FirebaseAuth and firebase.
// import React, { useState, useEffect } from "react";
// import { useNavigate } from "react-router-dom";

// import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
// import firebase from "firebase/compat/app";
// import "firebase/compat/auth";
// import { getAuth, onAuthStateChanged } from "firebase/auth";

// import { db } from "../Firebase/firebasedb";
// import { doc, getDoc } from "firebase/firestore";
// // Your web app's Firebase configuration
// const firebaseConfig = {
//   apiKey: "AIzaSyBW0dKgcQT_wOiuefSEzhl0opGFhxZVIDo",
//   authDomain: "includes-5bac5.firebaseapp.com",
//   projectId: "includes-5bac5",
//   storageBucket: "includes-5bac5.appspot.com",
//   messagingSenderId: "125756290283",
//   appId: "1:125756290283:web:b4995f30e7dc1fcd67baae",
// };

// firebase.initializeApp(firebaseConfig);
// // Configure FirebaseUI.

// function SignInScreen() {
//   const [user, setUser] = useState(null);
//   const auth = getAuth();

//   const [shouldRedirect, setShouldRedirect] = useState(false);
//   const [redirectDestination, setRedirectDestination] = useState("/dashboard");

//   useEffect(() => {
//     const unsubscribe = onAuthStateChanged(auth, (user) => {
//       if (user) {
//         // User is signed in, see docs for a list of available properties
//         // https://firebase.google.com/docs/reference/js/firebase.User
//         // const uid = user.uid;
//         // ...
//         setUser(user);
//       } else {
//       }
//     });
//     return () => unsubscribe();
//   }, [auth]);
//   const navigate = useNavigate();
//   useEffect(() => {
//     if (shouldRedirect) {
//       navigate(redirectDestination);
//     }
//   }, [shouldRedirect, redirectDestination, navigate]);
//   useEffect(() => {
//     if (user) {
//       const docRef = doc(db, "Users", user.uid);
//       getDoc(docRef).then((docSnap) => {
//         if (docSnap.exists()) {
//           console.log("Document data:", docSnap.data());
//           const data = docSnap.data();
//           // setUserData(data);
//           if (data.atype === "administrator") {
//             setRedirectDestination("/dashboardadmin");
//           } else if (data.atype === "faculty") {
//             setRedirectDestination("/dashboardfaculty");
//           } else if (data.atype === "student") {
//             setRedirectDestination("/dashboard");
//           } else {
//             setRedirectDestination("/dashboard");
//           }
//           setShouldRedirect(true);
//         } else {
//           // doc.data() will be undefined in this case
//           console.log("No such document!");
//           setRedirectDestination("/dashboard");
//           setShouldRedirect(true);
//         }
//       });
//     }
//   }, [user]);
//   const uiConfig = {
//     callbacks: {
//       signInSuccessWithAuthResult: function (authResult, redirectUrl) {
//         // User successfully signed in.
//         // Return type determines whether we continue the redirect automatically
//         // or whether we leave that to developer to handle.
//         console.log(authResult.user.uid);
//         const docRef = doc(db, "Users", authResult.user.uid);
//         getDoc(docRef).then((docSnap) => {
//           if (docSnap.exists()) {
//             console.log("Document data:", docSnap.data());
//             const data = docSnap.data();
//             if (data.atype === "administrator") {
//               setRedirectDestination("/dashboardadmin");
//             } else if (data.atype === "faculty") {
//               setRedirectDestination("/dashboardfaculty");
//             } else if (data.atype === "student") {
//               setRedirectDestination("/dashboard");
//             }
//             setShouldRedirect(true);
//           } else {
//             // doc.data() will be undefined in this case
//             console.log("No such document!");
//           }
//         });
//         return false;
//       },
//     },

//     // Popup signin flow rather than redirect flow.
//     signInFlow: "popup",
//     // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
//     signInSuccessUrl: "/dashboard",
//     // We will display Google and Facebook as auth providers.
//     signInOptions: [
//       // firebase.auth.GoogleAuthProvider.PROVIDER_ID,
//       // firebase.auth.FacebookAuthProvider.PROVIDER_ID,
//       firebase.auth.EmailAuthProvider.PROVIDER_ID,
//     ],
//   };
//   return (
//     <div>
//       <h1>NSF INCLUDES</h1>
//       <p>
//         In case you don't have an account yet, please just type your email in.
//       </p>
//       <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()} />
//     </div>
//   );
// }

// export default SignInScreen;
