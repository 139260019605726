import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

// import { Link } from "react-router-dom";
// import { Alert } from "react-bootstrap";
// import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";
import { Button } from "react-bootstrap";
import { db } from "../Firebase/firebasedb";
import { doc, getDoc } from "firebase/firestore";
import { getAuth, onAuthStateChanged } from "firebase/auth";

export default function Home() {
  const auth = getAuth();
  const [user, setUser] = useState(null);
  // const [userData, setUserData] = useState(null)

  const [shouldRedirect, setShouldRedirect] = useState(false);
  const [redirectDestination, setRedirectDestination] = useState("/");
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        // const uid = user.uid;
        // ...
        setUser(user);
      } else {
        setRedirectDestination("/");
        setShouldRedirect(true);
      }
    });
    return () => unsubscribe();
  }, [auth]);

  useEffect(() => {
    if (user) {
      const docRef = doc(db, "Users", user.uid);
      getDoc(docRef).then((docSnap) => {
        if (docSnap.exists()) {
          console.log("Document data:", docSnap.data());
          const data = docSnap.data();
          // setUserData(data);
          if (data.atype === "administrator") {
            setRedirectDestination("/dashboardadmin");
          } else if (data.atype === "faculty") {
            setRedirectDestination("/dashboardfaculty");
          } else if (data.atype === "student") {
            setRedirectDestination("/dashboardstudent");
          }
          // else {
          //   setRedirectDestination('/dashboard');
          // }
          setShouldRedirect(true);
        } else {
          // doc.data() will be undefined in this case
          console.log("No such document!");
          setRedirectDestination("/");
          setShouldRedirect(true);
        }
      });
    }
  }, [user]);

  const navigate = useNavigate();
  useEffect(() => {
    if (shouldRedirect) {
      navigate(redirectDestination);
    }
  }, [shouldRedirect, redirectDestination, navigate]);
  return (
    <>
      <main>
        <div className="mt-3">
          {/* Welcome to SOAR - Surmounting Obstacles for Academic Resilience! */}
          {/* This is a temporary page only for displaying SignIn and SignUP */}
          {/* buttons, both of which will be added to the NSF Includes official */}
          {/* website in production. */}
        </div>
        {/* <nav class="navbar navbar-expand-sm navbar-light bg-light">
        <a class="navbar-brand" href="/">Home</a>
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>

        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav mr-auto">
          <li className="nav-item active">
              <Link className="nav-link" to="/register">
                Register
              </Link>
            </li>
            <li className="nav-item active">
              <Link className="nav-link" to="/login">
                Log in
              </Link>
            </li>
            <li className="nav-item active">
              <Link className="nav-link" to="/dashboard">
                Dashboard
              </Link>
            </li>
          </ul>
        </div>
      </nav> */}
        {/* <Alert key={'danger'} variant={'danger'}>
          Please sign in first!
        </Alert> */}
        <p></p>
        <Button variant="primary" href="/login">
          Sign In
        </Button>{" "}
        <Button variant="secondary" href="/register">
          Sign Up
        </Button>{" "}
      </main>
    </>
  );
}
