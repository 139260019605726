import React, { useState, useEffect, useRef } from "react";

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { faker } from '@faker-js/faker';
import { random_rgb, random_rgba } from "../Automatic Forms/Utils";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

export const options = {
    responsive: true,
    plugins: {
        legend: {
            position: 'top',
        },
        title: {
            display: true,
            text: 'Chart.js Line Chart',
        },
    },
};

const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];

export const data = {
    labels,
    datasets: [
        {
            label: 'Dataset 1',
            data: labels.map(() => faker.datatype.number({ min: -1000, max: 1000 })),
            borderColor: 'rgb(255, 99, 132)',
            backgroundColor: 'rgba(255, 99, 132, 0.5)',
        },
        {
            label: 'Dataset 2',
            data: labels.map(() => faker.datatype.number({ min: -1000, max: 1000 })),
            borderColor: 'rgb(53, 162, 235)',
            backgroundColor: 'rgba(53, 162, 235, 0.5)',
        },
    ],
};

export function LineChart({ chartData, chartRef }) {
    const [displayChartData, setDisplayChartData] = useState(null);
    const [chartOptions, setChartOptions] = useState({
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: 'Chart.js Line Chart',
            },
        },
    })
    useEffect(() => {
        if (chartData) {
            console.log(chartData)
            let rawData = chartData.data;
            if (rawData != null) {
                const labels = Object.keys(rawData)
                const randomColor = random_rgb();
                const randomAlpha = (Math.random() * (0.4 - 0.8) + 0.4).toFixed(1)
                const randomBackgroundColor = randomColor + ',' + randomAlpha + ')'
                const randomBorderColor = randomColor + ',1)'

                const processedData = {
                    labels,
                    datasets: [
                        {
                            label: chartData.title,
                            data: Object.values(rawData),
                            borderColor: chartData.color ? chartData.color + ',1)' : randomBackgroundColor,
                            backgroundColor: chartData.color ? chartData.color + ',' + randomAlpha + ')' : randomBorderColor
                        },
                        // {
                        //     label: 'Dataset 2',
                        //     data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
                        //     backgroundColor: 'rgba(53, 162, 235, 0.5)',
                        // },
                    ]
                }
                // console.log(processedData)
                setDisplayChartData(processedData)
                setChartOptions({
                    ...chartOptions, plugins: {
                        legend: {
                            position: 'top',
                        },
                        title: {
                            display: true,
                            text: chartData.title,
                        },
                    },
                })
            }
            // let sortedArray = sortArray(rawData);
            // let sortedLabels = sortedArray.map(item => item[0])
            // let sortedValues = sortedArray.map(item => item[1])

            // console.log(sortedLabels, sortedValues)


        }

    }, [chartData])
    return <div>
        {displayChartData && <Line options={chartOptions} data={displayChartData} ref={chartRef} id='mainChart' />}
    </div>
}
