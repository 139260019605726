import React, { useState, useEffect, useRef } from "react";
import { format, parse, isValid } from "date-fns";
import ChatBot from "../SimpleChatbot/ChatBot";
import { useFormContext } from "react-hook-form";
import { ThemeProvider } from "styled-components";
import { Update } from "../Chatbot/Update";

const theme = {
    background: "#f5f8fb",
    fontFamily: "monospace",
    headerBgColor: "#6e48aa",
    headerFontColor: "#fff",
    headerFontSize: "16px",
    botBubbleColor: "#6E48AA",
    botFontColor: "#fff",
    userBubbleColor: "#fff",
    userFontColor: "#4a4a4a",
  };
  
  // const theme = {
  //   background: "#f5f8fb",
  //   fontFamily: "monospace",
  //   headerBgColor: "#7ca9f4",
  //   headerFontColor: "#fff",
  //   headerFontSize: "15px",
  //   botBubbleColor: "#7ca9f4",
  //   botFontColor: "#fff",
  //   userBubbleColor: "#fff",
  //   userFontColor: "#4a4a4a"
  // };
  
  const config = {
    width: "500px",
    height: "600px",
    floating: true,
  };
  const triggerSelect = (stepN, stepY, usevalue = false) => {
    const out = ({ value }) =>
      value &&
      ["n", "nn", "nnn", "no", "nope", "", "false"].includes(value.toLowerCase())
        ? stepN
        : usevalue
        ? value.toLowerCase() + "-update"
        : stepY;
    // console.log(x);
    // console.log(value);
    return out;
  };

//   const checkSpeechSynthesis = (
//     setSpeechSynthesis,
//     stepN,
//     stepY,
//     usevalue = false
//   ) => {
//     // const out = ({ value }) =>
//     //   value &&
//     //   ["n", "nn", "nnn", "no", "nope", "", "false"].includes(value.toLowerCase())
//     //     ? stepN
//     //     : usevalue
//     //       ? value.toLowerCase() + "-update"
//     //       : stepY;
//     const out = ({ value }) => {
//       console.log(value);
//       if (value) {
//         if (
//           ["n", "nn", "nnn", "no", "nope", "", "false"].includes(
//             value.toLowerCase()
//           )
//         ) {
//           setSpeechSynthesis({ enable: false, lang: "en" });

//           return stepN;
//         } else {
//           console.log("SPEECH PLEASE!");
//           if (usevalue) {
//             return value.toLowerCase() + "-update";
//           }
//           setSpeechSynthesis({ enable: true, lang: "en" });
//           // setFloating(false)

//           return stepY;
//         }
//       }
//       setSpeechSynthesis({ enable: false, lang: "en" });

//       return stepN;
//     };
//     // console.log(x);
//     // console.log(value);
//     return out;
//   };
  export const AutomaticBot = ({
                                setFormCurrentDisplayQuestion,
                                setFormEnd,
                                formQuestions,
                                setWaitAction = null,
                                botTriggerNext = false,
                                latestRetrievedForm = undefined }) => {
    const {
      // formState: { errors },
      setValue,
    } = useFormContext();
    const [speechSynthesis, setSpeechSynthesis] = useState({
        enable: false,
        lang: "en",
      });

      const speechSwitch = () => {
        setSpeechSynthesis({ enable: !speechSynthesis.enable, lang: "en" });
        console.log(speechSynthesis);
      };
    const [formQuestionDict, setFormQuestionDict] = useState({});
    const [renderFormQuestions, setRenderFormQuestions] = useState([])
    const [allBotSteps, setAllBotSteps] = useState([]);
    const otherCodewords = [
        "Federal grant. Please specify:",
        "Other",
        "Other services provided to students",
        "Part of Other Campus Division (Please Specify)",
      ];

    // const [floating, setFloating] = useState(true);
    const handleEnd = ({ steps, values }) => {
      // console.log(steps);
      // console.log(values);
      console.log("BOT FORM END!");
      setFormEnd(true);
      // navigate("/dashboard");
      // ref.dispatchEvent(new Event("submit", { cancelable: true, bubbles: true }));
    };
    useEffect(() => {
      console.log("SPEECH UPDATE!");
      console.log(speechSynthesis);
    }, [speechSynthesis]);
    useEffect(() => {
        if (formQuestions && renderFormQuestions.length <= 0) {
            formQuestions.forEach(q => {
                formQuestionDict[q.questionID] = q;
                if (q["question_type"] === "short_answer" ) {
                    if (q.parent_questionID && q.parent_questionID != '') { //to test checkbox follow up questions
                        renderFormQuestions.push(q)

                    }
                    else {
                        renderFormQuestions.push(q)
                    }

                }
                else if (q["question_type"] === "long_answer") {
                    renderFormQuestions.push(q)
                }
                else if (q["question_type"] === "email") {
                    renderFormQuestions.push(q)
                }
                else if (q["question_type"] === "phone_number") {
                    renderFormQuestions.push(q)
                }
                else if (q["question_type"] === "multiple_choice") {
                    if (q.parent_questionID && q.parentQuestion != '') {
                        renderFormQuestions.push(q)

                    }
                    else {
                        renderFormQuestions.push(q)
                    }
                }
                else if (q["question_type"] === "checkbox") {
                    renderFormQuestions.push(q)
                }
                else if (q["question_type"] === "date") {
                    renderFormQuestions.push(q)
                }
                else if (q.question_type === 'checkbox_followup') {
                    renderFormQuestions.push(q)
                }
                else if (q.question_type === 'rating_bubble') {
                    renderFormQuestions.push(q)
                }
                else if (q.question_type === 'file_upload') {
                    renderFormQuestions.push(q)
                }
                setRenderFormQuestions(renderFormQuestions)


            })
            setFormQuestionDict(formQuestionDict);
            var allSteps = [
            //     {
            //     id: "speech_synthesis_question",
            //     message: "Would you like to turn on the speech synthesis?",
            //     trigger: "user_answer",
            //   },
            //   {
            //     id: "user_answer",
            //     user: true,
            //     trigger: checkSpeechSynthesis(
            //       setSpeechSynthesis,
            //       "privacy",
            //       "privacy"
            //     ),
            //   },
            {
                id: "init",
                message: "Please press Enter/Return key to continue.",
                trigger: "init_user",
              },
              {
                id: "init_user",
                user: true,
                trigger: "privacy",
              },
              {
                id: "privacy",
                message:
                  "This form collects information about participants of the TAPDINTO-STEM program. Access to the data will be restricted to authorized personnel of the program. The data collected will only be reported in aggregation to offer maximal protection of your privacy. Please continue.",
                trigger: "0",
              },

            ];
            let latestIndex = 0;
            console.log('re-render useEffect')
            renderFormQuestions.forEach((q, index) => {
                // console.log(q.parent_questionID);
                if (q.parent_questionID === '' || q.parent_questionID === undefined) {
                    if (q["question_type"] === "short_answer" ) {
                        let [newSteps, latestIdx] = ShortAnswer(q, latestIndex);
                        allSteps = [...allSteps, ...newSteps];
                        latestIndex = latestIdx;
                    }
                    else if (q["question_type"] === "long_answer") {
                        let [newSteps, latestIdx] = LongAnswer(q, latestIndex);
                        allSteps = [...allSteps, ...newSteps];
                        latestIndex = latestIdx;
                    }
                    else if (q["question_type"] === "email") {
                        let [newSteps, latestIdx] = EmailInput(q, latestIndex);
                        allSteps = [...allSteps, ...newSteps];
                        latestIndex = latestIdx;
                    }
                    else if (q["question_type"] === "phone_number") {
                        let [newSteps, latestIdx] = PhoneNumberInput(q, latestIndex);
                        allSteps = [...allSteps, ...newSteps];
                        latestIndex = latestIdx;
                    }
                    else if (q["question_type"] === "multiple_choice") {
                        // setValue('gender', "Male")
                        let [newSteps, latestIdx] = MultipleChoice(q, latestIndex);
                        allSteps = [...allSteps, ...newSteps];
                        latestIndex = latestIdx;
                    }
                    else if (q["question_type"] === "checkbox") {
                        let [newSteps, latestIdx] = CheckBox(q, latestIndex);
                        allSteps = [...allSteps, ...newSteps];
                        latestIndex = latestIdx;
                    }
                    else if (q["question_type"] === "date") {
                        // console.log(parseMultiple('12 January 2020', DateFormats))
                        // setValue('Date of Birth', '2000-12-01')
                        let [newSteps, latestIdx] = DateInput(q, latestIndex);
                        allSteps = [...allSteps, ...newSteps];
                        latestIndex = latestIdx;
                    }
                    else if (q.question_type === 'checkbox_followup') {
                        let q_family = [q]
                        formQuestions.forEach(question => {
                            // console.log(question)
                            if (question.parent_questionID === q.questionID) {
                                q_family.push(question);
                            }
                        })
                        // console.log('in here', latestIndex)
                        let [newSteps, latestIdx] = CheckBoxFollowUp(q_family, latestIndex);
                        // console.log(newSteps);
                        allSteps = [...allSteps, ...newSteps];
                        latestIndex = latestIdx;
                    }
                    else if (q.question_type === 'rating_bubble') {
                        let [newSteps, latestIdx] = RatingBubble(q, latestIndex);
                        allSteps = [...allSteps, ...newSteps];
                        latestIndex = latestIdx;
                    }
                    else if (q.question_type === 'file_upload') {
                        let [newSteps, latestIdx] = FileUpload(q, latestIndex);
                        allSteps = [...allSteps, ...newSteps];
                        latestIndex = latestIdx;
                    }
                }
            })
            allSteps.slice(-1)[0].trigger = 'confirm-question'
            const confirmQuestion = {
                questionID: 'confirm-question',
                question_text: "Please review the form and press Submit Now whenever you are ready.",
                question_type: 'plain_text'
            }
            const finalSteps = [
                {
                  id: confirmQuestion.questionID,
                  message:
                  confirmQuestion.question_text,
                  trigger: confirmQuestion.questionID + '-update',
                },
                {
                    id: confirmQuestion.questionID + '-update', //update currentFormDisplayQuestion on frontend
                    component: <BotMessageComponent q = {confirmQuestion} messageToDisplay = {confirmQuestion.question_text}/>,
                    replace:true,
                    // message: q.question_text,
                    trigger: "confirm-answer",
                },
                {
                  id: "confirm-answer",
                  options: [{ value: "y", label: "Submit Now", trigger: "final" }],
                },
                {
                  id: "final",
                  message: "Thanks! Your data was submitted successfully!",
                  end: true,
                },]
            allSteps = [...allSteps, ...finalSteps]
            console.log(allSteps);
            setAllBotSteps(allSteps);
        }



    }, [formQuestions, formQuestionDict])
    useEffect(() => {
        if (botTriggerNext === true) {
            console.log('botTriggerNext is true inside bot baby!');
            thisform.current &&
            thisform.current.dispatchEvent(
              new Event("submit", { cancelable: true, bubbles: true })
            );
        }
    }, [botTriggerNext])

    const BotMessageComponent = ({q, messageToDisplay}) => {
        const [currentQuestion] = useState(q);
        useEffect(() => {
            // console.log(currentQuestion);
            setFormCurrentDisplayQuestion(currentQuestion);
        }, [currentQuestion])

        return <div></div>
    }
    const ShortAnswer = (q, i) => {
        let botMessage = q.question_text;
        if (latestRetrievedForm) {
            if (latestRetrievedForm[`${q.questionID}`] != '') {
                const answer = latestRetrievedForm[`${q.questionID}`]
                botMessage += `. Your previous answer was \"${answer}\". Press Enter/Return to re-use the old answer.`
            }
        }
        // console.log(botMessage);
        let steps = [
            //Bot
            {
                id: i.toString(),
                message: botMessage,
                // message: q.question_text,
                trigger: i.toString()+ '-update',
            },
            {
                id: i.toString()+ '-update',
                component: <BotMessageComponent q = {q} messageToDisplay = {q.question_text}/>,
                replace:true,
                // message: q.question_text,
                trigger: q.questionID,
            },
            //User
            {
                id: q.questionID,
                user: true,
                // trigger: q.questionID + '-update',
                trigger: (i+1).toString(),
                validator: (value) => {
                    console.log(value)
                    if (value || value.replace(/\s/g, '') != '' ) {
                        setValue(q.questionID, value)
                        return true;
                    }
                    else {
                        if (!botMessage.includes('re-use')) {
                            const error = {
                                type:'manual',
                                message:'Invalid Input!'
                            }
                            return error.message;
                        }
                        return true;
                    }

                    // try {
                    //   validationSchema.fields[keys[i]] === undefined ||
                    //     validationSchema.fields[keys[i]].validateSync(value);
                    //   return true;
                    // } catch (err) {
                    //   return err.errors;
                    // }
                  },
            },

            // //Link the form
            // {
            //     id: q.questionID + '-update',
            //     component: <Update handleUpdate={handleUpdate} />,
            //     replace: true,
            //     trigger: (i+1).toString(),
            // }
        ]
        return [steps, i+1];

    }
    const EmailInput = (q, i) => {
        let botMessage = q.question_text;
        if (latestRetrievedForm) {
            if (latestRetrievedForm[`${q.questionID}`] != '') {
                const answer = latestRetrievedForm[`${q.questionID}`]
                botMessage += `. Your previous answer was \"${answer}\". Press Enter/Return to re-use the old answer.`
            }
        }
        // console.log(botMessage);
        let steps = [
            //Bot
            {
                id: i.toString(),
                message: botMessage,
                // message: q.question_text,
                trigger: i.toString()+ '-update',
            },
            {
                id: i.toString()+ '-update',
                component: <BotMessageComponent q = {q} messageToDisplay = {q.question_text}/>,
                replace:true,
                // message: q.question_text,
                trigger: q.questionID,
            },
            //User
            {
                id: q.questionID,
                user: true,
                // trigger: q.questionID + '-update',
                trigger: (i+1).toString(),
                validator: (value) => {
                    console.log(value)
                    if (value || value.replace(/\s/g, '') != '' ) {
                        if (!value.includes('@'))
                            return 'Invalid Email!'
                        setValue(q.questionID, value)
                        return true;
                    }
                    else {
                        if (!botMessage.includes('re-use')) {
                            const error = {
                                type:'manual',
                                message:'Invalid Input!'
                            }
                            return error.message;
                        }
                        return true;
                    }

                    // try {
                    //   validationSchema.fields[keys[i]] === undefined ||
                    //     validationSchema.fields[keys[i]].validateSync(value);
                    //   return true;
                    // } catch (err) {
                    //   return err.errors;
                    // }
                  },
            },

            // //Link the form
            // {
            //     id: q.questionID + '-update',
            //     component: <Update handleUpdate={handleUpdate} />,
            //     replace: true,
            //     trigger: (i+1).toString(),
            // }
        ]
        return [steps, i+1];

    }
    const PhoneNumberInput = (q, i) => {
        let botMessage = q.question_text;
        if (latestRetrievedForm) {
            if (latestRetrievedForm[`${q.questionID}`] != '') {
                const answer = latestRetrievedForm[`${q.questionID}`]
                botMessage += `. Your previous answer was \"${answer}\". Press Enter/Return to re-use the old answer.`
            }
        }
        // console.log(botMessage);
        let steps = [
            //Bot
            {
                id: i.toString(),
                message: botMessage,
                // message: q.question_text,
                trigger: i.toString()+ '-update',
            },
            {
                id: i.toString()+ '-update',
                component: <BotMessageComponent q = {q} messageToDisplay = {q.question_text}/>,
                replace:true,
                // message: q.question_text,
                trigger: q.questionID,
            },
            //User
            {
                id: q.questionID,
                user: true,
                // trigger: q.questionID + '-update',
                trigger: (i+1).toString(),
                validator: (value) => {
                    //isNaN:   // returns true if the variable does NOT contain a valid number
                    console.log(isNaN(value))
                    const processedValue = removeSpecialCharacters(value)

                    if (processedValue!= '' && !isNaN(processedValue)) { //a number
                        if(processedValue.toString().length == 10) {
                            setValue(q.questionID, processedValue)
                            return true;
                        }
                        else {
                            return "Number must contain 10 digits!"
                        }


                    }
                    else { //astring
                        if (!botMessage.includes('re-use')) {
                            const error = {
                                type:'manual',
                                message:'Invalid Input!'
                            }
                            return error.message;
                        }
                        return true;
                    }

                    // try {
                    //   validationSchema.fields[keys[i]] === undefined ||
                    //     validationSchema.fields[keys[i]].validateSync(value);
                    //   return true;
                    // } catch (err) {
                    //   return err.errors;
                    // }
                  },
            },

            // //Link the form
            // {
            //     id: q.questionID + '-update',
            //     component: <Update handleUpdate={handleUpdate} />,
            //     replace: true,
            //     trigger: (i+1).toString(),
            // }
        ]
        return [steps, i+1];

    }
    const LongAnswer = (q, i) => {
        let botMessage = q.question_text;
        if (latestRetrievedForm) {
            if (latestRetrievedForm[`${q.questionID}`] != '') {
                const answer = latestRetrievedForm[`${q.questionID}`]
                botMessage += `. Your previous answer was \"${answer}\". Press Enter/Return to re-use the old answer.`
            }
        }
        let steps = [
            //Bot
            {
                id: i.toString(),
                message: botMessage,
                // message: q.question_text,
                trigger: i.toString()+ '-update',
            },
            //Update Current Question Progress
            {
                id: i.toString()+ '-update',
                component: <BotMessageComponent q = {q} messageToDisplay = {q.question_text}/>,
                replace:true,
                // message: q.question_text,
                trigger: q.questionID,
            },
            //User
            {
                id: q.questionID,
                user: true,
                trigger: q.questionID + '-update',
                validator: (value) => {
                    console.log(value)
                    if (value || value.replace(/\s/g, '') != '' ) {
                        setValue(q.questionID, value)
                        return true;
                    }
                    else {
                        if (!botMessage.includes('re-use')) {
                            const error = {
                                type:'manual',
                                message:'Invalid Input!'
                            }
                            return error.message;
                        }
                        return true;
                    }
                  },
            },
            //Link the form
            {
                id: q.questionID + '-update',
                component: <Update handleUpdate={handleUpdate} />,
                replace: true,
                trigger: (i+1).toString(),
            }
        ]
        return [steps, i+1];
    }
    const MultipleChoice = (q, i) => {
        const options = q.options;
        let option_txt = '\n';
        options.forEach((option, i) => {
            option_txt += `${i+1}. ${option}\n`
        })


        const originalbotMessage = q.question_text + ': ' + option_txt;
        let reuseBotMessage = '\n';

        if (latestRetrievedForm) {
            if (latestRetrievedForm[`${q.questionID}`] != '') {
                const answer = latestRetrievedForm[`${q.questionID}`]
                // console.log(latestRetrievedForm[`${q.questionID}`])
                reuseBotMessage += `${q.question_text}: Your previous answer for ${q.question_text} was \"${answer}\". Would you like to re-use this answer?`
            }
        }
        else {
            reuseBotMessage = originalbotMessage
        }

        let steps = [
            //Bot
            {
                id: i.toString(),
                message: reuseBotMessage,
                // message: q.question_text,
                trigger: reuseBotMessage.includes('re-use')
                ? i.toString() + '-reuse-user'
                : i.toString() + '-update'
            },
            // {
            //     id: i.toString(),
            //     message: '\n',
            //     // message: q.question_text,
            //     trigger: i.toString() + '-original'
            // },
            {
                id: i.toString() + '-original',
                message: originalbotMessage,
                // message: q.question_text,
                trigger: i.toString() + '-update'
            },
            //User
            {
                id: i.toString() + '-reuse-user',
                user: true,
                trigger: triggerSelect(i.toString() + '-original', (i+1).toString())
            },
            {
                id: i.toString() + '-update',
                component: <BotMessageComponent q = {q} messageToDisplay = {originalbotMessage}/>,
                replace:true,
                // message: q.question_text,
                trigger: q.questionID,
            },
            //User
            {
                id: q.questionID,
                user: true,
                // trigger: q.questionID + '-update',
                trigger: (i+1).toString(),


                validator: (value) => {
                    const id = q.questionID
                    const currentQuestion = formQuestionDict[id];
                    console.log(value)
                    console.log(currentQuestion)

                    if (isNaN(value)) { //a text option
                        let matched = false;

                        const options = currentQuestion.options;
                        options.forEach(option => {
                            if (option.toLowerCase() === value.toLowerCase()) {
                                setValue(id, option);
                                matched = true;
                                // return true;
                            }
                        } )
                        if (!matched)
                            return 'Invalid option!';
                        else
                            return true;

                    }
                    else { //is a number option
                        if (value > currentQuestion.options.length) {
                            return `Invalid number!\nMax: ${currentQuestion.options.length} options!`;
                        }
                        setValue(id, currentQuestion.options[value-1]);
                        return true;
                    }
                }
            },
            // //Link the form
            // {
            //     id: q.questionID + '-update',
            //     component: <Update handleUpdate={handleUpdate} />,
            //     replace: true,
            //     trigger: (i+1).toString(),
            // }
        ]
        return [steps, i+1];
    }
    const removeSpecialCharacters = (str) => {
        return str.replace(/[^.,a-zA-Z0-9]/g, '')
    }
    const CheckBox = (q, i) => {
        // setValue('race.American Indian', true);
        let options = q.options;
        const isLastQuestion = q.questionID === renderFormQuestions.slice(-1)[0].questionID;
        const stepY = isLastQuestion ? 'confirm-question' : (i+options.length + 1).toString()
        // console.log(stepN)
        const botMessage = `${q.question_text}: For each of these options, please indicate whether each is applicable to you by saying Yes or No. Are you/Do you have ...`
        let reuseBotMessage = '\n';

        if (latestRetrievedForm) {
            if (latestRetrievedForm[`${q.questionID}`] != '') {
                const answer = latestRetrievedForm[`${q.questionID}`]
                reuseBotMessage += `${q.question_text}: Your previous answer for ${q.question_text} was `
                // console.log(latestRetrievedForm[`${q.questionID}`])
                let reuseBotSuffixOther = ''
                for (const [key, value] of Object.entries(answer)) {

                    if (value != false) {
                        if (key.toLowerCase() == 'other') {
                            const originalOption = key.split('other')[1]
                            reuseBotSuffixOther += ` ${originalOption}: ${value},`
                        }
                        else if (!key.includes('check')) {
                            reuseBotMessage += `${value},`
                        }
                    }
                  }
                reuseBotMessage += reuseBotSuffixOther + ". Would you like to re-use the answer(s)?"
            }
        }
        else {
            reuseBotMessage = botMessage;
        }
        // console.log(reuseBotMessage)
        let steps = [
            //Bot
            //Bot
            {
                id: i.toString(),
                message: reuseBotMessage,
                // message: q.question_text,
                trigger: reuseBotMessage.includes('re-use')
                ? i.toString() + '-reuse-user'
                : i.toString() + '-update'
            },
            //User
            {
                id: i.toString() + '-reuse-user',
                user: true,
                trigger: triggerSelect(i.toString() + '-original',
                stepY)
            },
            {
                id: i.toString() + '-original',
                message: botMessage,
                trigger: i.toString() + '-update',
            },

            {
                id: i.toString() + '-update',
                component: <BotMessageComponent q = {q} messageToDisplay = {botMessage}/>,
                replace:true,
                // message: q.question_text,
                trigger: (i+1).toString(),
            },
        ]
        i+=1;
        options.forEach((option, index) => {
            const botMessage = option + '?';
            let newSteps = [];
            if (otherCodewords.includes(option)){
                const optionID = option.match(/\w+/g).join("_")
                newSteps = [
                    //bot
                    {
                        id: i.toString(),
                        message: botMessage,
                        trigger: q.questionID + '.check' + optionID,
                    },
                    // User
                    {
                        id: q.questionID + '.check' + optionID,
                        user: true,
                        // trigger: triggerSelect(q.questionID + '.check' + optionID + '-updateN',
                        // q.questionID + '.check' + optionID + '-updateY'),
                        trigger: triggerSelect(q.questionID + '.check' + optionID + '-updateN',
                        q.questionID + '.check' + optionID + '-updateY'),
                        validator: (value) => {
                                const id = q.questionID + '.check' + optionID
                                const yesCodeWords = ['y', 'yes']
                                const noCodeWords = ['n', 'no']
                                let processedID = id.replace(/,/g,'-') //key cannot have comma

                                if (yesCodeWords.includes(value.toLowerCase()))
                                {
                                    setValue(processedID, option)
                                    return true;
                                }
                                else if (noCodeWords.includes(value.toLowerCase()))
                                {
                                    setValue(processedID, false)
                                    return true;
                                }
                                else {
                                    return 'Invalid Input!'
                                }
                        }
                    },
                    // Link the form (Other = No)
                    {
                        id: q.questionID + '.check' + optionID + '-updateN',
                        component: <Update handleUpdate={handleUpdate} />,
                        replace: true,
                        // trigger: q.questionID + '.otherUpdateFinal',
                        trigger: isLastQuestion? 'confirm-question' : (i+1).toString(),

                    },
                    //If Other = True
                    {
                        id: q.questionID + '.check' + optionID + '-updateY',
                        message: 'Please indicate your Other:',
                        trigger: q.questionID + '.other' + optionID,
                    },
                    {
                        id: q.questionID + '.other' + optionID,
                        user: true,
                        // trigger: q.questionID + '.otherUpdateFinal',
                        trigger: (i+1).toString(),

                        validator: (value) => {
                            const id = q.questionID + '.other' + optionID
                            //For "Other" checkbox
                            if (value && value.replace(/[^.,a-zA-Z0-9]/g, '') != '') {
                                setValue(id, value);
                                return true;
                            }
                            return 'Invalid Input';
                        }
                    },
                    //  //Link the form (Finally)
                    //  {
                    //     id: q.questionID + '.otherUpdateFinal',
                    //     component: <Update handleUpdate={handleUpdate} />,
                    //     replace: true,
                    //     trigger: (i+1).toString(),
                    // },
                ]
                i+=1;

            }
            else {
                newSteps = [
                    //bot
                    {
                        id: i.toString(),
                        message: botMessage,
                        trigger: q.questionID + '.' + option,
                    },
                    //User
                    {
                        id: q.questionID + '.' + option,
                        user: true,
                        // trigger: q.questionID + '.' + option + '-update',
                        trigger: (i+1).toString(),

                        validator: (value) => {
                            const id = q.questionID + '.' + option
                            const yesCodeWords = ['y', 'yes']
                            const noCodeWords = ['n', 'no']
                            let processedID = id.replace(/,/g,'-') //key cannot have comma
                            if (yesCodeWords.includes(value.toLowerCase()))
                            {
                                setValue(processedID, option)
                                return true;
                            }
                            else if (noCodeWords.includes(value.toLowerCase()))
                            {
                                setValue(processedID, false)
                                return true;
                            }
                            else {
                                return 'Invalid Input!'
                            }

                    }
                    },
                    // //Link the form
                    // {
                    //     id: q.questionID + '.' + option + '-update',
                    //     component: <Update handleUpdate={handleUpdate} />,
                    //     replace: true,
                    //     trigger: (i+1).toString(),
                    // }
                ]
                i+=1;
            }

            steps = [...steps, ...newSteps]

        })
        // console.log(steps)
        return [steps, i]
    }
    const CountIDs = (q_family) => {
        let countItems = 0;
        q_family.forEach((followup_q => {
            if (followup_q["question_type"] === "short_answer" ) {
              countItems += 1;
            }
            else if (followup_q["question_type"] === "email" ) {
                countItems += 1;
            }
            else if (followup_q["question_type"] === "phone_number" ) {
                countItems += 1;
              }
            else if (followup_q["question_type"] === "long_answer") {
                countItems += 1;

            }
            else if (followup_q["question_type"] === "multiple_choice") {
                countItems+=1;
            }
            else if (followup_q["question_type"] === "checkbox") {
                const options = followup_q.options;

                countItems+= options.length +1;
            }
            else if (followup_q["question_type"] === "date") {
                countItems+=1;
            }
            else if (followup_q.question_type === 'checkbox_followup') {
                // countItems+=1;
                // const sub_q_family = q_family.slice(1)
                // countItems += CountIDs(sub_q_family)
            }
            else if (followup_q.question_type === 'rating_bubble') {
                const options = followup_q.options;
                countItems += 1 + options.length;
            }
            else if (followup_q.question_type === 'file_upload') {
                countItems+=1;
            }
        }))
        return countItems;
    }
    const ExtractPreviousAnswer = (q, latestRetrievedForm) => {
        const questionID = q.questionID
        if (q["question_type"] === "short_answer" ) {
            return latestRetrievedForm[questionID]
          }
          else if (q["question_type"] === "long_answer") {
            return latestRetrievedForm[questionID]
          }
          else if (q["question_type"] === "multiple_choice") {
            return latestRetrievedForm[questionID]
          }
          else if (q["question_type"] === "checkbox") {
            const answer = latestRetrievedForm[q.questionID]
            let reuseBotMessage = ''
            // console.log(latestRetrievedForm[`${q.questionID}`])
            let reuseBotSuffixOther = ''
            for (const [key, value] of Object.entries(answer)) {

                if (value != false) {
                    if (key.includes('other')) {
                        const originalOption = key.split('other')[1]
                        reuseBotSuffixOther += ` ${originalOption}: ${value} | `
                    }
                    else if (!key.includes('check')) {
                        reuseBotMessage += `${value} | `
                    }
                }
                }
            return reuseBotMessage + reuseBotSuffixOther
          }
          else if (q["question_type"] === "date") {
            return latestRetrievedForm[questionID]

          }
          else if (q.question_type === 'checkbox_followup') {
             //To-be implemented
             return "Nested Checkbox Followup"
          }
          else if (q.question_type === 'rating_bubble') {
                let previousRatings = {}
                previousRatings = latestRetrievedForm[q.questionID]
            // To-be-implemented
            return "[Rating Bubble]"
          }
          else if (q.question_type === 'file_upload') {
            let previousFileUploadInfo = {}
            let previousFileName = ''
            if (latestRetrievedForm[`${q.questionID}`]) {
                previousFileUploadInfo = latestRetrievedForm[`${q.questionID}`]
                previousFileName = previousFileUploadInfo.storageURL.split('/').pop();

            }
            const returnedString = previousFileName != '' ? `Filename: ${previousFileName}`: ''
            return returnedString
          }
    }
    const ReusedMessageComponent = ({questions, answers}) => {
        // console.log(questions,answers)
        return <div>
            <h4>Previous Answers</h4>
            {questions.map((q, i) => {
                return <div>

                    <p>{q}: <b><i>{answers[i]}</i></b></p>
                </div>
            })}
        </div>
    }
    const CheckBoxFollowUp = (q_family, i) => {
        const parentQuestion = q_family[0];
        const isParentLastQuestion = q_family[0].questionID === renderFormQuestions.slice(-1)[0].questionID
        // console.log(q_family.slice(-1)[0].questionID, renderFormQuestions.slice(-1)[0].questionID)
        const countItems = CountIDs(q_family.slice(1))
        console.log('isParentLastQuestion?' + isParentLastQuestion.toString())
        const stepNParent = isParentLastQuestion ? 'confirm-question' : (parseInt(i + 1 + countItems)).toString()
        console.log(i, countItems, stepNParent)
        const stepYReuse = isParentLastQuestion ? 'confirm-question' : (parseInt(i + 1 + countItems)).toString()
        const botMessage = `${parentQuestion.question_text} - Is this applicable to you? If yes, there will be some follow-up questions for you.`
        let reuseBotMessage = '\n';
        // let reuseBotMessages = []
        let reuseBotAnswers = []
        let reuseBotQuestions = []
        if (latestRetrievedForm) {
            if (latestRetrievedForm[`${parentQuestion.questionID}`] != false) {
                reuseBotMessage += `${parentQuestion.question_text}: Your previous answer for ${parentQuestion.question_text} was yes! `
                q_family.slice(1).forEach(followup_q => {
                    const answer = ExtractPreviousAnswer(followup_q, latestRetrievedForm)
                    // const answer = latestRetrievedForm[`${followup_q.questionID}`]
                    // reuseBotMessage += `${followup_q.question_text}: ${answer}. `
                    reuseBotAnswers.push(answer)
                    reuseBotQuestions.push(followup_q.question_text)
                    // reuseBotMessages.push(`${followup_q.question_text}: ${answer}. `)
                })
            }
            // console.log(reuseBotAnswers)
            reuseBotMessage += "Would you like to re-use the answer(s)?"

        }
        else {
            reuseBotMessage = botMessage;
        }
        // console.log(stepYReuse)
        let steps = [
            //Bot
            {
                id: i.toString(),
                message: reuseBotMessage,
                // message: q.question_text,
                trigger: i.toString() + '-suffix'
            },
            //Bot
            {
                id: i.toString() + '-suffix',
                component: <ReusedMessageComponent
                            answers = {reuseBotAnswers}
                            questions = {reuseBotQuestions} >
                        </ReusedMessageComponent>,
                replace: reuseBotMessage.includes('re-use')? false: true,
                trigger: reuseBotMessage.includes('re-use')
                ? i.toString() + '-reuse-user'
                : i.toString() + '-update',
            },
            //User
            {
                id: i.toString() + '-reuse-user',
                user: true,
                trigger: triggerSelect(i.toString() + '-original',
                stepYReuse)
            },
            {
                id: i.toString() + '-original',
                message: botMessage ,
                trigger: i.toString() + '-update',

            },
            {
                id: i.toString() + '-update',
                component: <BotMessageComponent q = {parentQuestion} messageToDisplay = {botMessage}/>,
                replace:true,
                // message: q.question_text,
                trigger: parentQuestion.questionID,
            },
            //User
            {
                id: parentQuestion.questionID,
                user: true,
                // trigger: triggerSelect(stepNParent, parentQuestion.questionID + '-update') ,
                trigger: triggerSelect(stepNParent,(i+1).toString()) ,

                validator: (value) => {
                    const yesCodeWords = ['y', 'yes']
                    const noCodeWords = ['n', 'no']
                    let processedID = parentQuestion.questionID.replace(/,/g,'-') //key cannot have comma

                    if (yesCodeWords.includes(value.toLowerCase()))
                    {
                        setValue(processedID, true)
                        return true;
                    }
                    else if (noCodeWords.includes(value.toLowerCase()))
                    {
                        setValue(processedID, false)
                        return true;
                    }
                    return 'Invalid Input!'
                }
            },
            // // Link the form (Other = Yes)
            // {
            //     id: parentQuestion.questionID + '-update',
            //     component: <Update handleUpdate={handleUpdate} />,
            //     replace: true,
            //     trigger: (i+1).toString()
            // },
        ]
        i+=1;
        q_family.slice(1).forEach(followup_q => {
            if (followup_q["question_type"] === "short_answer") {
                let [newSteps, latestIdx] = ShortAnswer(followup_q, i);
                steps = [...steps, ...newSteps];
                i = latestIdx;
            }
            else if (followup_q["question_type"] === "long_answer") {
                let [newSteps, latestIdx] = LongAnswer(followup_q, i);
                steps = [...steps, ...newSteps];
                i = latestIdx;
            }
            else if (followup_q["question_type"] === "email") {
                let [newSteps, latestIdx] = EmailInput(followup_q, i);
                steps = [...steps, ...newSteps];
                i = latestIdx;
            }
            else if (followup_q["question_type"] === "phone_number") {
                let [newSteps, latestIdx] = LongAnswer(followup_q, i);
                steps = [...steps, ...newSteps];
                i = latestIdx;
            }
            else if (followup_q["question_type"] === "multiple_choice") {
                // setValue('gender', "Male")
                let [newSteps, latestIdx] = MultipleChoice(followup_q, i);
                steps = [...steps, ...newSteps];
                i = latestIdx;
            }
            else if (followup_q["question_type"] === "checkbox") {
                let [newSteps, latestIdx] = CheckBox(followup_q, i);
                console.log(newSteps)
                steps = [...steps, ...newSteps];
                i = latestIdx;
            }
            else if (followup_q["question_type"] === "date") {
                // console.log(parseMultiple('12 January 2020', DateFormats))
                let [newSteps, latestIdx] = DateInput(followup_q, i+1);
                steps = [...steps, ...newSteps];
                i = latestIdx;
            }
        })
        return [steps, i];
    }
    const processID = (rawID) => {
        return rawID.replaceAll(',', '-').replaceAll('.', '-')
    }
    const RatingBubble = (q, i) => {
        const maxRange = q.maxRange;
        const options = q.options;
        const botMessage = q.question_text;
        let previousRatings = {}
        if (latestRetrievedForm) {
            if (latestRetrievedForm[`${q.questionID}`]) {
                previousRatings = latestRetrievedForm[`${q.questionID}`]
            }
        }
        let steps = [
            //Bot
            {
                id: i.toString(),
                message: botMessage,
                trigger: i.toString() + '-update' ,
            },
            {
                id: i.toString() + '-update',
                component: <BotMessageComponent q = {q} messageToDisplay = {botMessage}/>,
                replace: true,
                // message: q.question_text,
                trigger: (i+1).toString(),
            },
        ]
        i+=1;
        options.forEach(option => {
            let reuseBotMessage = '';

            const processedOptionID = processID(option);
            const littleRatingQuestion = {
                question_text: option,
                questionID: q.questionID + '.' + processedOptionID,
                question_type: 'rating_bubble_item',
            }
            console.log(previousRatings)
            console.log(processedOptionID)
            if (previousRatings[processedOptionID]) {
                console.log(previousRatings[processedOptionID])
            }
            const ratingQuestion = `Please respond from 1 to ${maxRange} for the following question: ${option}.`
            let newSteps = [
                // Bot
                {
                    id: i.toString(),
                    message: ratingQuestion,
                    trigger: previousRatings[processedOptionID] ? i.toString() + '-suffix' : i.toString() + '-update',
                },
                //Bot
                {
                    id: i.toString() + '-suffix',
                    component: <div style = {{fontStyle: 'italic'}}>You entered "{previousRatings[processedOptionID]}" previously</div>,
                    trigger: i.toString() + '-update' ,
                },
                {
                    id: i.toString() + '-update',
                    component: <BotMessageComponent q = {littleRatingQuestion} messageToDisplay = {ratingQuestion}/>,
                    replace:true,
                    // message: q.question_text,
                    trigger: q.questionID + '.' + processedOptionID,
                },
                //User
                {
                    id: q.questionID + '.' + processedOptionID,
                    user: true,
                    // trigger: q.questionID +  '.' + processedOptionID + '-update',
                    trigger: (i+1).toString(),
                    validator: (value) => {
                        const id = q.questionID + '.' + processedOptionID
                        if (!isNaN(value)) { //a number option
                            if (value <= maxRange) {
                                setValue(id, value);
                                return true
                            }
                            return `Invalid Range! Max: ${maxRange}`

                        }
                        return 'Invalid Input! Expected a number only!'
                    }
                },
                // //Form Update
                // {
                //     id:  q.questionID +  '.' + processedOptionID + '-update',
                //     component: <Update handleUpdate={handleUpdate} />,
                //     replace: true,
                //     trigger: (i+1).toString(),
                // }
            ]
            i+=1;
            steps = [...steps, ...newSteps];
        })
        return [steps, i];

    }
    const DateInput = (q, i) => {

        const botSuffix = "\nNote: you can enter January 05, 1990, 01/05/1990, 1/5/1990, Jan 5th 1990, jan 5 1990, or 1990/01/05."
        let botMessage = q.question_text;
        if (latestRetrievedForm) {
            if (latestRetrievedForm[`${q.questionID}`] != '') {
                const answer = latestRetrievedForm[`${q.questionID}`]
                botMessage += `. Your previous answer was \"${answer}\". Press Enter/Return to re-use the old answer.`
            }
        }

        let steps = [
            //Bot
            {
                id: i.toString(),
                message: botMessage,
                trigger: i.toString() + '-suffix' ,
            },
            //Bot
            {
                id: i.toString() + '-suffix',
                component: <div style = {{fontStyle: 'italic'}}>{botSuffix}</div>,
                trigger: i.toString() + '-update' ,
            },
            {
                id: i.toString() + '-update',
                component: <BotMessageComponent q = {q} messageToDisplay = {q.question_text + botSuffix}/>,
                replace:true,
                // message: q.question_text,
                trigger: q.questionID,
            },
            //User
            {
                id: q.questionID,
                user: true,
                // trigger: q.questionID + '-update',
                trigger: (i+1).toString(),
                validator: (value) => {

                    if (value || removeSpecialCharacters(value) != '' ) {
                        const formattedDate = parseMultiple(value, DateFormats);
                        console.log(formattedDate)
                        if (formattedDate) {
                            console.log(formattedDate);
                            setValue(q.questionID, formattedDate)
                            return true;
                        }
                        return "Invalid Date Input!";
                    }
                    else if (botMessage.includes('re-use')) {
                        return true;
                    }
                    return "Invalid Date Format!";
                }
            },
            // //Link the form
            // {
            //     id: q.questionID + '-update',
            //     component: <Update handleUpdate={handleUpdate} />,
            //     replace: true,
            //     trigger: (i+1).toString(),
            // }
        ]
        return [steps, i+1];
    }
    const DateFormats = [
        'yyyy-MM-dd',
        'yyyy-M-d',
        'yyyy/M/d',
        'yyyy M d',
        'yyyy MM d',
        'yyyy/MM/d',
        'yyyy-MM-d',
        'yyyy MM dd',
        'yyyy/MM/dd',
        'yyyy-MM-dd',
        'yyyy M dd',
        'yyyy/M/dd',
        'yyyy-M-dd',
        'yyyy-MM-dd HH:mm:ss',
        'yyyy-MM-dd HH:mm:ss A',
        'yyyy-MM-dd HH:mm',
        'yyyy-MM-dd HH:mm A',
        'MMMM d yyyy',
        'M/d/yyyy',
        'M d yyyy',
        'MM d yyyy',
        'MM/d/yyyy',
        'MM-d-yyyy',
        'MMMM dd, yyyy',
        'MMMM dd, yyyy',
        'MMMM dd yyyy',
        'MMMM do, yyyy',
        'MMMM do yyyy',
        'MMM dd, yyyy',
        'MMM do, yyyy',
        'MMM d, yyyy',
        'MMM d yyyy',
        'MMMM dd yyyy',
        'MMMM do yyyy',
        'MMM dd yyyy',
        'MMM do yyyy',
        'EEEE, MMMM dd yyyy, HH:mm',
        'EEEE, MMMM dd yyyy, HH:mm A',
        'EEEE, MMMM do yyyy, HH:mm',
        // 'EEEE, MMMM do yyyy, HH:mm A',
        'EEEE MMMM dd yyyy HH:mm',
        'EEEE MMMM dd yyyy HH:mm A',
        // 'EEEE MMMM do yyyy HH:mm',
        // 'EEEE MMMM do yyyy HH:mm A',
        'EEEE, MMMM dd yyyy, HH:mm:ss',
        'EEEE, MMMM dd yyyy, HH:mm:ss A',
        // 'EEEE, MMMM do yyyy, HH:mm:ss',
        // 'EEEE, MMMM do yyyy, HH:mm:ss A',
        'EEEE MMMM dd yyyy HH:mm:ss',
        'EEEE MMMM dd yyyy HH:mm:ss A',
        // 'EEEE MMMM do yyyy HH:mm:ss',
        // 'EEEE MMMM do yyyy HH:mm:ss A',
        'MM/dd/yyyy',
        'MM dd yyyy',
        'dd MMMM yyyy',
        'do MMMM yyyy',
        'yyyy MMMM dd',
        'yyyy-MM-dd HH:mm:ss',
      ];
    const parseMultiple = (
            dateString,
            formatString
        ) => {
            let result;
            for (let i = 0; i < formatString.length; i++) {
                result = parse(dateString, formatString[i], new Date());
                if (isValid(result)) {

                    const formattedTxt = format(result, "yyyy-MM-dd")
                    return formattedTxt;
                }
            }


            return null;
        };

    const thisform = useRef();
    const UploadPending = (props) => {
        const [submitted, setSubmitted] = useState(false);
        useEffect(() => {
            if (!submitted) {
                setWaitAction(true);
            }
        }, [submitted])
        const onSubmit = async (e) => {
            e.preventDefault();
            console.log('clicked');
            setSubmitted(true);
            props.triggerNextStep();
        }
        // console.log(botTriggerNext);
        // console.log(props.triggerNext + " inside uploadPending");
        const style = submitted? {color: 'green'} : {color: 'blue'}
        return <div >
            <p style = {style}>{submitted? "File Attachment Completed!": "File Attachment Pending..."}</p>
            <form onSubmit={onSubmit} ref = {thisform} >


            {!submitted && <div className="form-group">
                <button className="btn btn-success" >
                    Proceed without attaching files
                </button>
            </div> }
            </form>
        </div>
    }
    const temp = true;
    const FileUpload = (q, i) => {
        const botMessage = q.question_text + ". Please use the main form to complete upload. We will proceed when you attach a file."

        let previousFileUploadInfo = {}
        let previousFileName = ''
        let reuseBotMessage = botMessage;
        const isLastQuestion = q.questionID === renderFormQuestions.slice(-1)[0].questionID;
        const stepYReuse = isLastQuestion ? 'confirm-question' : (i + 2).toString()


        if (latestRetrievedForm) {
            if (latestRetrievedForm[`${q.questionID}`]) {
                previousFileUploadInfo = latestRetrievedForm[`${q.questionID}`]
                previousFileName = previousFileUploadInfo.storageURL.split('/').pop();
                reuseBotMessage = `You have uploaded \"${[previousFileName]}\" previously. Would you like to re-use this file?`

            }
        }

        let steps = [
            {
                id: i.toString(),
                message: reuseBotMessage,
                // message: q.question_text,
                trigger: reuseBotMessage.includes('re-use')
                ? q.questionID + '-reuse-user'
                : i.toString() + '-update'
            },
            //User
            {
                id: q.questionID + '-reuse-user',
                user: true,
                trigger: triggerSelect(i.toString() + '-updateReuseN',
                stepYReuse)
            },
            {
                id: i.toString() + '-updateReuseN',
                component: <Update handleUpdate={handleUpdate} />,
                replace:true,
                // message: q.question_text,
                trigger: i.toString() + '-original',
            },
            {
                id: i.toString() + '-original',
                message: botMessage,
                trigger: i.toString() + '-update'
            },
            {
                id: i.toString() + '-update', //update currentFormDisplayQuestion on frontend
                component: <BotMessageComponent q = {q} messageToDisplay = {botMessage}/>,
                replace:true,
                // message: q.question_text,
                trigger: (i+1).toString(),
            },
            {
                id: (i+1).toString(),
                component: <UploadPending/>,
                // asMessage: true,
                waitAction: true,
                trigger: (i+2).toString()
            },
        ];
        i+=2;
        return [steps, i];
    }
    const handleUpdate = (previousStep) => {
        const { id, value } = previousStep;
        console.log(id + ' ' + value);
        const yesCodeWords = ['y', 'yes']
        const noCodeWords = ['n', 'no']
        if (id.includes('.')) { //a checkbox answer
            let processedID = id.replace(/,/g,'-') //key cannot have comma
            let questionID = processedID.split('.')[0];
            const currentQuestion = formQuestionDict[questionID];

            if (id.includes('.other')) { //For "Other" checkbox
                setValue(id, value);
                // console.log(questionID);
                formQuestionDict[questionID].options.forEach(option => {
                    // console.log(otherCodewords + "-" + option);
                    if (otherCodewords.includes(option)) {
                        setValue(questionID+'.check'+option, true);
                    }
                })
        }
        else if (currentQuestion.question_type === 'rating_bubble') {

            // let processedID = id.replace(/,/g,'-') //key cannot have comma
            if (!isNaN(value)) { //a number option
                setValue(id, value);
            }
            // if (['y', 'yes'].includes(value.toLowerCase()))
            // {
            //     setValue(processedID, true)
            // }
            // else if (['no', 'n'].includes(value.toLowerCase()))
            // {
            //     setValue(processedID, false)
            // }
        }
        else {
            if (yesCodeWords.includes(value.toLowerCase()))
            {
                setValue(processedID, true)
            }
            else if (noCodeWords.includes(value.toLowerCase()))
            {
                setValue(processedID, false)
            }
            else {
                //add validation here (Cheng)
            }
        }


      }
      else if (id.includes('-reuse-user')) {
            const questionID = id.split('-reuse-user')[0]
            if (yesCodeWords.includes(value.toLowerCase()))
            {
                setValue(questionID + '-reused', true)
            }
            else if (noCodeWords.includes(value.toLowerCase()))
            {
                setValue(questionID + '-reused', false)
            }
            else {
                //add validation here (Cheng)
            }

      }
      else {
        const currentQuestion = formQuestionDict[id];
        console.log(currentQuestion)
        if (currentQuestion.question_type === 'short_answer' || currentQuestion.question_type === 'long_answer') {
            // if (value || value.replace(/\s/g, '') != '' )
            //     setValue(id, value)
        }
        else if (currentQuestion.question_type === 'multiple_choice') {
            console.log(currentQuestion)
            if (isNaN(value)) { //a text option
                const options = currentQuestion.options;
                options.forEach(option => {
                    if (option.toLowerCase() === value.toLowerCase()) {
                        setValue(id, option);
                    }
                } )

            }
            else { //is a number option
                setValue(id, currentQuestion.options[value-1]);
            }
        }
        else if (currentQuestion.question_type === 'date') {
            if (value || value.replace(/\s/g, '') != '' ) {
                const formattedDate = parseMultiple(value, DateFormats);
                console.log(formattedDate);
                setValue(id, formattedDate)
            }


        }
        else if (currentQuestion.question_type === 'checkbox_followup') {

            let processedID = id.replace(/,/g,'-') //key cannot have comma

            if (['y', 'yes'].includes(value.toLowerCase()))
            {
                setValue(processedID, true)
            }
            else if (['no', 'n'].includes(value.toLowerCase()))
            {
                setValue(processedID, false)
            }
        }

        else if (currentQuestion.question_type === 'file_upload'){
            console.log("THIS IS A FILE UPLOAD QUESTION")
        }


      }

    //   if (id ==== "email") {
    //     setValue(id, value.replace(" at ", "@").replace(/ /g, ""));
    //   } else if (id ==== "dob" && /[A-Za-z]/.test(value)) {
    //     const newval = parse(value, "MMMM do yyyy", new Date());
    //     setValue(id, format(newval, "yyyy-MM-dd"));
    //     // console.log(format(newval, "yyyy-MM-dd"));
    //   } else if (id ==== "degreeComplete" && /[A-Za-z]/.test(value)) {
    //     const newval = parse(value, "MMMM yyyy", new Date());
    //     setValue(id, format(newval, "yyyy-MM"));
    //   } else if (
    //     (id.includes("condition") ||
    //       id.includes("race") ||
    //       id.includes("educassit") ||
    //       id.includes("accessibility")) &&
    //     ["n", "nn", "nnn", "no", "nope"].includes(value.toLowerCase())
    //   ) {
    //     setValue(id, false);
    //   } else {
    //     if ((id.includes(".other") || id.includes(".federal")) && value !=== "") {
    //       console.log(id.match(/.+\./) + "check" + id.slice(-3));
    //       setValue(id.match(/.+\./) + "check" + id.slice(-3), true);
    //     }
    //     setValue(id, value);
    //   }
    };

    return (
      <ThemeProvider theme={theme}>
        {/* {console.log([...keys1, ...keys2])} */}
        {/* {console.log({...vals1, ...vals2})} */}
        {/* {console.log(part1_length + part2_length + 1 )} */}
        {allBotSteps.length > 3 && <ChatBot
          recognitionEnable={true}
          handleEnd={handleEnd}
          speechSynthesis={speechSynthesis}
          speechSwitch={speechSwitch}
          {...config}
          steps={allBotSteps}
        />}
      </ThemeProvider>
    );
  };
