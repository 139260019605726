import React, { useState, useEffect, useRef } from "react";
import Dropdown from 'react-bootstrap/Dropdown';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Plot from 'react-plotly.js';

const isNumeric = (n) => {
    return typeof n === 'number';
}

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <Button variant="outline-info"
        href=""
        ref={ref}
        onClick={(e) => {
            e.preventDefault();
            onClick(e);
        }}>{children}
        &#x25bc;</Button>
));
const CustomToggle2 = React.forwardRef(({ children, onClick }, ref) => (
    <Button variant="outline-warning"
        href=""
        ref={ref}
        onClick={(e) => {
            e.preventDefault();
            onClick(e);
        }}>{children}
        &#x25bc;</Button>
));
const CustomToggleChartSelection = React.forwardRef(({ children, onClick }, ref) => (
    <Button variant="outline-secondary"
        href=""
        ref={ref}
        onClick={(e) => {
            e.preventDefault();
            onClick(e);
        }}>{children}
        &#x25bc;</Button>
));

const CustomMenu = React.forwardRef(
    ({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
        const [value, setValue] = useState('');

        return (
            <div
                ref={ref}
                style={style}
                className={className}
                aria-labelledby={labeledBy}
            >
                <Form.Control
                    autoFocus
                    className="mx-3 my-2 w-auto"
                    placeholder="Type to filter..."
                    onChange={(e) => setValue(e.target.value)}
                    value={value}
                />
                <ul className="list-unstyled">
                    {React.Children.toArray(children).filter(
                        (child) =>
                            !value || child.props.children.toLowerCase().includes(value),
                    )}
                </ul>
            </div>
        );
    },
);

export const BivariableTableCharts = ({ latestAnswerDict }) => {

    const [currentEventKey, setCurrentEventKey] = useState(null)
    const [currentEventKey2, setCurrentEventKey2] = useState(null)

    const [allVariables, setAllVariables] = useState([]);
    const [xAxisLabel, setXAxisLabel] = useState('X Axis');
    const [yAxisLabel, setYAxisLabel] = useState('Y Axis');
    const [plotTitle, setPlotTitle] = useState('Plot');

    const excludedKeys = ['formID', 'formSubmissionID', 'createdAt', 'userID']

    useEffect(() => {
        if (latestAnswerDict) {
            let allKeys = Object.keys(latestAnswerDict);
            allKeys.sort();
            setAllVariables(allKeys)
        }
        console.log(latestAnswerDict)
    }, [latestAnswerDict])

    const plotData = () => {
        if (currentEventKey === null || currentEventKey === undefined) {
            return [];
        }

        const columnData = latestAnswerDict[currentEventKey];
        console.log(columnData)
        let isNum = false
        if (columnData)
            isNum = isNumeric(columnData[0]);

        if (currentEventKey2 === null || currentEventKey2 === undefined) {
            if (isNum) {
                // Histogram
                return [{
                    x: columnData,
                    type: 'histogram',
                }];
            } else {
                // Bar plot
                const uniqueValues = [...new Set(columnData)];
                const counts = uniqueValues.map(val => columnData.filter(x => x === val).length);
                return [{
                    x: uniqueValues,
                    y: counts,
                    type: 'bar',
                }];
            }
        } else {
            const columnData2 = latestAnswerDict[currentEventKey2];
            const isNum2 = isNumeric(columnData2[0]);
            console.log(isNum, isNum2)

            if (isNum && isNum2) {
                // Scatter plot
                return [{
                    x: columnData,
                    y: columnData2,
                    mode: 'markers',
                    type: 'scatter',
                    // marker: { size: 12 }
                }];
            } else if (!isNum && !isNum2) {
                // Bubble chart
                const uniquePairs = [...new Set(columnData.map((val, i) => [val, columnData2[i]].toString()))];
                const counts = uniquePairs.map(pair => columnData.filter((x, i) => [x, columnData2[i]].toString() === pair).length);
                return uniquePairs.map((pair, i) => {
                    const [x, y] = pair.split(',');
                    return {
                        x: [x],
                        y: [y],
                        mode: 'markers',
                        marker: {
                            size: counts[i] * 10,
                        },
                        type: 'scatter',
                    };
                });
            } else {
                // Box plot
                const boxData = isNum ? columnData : columnData2;
                const groupData = isNum ? columnData2 : columnData;
                return [{
                    y: boxData,
                    type: 'box',
                    name: 'Box Plot',
                    boxpoints: 'all',
                    jitter: 0.5,
                    whiskerwidth: 0.2,
                    fillcolor: 'cls',
                    line: {
                        color: 'black',
                    },
                    x: groupData,
                }];
            }
        }
    }
    return (
        <>
            <div className="row">
                {/* Input fields for x-axis label, y-axis label, and title */}
                <div className="col-md-4 mt-3">
                    <input 
                        type="text" 
                        className="form-control"
                        value={xAxisLabel} 
                        onChange={(e) => setXAxisLabel(e.target.value)} 
                        placeholder="X Axis Label" 
                    />
                </div>
                <div className="col-md-4 mt-3">
                    <input 
                        type="text" 
                        className="form-control"
                        value={yAxisLabel} 
                        onChange={(e) => setYAxisLabel(e.target.value)} 
                        placeholder="Y Axis Label" 
                    />
                </div>
                <div className="col-md-4 mt-3">
                    <input
                        type="text"
                        className="form-control"
                        value={plotTitle}
                        onChange={(e) => setPlotTitle(e.target.value)}
                        placeholder="Plot Title"
                    />
                </div>
            </div>
            <div className="row">
                <Dropdown className='col-md-4 mt-3' onSelect={(eventKey, event) => { setCurrentEventKey(eventKey) }}>
                    <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
                        Select Primary Variable {currentEventKey ? `(${currentEventKey}) ` : ' '}
                    </Dropdown.Toggle>

                    <Dropdown.Menu as={CustomMenu} >
                        {allVariables.map(primVar => {
                            if (!excludedKeys.includes(primVar))
                                return <Dropdown.Item eventKey={primVar} active={primVar == currentEventKey}>{primVar}</Dropdown.Item>
                        })}
                    </Dropdown.Menu>
                </Dropdown>


                <Dropdown className='col-md-4 mt-3' onSelect={(eventKey, event) => {
                    if (currentEventKey2 != eventKey) {
                        if (eventKey != 'None')
                            setCurrentEventKey2(eventKey);
                        else {
                            setCurrentEventKey2(null);

                        }
                        // setChartData(null)
                    }

                }}>
                    <Dropdown.Toggle as={CustomToggle2} id="dropdown-custom-components">
                        Select Secondary Variable {currentEventKey2 ? `(${currentEventKey2}) ` : ' (None)'}
                    </Dropdown.Toggle>

                    <Dropdown.Menu as={CustomMenu} >
                        <Dropdown.Item eventKey={'None'} active={currentEventKey2 == 'None'}>{'None'}</Dropdown.Item>

                        {allVariables.map(variable => {
                            if (!excludedKeys.includes(variable) && variable != currentEventKey) {
                                return <Dropdown.Item eventKey={variable} active={variable == currentEventKey2}>{variable}</Dropdown.Item>
                            }
                        })}
                    </Dropdown.Menu>
                </Dropdown>

            </div>
            <div className="row">


                <Plot
                    data={plotData()}
                    layout={{
                        width: 800,
                        height: 600,
                        title: plotTitle,
                        xaxis: { title: xAxisLabel },
                        yaxis: { title: yAxisLabel }
                    }}
                />
            </div>
        </>
    )
}
