import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyBW0dKgcQT_wOiuefSEzhl0opGFhxZVIDo",
    authDomain: "includes-5bac5.firebaseapp.com",
    projectId: "includes-5bac5",
    storageBucket: "includes-5bac5.appspot.com",
    messagingSenderId: "125756290283",
    appId: "1:125756290283:web:b4995f30e7dc1fcd67baae"
};

export const app = initializeApp(firebaseConfig);

export const db = getFirestore(app);

// Initialize Firebase Authentication and get a reference to the service
export const auth = getAuth(app);