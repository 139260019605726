import React, { useEffect, useState, useRef } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { validationSchema_m } from "../Components/Validations";
import { ChatbotMentor } from "../Chatbot/Chatbots";
import { format } from "date-fns";
// import TestSet from "../Components/TestSet";
import { Input, RadioGroup, Checkbox } from "../Components";
import { yupResolver } from "@hookform/resolvers/yup";
import "bootstrap/dist/css/bootstrap.min.css";
import { db } from "../Firebase/firebasedb";
import {
  collection,
  // addDoc,
  doc,
  getDoc,
  setDoc,
  getDocs,
  query,
  where,
  orderBy,
  limit,
} from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { Modal, Button } from "react-bootstrap";

// Initialize Cloud Firestore and get a reference to the service
const genderOptions = [
  { label: "Male", value: "1" },
  { label: "Female", value: "2" },
  { label: "Transgender Female", value: "3" },
  { label: "Transgender Male", value: "4" },
  { label: "Gender Non-Conforming", value: "5" },
  { label: "Prefer Not to Answer", value: "6" },
];

const callOptions = [
  { label: "Voice", value: "voice" },
  { label: "TDD", value: "TDD" },
];

const binaryOptions = [
  { label: "Yes", value: "yes" },
  { label: "No", value: "no" },
];

const latinoOptions = [
  { label: "Hispanic or Latino", value: "yes" },
  { label: "Not Hispanic or Latino", value: "no" },
];

const yearOptions = [
  { label: "First Year", value: "freshman" },
  { label: "Sophomore", value: "sophomore" },
  { label: "Junior", value: "junior" },
  { label: "Senior", value: "senior" },
  { label: "Graduate", value: "graduate" },
];

const parenteduOptions = [
  { value: "1", label: "High school or GED" },
  { value: "2", label: "Technical training" },
  { value: "3", label: "Associates degree" },
  { value: "4", label: "Bachelors degree (B.A. or B.S.)" },
  { value: "5", label: "Master's degree (M.A., M.S., etc.)" },
  {
    value: "6",
    label: "Doctorate (Ph.D., M.D., D.D.S., D.V.M., J.S.D., etc.)",
  },
  { value: "7", label: "I Don’t know" },
];

const incomeOptions = [
  { value: "1", label: "Less than $20,000" },
  { value: "2", label: "between $20,000 and $40,000" },
  { value: "3", label: "between $40,000 and $60,000" },
  { value: "4", label: "between $60,000 and $80,000" },
  { value: "5", label: "between $80,000 and $100,000" },
  { value: "6", label: "More than $100,000" },
  { value: "7", label: "Do not know" },
];

const raceOptions = [
  { name: "American Indian or Alaska Native" },
  { name: "Asian" },
  { name: "White" },
  { name: "Black or African American" },
  { name: "Native Hawaiian or Other Pacific Islander" },
  { name: "Other" },
];

const conditionOptions = [
  { name: "Asperger/Autism" },
  { name: "ADD/ADHD" },
  { name: "Deaf/Hard-of-Hearing" },
  { name: "Physical/Orthopedic/Mobility" },
  { name: "Systemic Health/Medical" },
  { name: "Psychological/Psychiatric" },
  { name: "Learning Disability" },
  { name: "Blind/Visual Impairment" },
  { name: "Speech Impairment" },
  { name: "Acquired/Traumatic Brain Injury" },
  { name: "Other" },
];

const accessibilityOptions = [
  { name: "Exam accommodations" },
  { name: "Reader of scribe services" },
  { name: "Note-taking services" },
  { name: "Alternative formats for course materials" },
  { name: "Early access to course content" },
  { name: "Preferred seating" },
  { name: "Other" },
];

const assistOptions = [
  { name: "Federal grant. Please specify:" },
  { name: "Federal Student loan" },
  { name: "Private loan" },
  { name: "Scholarship" },
  { name: "Other" },
];

const MentorForm = ({ navigation }) => {
  const auth = getAuth();
  const [user, setUser] = useState(null);
  const [mentorData, setMentorData] = useState(null);

  const [shouldRedirect, setShouldRedirect] = useState(false);
  const [redirectDestination, setRedirectDestination] = useState(null);
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        // const uid = user.uid;
        // ...
        setUser(user);
      } else {
        setRedirectDestination("/");
        setShouldRedirect(true);
      }
    });
    return () => unsubscribe();
  }, [auth]);

  useEffect(() => {
    if (user) {
      const docRef = doc(db, "Users", user.uid);
      getDoc(docRef).then((docSnap) => {
        if (docSnap.exists()) {
          console.log("Document data:", docSnap.data());
          const data = docSnap.data();
          // setMentorData(data);
          if (data.atype === "administrator") {
            setRedirectDestination("/dashboardadmin");
            setShouldRedirect(true);
          } else if (data.atype === "faculty") {
            setRedirectDestination("/dashboardfaculty");
            setShouldRedirect(true);
          }
        } else {
          // doc.data() will be undefined in this case
          console.log("No such document!");
          setRedirectDestination("/dashboardstudent");
          setShouldRedirect(true);
        }
      });
    }
  }, [user]);

  useEffect(() => {
    if (user) {
      const colMentor = collection(db, "MentorForm");
      const q = query(
        colMentor,
        where("studentID", "==", user.uid),
        orderBy("createdAt", "desc"),
        limit(1)
      );
      getDocs(q)
        .then((snapshot) => {
          const x = snapshot.docs[0].data();
          setMentorData(x);
        })
        .catch((err) => {
          setMentorData(true);
          console.log(err);
        });
    }
  }, [user]);

  const navigate = useNavigate();
  useEffect(() => {
    if (shouldRedirect) {
      navigate(redirectDestination);
    }		
  }, [shouldRedirect, redirectDestination, navigate]);

  const formOptions = { resolver: yupResolver(validationSchema_m) };
  const methods = useForm(formOptions);
  const { handleSubmit, reset, watch } = methods;
  const ifDifferent = watch("ifDifferent");
  const [botSubmit, setBotSubmit] = useState(false);
  // const [isChecked, setIsChecked] = useState(false);

  // const handleOnCheck = () => {
  //   setIsChecked(!isChecked);
  // };
  useEffect(() => {
    console.log(botSubmit);
    if (botSubmit === true) {
      console.log("TRUE!!!");
      thisform.current &&
        thisform.current.dispatchEvent(
          new Event("submit", { cancelable: true, bubbles: true })
        );
      // setBotSubmit(false);
    }
  }, [botSubmit]);

  function onSubmit(data, event) {
    console.log(event);
    event.preventDefault();
    // display form data on success
    console.log(data);
    // alert("SUCCESS!! :-)\n\n" + JSON.stringify(data, null, 4));
    // Add a new document with a generated id.
    writeToFirebase(data);
    // navigate("/dashboard");
    return false;
  }

  const generateMetorFormID = (data, allDocumentsSize) => {
    var currentDate = new Date().toString();
    var fullYear = new Date().getFullYear();

    if (!ifDifferent) {
      data.pcity = "";
      data.pemail = "";
      data.pmailingAddress = "";
      data.pstate = "";
      data.pcall = "";
      data.pcallType = "";
      data.pzip = "";
      data.ptelephone = "";
      data.pvoice = "";
    }
    setDoc(
      doc(db, "MentorForm", `FD${fullYear}${allDocumentsSize + 1000001}`),
      {
        createdPlatfrom: "WebApp",
        studentID: user.uid,
        createdAt: currentDate,
        firstName: data.firstName,
        lastName: data.lastName,
        streetNumber: data.mailingAddress,
        streetName: data.mailingAddress,
        city: data.city,
        state: data.state,
        zipCode: data.zip,
        callType: data.callType,
        call: data.call,
        gender: data.gender,
        birthdate: data.dob,
        usCitizen: data.citizen,
        usPermanent: data.resident,
        currentYearLevel: data.year,
        collegeName: "Unknown",
        major: data.major,
        gpa: data.gpa,
        dateOfYearCompletion: data.degreeComplete,
        transferStudent: data.transfer,
        veteran: data.veteran,
        ethnicity: data.ethnicity,
        race: data.race,
        healthCondition: data.condition,
        // healthConditionOther: data.condition.other,
        parentGuardian: data.guardian,
        isPermanentMailingAddress: data.ifDifferent,
        streetNumberP: data.pmailingAddress,
        streetNameP: data.pmailingAddress,
        cityP: data.pcity,
        stateP: data.pstate,
        zipCodeP: data.pzip,
        emailP: data.pemail,
        callTypeP: data.pcallType,
        callP: data.pcall,
        maleDegree: data.maleDegree,
        femaleDegree: data.femaleDegree,
        income: data.income,
        accessibility: data.accessibility,
        educassit: data.educassit,
      }
    ).then((docSnapShot) => {
      // console.log("Document written with ID: ", docSnapShot.data()); ???
      // alert("FORM SUBMITTED SUCCESSFULLY!");
      // navigate("/dashboard");
      handleShow();
      // handleClose();
    });
  };
  // const onSubmit = (e) => {
  //   e.preventDefault();
  //   alert("refresh prevented");
  // };
  function writeToFirebase(data) {
    // var currentDate = new Date().toString();
    var fullYear = new Date().getFullYear();
    console.log(fullYear);

    const documentSize = getDocs(collection(db, "MentorForm"));

    documentSize.then((allDocuments) => {
      console.log(allDocuments.size);
      generateMetorFormID(data, allDocuments.size);
    });

    // if (!ifDifferent) {
    //   data.pcity = "";
    //   data.pemail = "";
    //   data.pmailingAddress = "";
    //   data.pstate = "";
    //   data.ptdd = "";
    //   data.pzip = "";
    //   data.ptelephone = "";
    //   data.pvoice = "";
    // }
    // setDoc(doc(db, "MentorForm", `testID`), {
    //   createdPlatfrom: 'WebApp',
    //   studentID: user.uid,
    //   createdAt: currentDate,
    //   firstName: data.firstName,
    //   lastName: data.lastName,
    //   streetNumber: data.mailingAddress,
    //   streetName: data.mailingAddress,
    //   city: data.city,
    //   state: data.state,
    //   zipCode: data.zip,
    //   callType: "Voice",
    //   gender: data.gender,
    //   birthdate: data.dob,
    //   usCitizen: data.citizen,
    //   usPermanent: data.resident,
    //   currentYearLevel: data.year,
    //   collegeName: "Unknown",
    //   major: data.major,
    //   gpa: data.gpa,
    //   dateOfYearCompletion: data.degreeComplete,
    //   transferStudent: data.transfer,
    //   veteran: data.veteran,
    //   ethnicity: data.ethnicity,
    //   race: data.race,
    //   healthCondition: data.condition,
    //   healthConditionOther: data.condition.other,
    //   parentGuardian: data.guardian,
    //   isPermanentMailingAddress: data.ifDifferent,
    //   streetNumberP: data.pmailingAddress,
    //   streetNameP: data.pmailingAddress,
    //   cityP: data.pcity,
    //   stateP: data.pstate,
    //   zipCodeP: data.pzip,
    // }).then((docSnapShot) => {
    //   console.log("Document written with ID: ", docSnapShot.id);
    //   // alert("FORM SUBMITTED SUCCESSFULLY!");
    //   // navigate("/dashboard");
    //   handleShow();
    // });
  }

  const thisform = useRef();
  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
    navigate("/dashboardstudent");
  };
  const handleShow = () => {
    setShow(true);
  };

  // usePrompt("Are you sure you want to leave?", true);

  return (
    mentorData && (
      <div className="container">
        {console.log("mentorData", mentorData)}
        <div className="card m-3">
          <h5 className="card-header">Demographic Form</h5>
          <div className="card-body">
            <p className="card-text fw-bold mb-4">
              This form collects information about participants of the
              TAPDINTO-STEM program. Access to the data will be restricted to
              authorized personnel of the program. The data collected will only
              be reported in aggregation to offer maximal protection of your
              privacy.
            </p>
            <Modal show={show} onHide={handleClose}>
              <Modal.Header closeButton>
                <Modal.Title>Submission Successful!</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                Your form has been submitted successfully!
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                  Close
                </Button>
              </Modal.Footer>
            </Modal>

            <FormProvider {...methods}>
              <form onSubmit={handleSubmit(onSubmit)} ref={thisform}>
                <div className="row">
                  <Input
                    name="firstName"
                    label="First Name"
                    className="mb-3 col-md-4"
                    defaultValue={mentorData.firstName}
                  />
                  <Input
                    name="middleName"
                    label="Middle Name"
                    className="mb-3 col-md-4"
                    defaultValue={mentorData.middleName}
                  />
                  <Input
                    name="lastName"
                    label="Last Name"
                    className="mb-3 col-md-4"
                    defaultValue={mentorData.lastName}
                  />
                </div>
                <div className="row">
                  <Input
                    name="mailingAddress"
                    label="Street Address"
                    className="mb-3 col-md-6"
                    defaultValue={mentorData.streetName}
                  />
                  <Input
                    name="city"
                    label="City"
                    className="mb-3 col-md-2"
                    defaultValue={mentorData.city}
                  />
                  <Input
                    name="state"
                    label="State"
                    className="mb-3 col-md-2"
                    defaultValue={mentorData.state}
                  />
                  <Input
                    name="zip"
                    label="Zip"
                    className="mb-3 col-md-2"
                    defaultValue={mentorData.zipCode}
                  />
                </div>
                <div className="row">
                  <Input
                    name="email"
                    label="Email"
                    className="mb-3 col-md-3"
                    defaultValue={user.email}
                  />
                  {/* <Input */}
                  {/*   name="telephone" */}
                  {/*   label="Telephone" */}
                  {/*   className="mb-3 col-md-3" */}
                  {/* /> */}
                  <RadioGroup
                    label="Contact Type"
                    name="callType"
                    options={callOptions}
                    checked={mentorData.callType}
                  />

                  <Input
                    name="call"
                    label="Contact Number"
                    className="mb-3 col-md-3"
                    defaultValue={mentorData.call}
                  />
                  {/* <Input name="tdd" label="TDD" className="mb-3 col-md-3" /> */}
                  <Input
                    name="dob"
                    type="date"
                    label="Date of Birth"
                    className="mb-3 col"
                    defaultValue={mentorData.birthdate}
                  />
                </div>
                <div className="row">
                  <RadioGroup
                    label="Gender"
                    name="gender"
                    options={genderOptions}
                    className="mb-3 col-8 col-sm-6 col-md-4 col-lg-3"
                    checked={mentorData.gender}
                  />
                  <RadioGroup
                    label="Year"
                    name="year"
                    options={yearOptions}
                    checked={mentorData.currentYearLevel}
                  />

                  <RadioGroup
                    label="U.S. Citizen"
                    name="citizen"
                    options={binaryOptions}
                    checked={mentorData.usCitizen}
                  />
                  <RadioGroup
                    label="U.S. Permanant Resident"
                    name="resident"
                    options={binaryOptions}
                    checked={mentorData.usPermanent}
                  />
                </div>
                <div className="row">
                  <Input
                    name="major"
                    label="Major"
                    className="mb-3 col"
                    defaultValue={mentorData.major}
                  />
                  <Input
                    name="gpa"
                    label="Cumulative GPA"
                    className="mb-3 col-md-2"
                    defaultValue={mentorData.gpa}
                  />
                  <Input
                    name="degreeComplete"
                    label="Date of Degree Completion"
                    type="month"
                    className="mb-3 col"
                    defaultValue={mentorData.dateOfYearCompletion}
                  />
                  <RadioGroup
                    label="Transfer Student"
                    name="transfer"
                    options={binaryOptions}
                    checked={mentorData.transferStudent}
                  />
                </div>
                <div className="row">
                  <RadioGroup
                    label="Veteran"
                    name="veteran"
                    options={binaryOptions}
                    checked={mentorData.veteran}
                  />

                  <RadioGroup
                    label="Ethnicity (optional)"
                    name="ethnicity"
                    options={latinoOptions}
                    checked={mentorData.ethnicity}
                  />
                  <Checkbox
                    label="Race (Check all that apply, optional)"
                    groupname="race"
                    options={raceOptions}
                    className="col-sm-12 col-md-4 col-lg-4 mb-3"
                    checked={mentorData.race}
                  />
                  <RadioGroup
                    label="Male Parent/Guardian Education Level"
                    name="maleDegree"
                    options={parenteduOptions}
                    className="col-sm-12 col-md-6 col-lg-3 mb-3"
                    checked={mentorData.maleDegree}
                  />
                  <RadioGroup
                    label="Female Parent/Guardian Education Level"
                    name="femaleDegree"
                    options={parenteduOptions}
                    className="col-sm-12 col-md-6 col-lg-3 mb-3"
                    checked={mentorData.femaleDegree}
                  />
                  <RadioGroup
                    label="Parent/Household Annual Income (optional)"
                    name="income"
                    options={incomeOptions}
                    className="col-sm-12 col-md-6 col-lg-4 mb-3"
                    checked={mentorData.income}
                  />

                  <Checkbox
                    label="Condition (select one or more, optional)"
                    groupname="condition"
                    options={conditionOptions}
                    className="col-sm-12 col-md-4 col-lg-4 mb-3"
                    checked={mentorData.healthCondition}
                  />
                  {/* </div> */}
                  {/* <div className="row>"> */}
                  <Checkbox
                    label="Office of Accessibility/Disability Services"
                    groupname="accessibility"
                    options={accessibilityOptions}
                    className="col-sm-12 col-md-4 col-lg-4 mb-3"
                    checked={mentorData.accessibility}
                  />
                  <Checkbox
                    label="Educational Assistance"
                    groupname="educassit"
                    options={assistOptions}
                    className="col-sm-12 col-md-4 col-lg-4 mb-3"
                    checked={mentorData.educassit}
                  />
                </div>

                <div className="row>">
                  <Input
                    name="ifDifferent"
                    label="Permanent Mailing Address (if different from above)"
                    type="checkbox"
                    className="form-check col"
                    labelClass="form-check-label"
                    inputClass="form-check-input"
                    defaultChecked={mentorData.isPermanentMailingAddress}
                  />

                  {(!!ifDifferent || mentorData.ifDifferent) && (
                    <>
                      <div className="row">
                        <Input
                          name="pmailingAddress"
                          label="Local Mailing Address"
                          className="mb-3 col-md-6"
                          defaultValue={mentorData.streetNameP}
                        />
                        <Input
                          name="pcity"
                          label="City"
                          className="mb-3 col-md-2"
                          defaultValue={mentorData.cityP}
                        />
                        <Input
                          name="pstate"
                          label="State"
                          className="mb-3 col-md-2"
                          defaultValue={mentorData.stateP}
                        />
                        <Input
                          name="pzip"
                          label="Zip"
                          className="mb-3 col-md-2"
                          defaultValue={mentorData.zipCodeP}
                        />
                      </div>
                      <div className="row">
                        <Input
                          name="pemail"
                          label="Email"
                          className="mb-3 col-md-6"
                          defaultValue={mentorData.emailP}
                        />
                        {/* <Input */}
                        {/*   name="ptelephone" */}
                        {/*   label="Telephone" */}
                        {/*   className="mb-3 col-md-3" */}
                        {/* /> */}
                        <RadioGroup
                          label="Contact Type"
                          name="pcallType"
                          options={callOptions}
                          checked={mentorData.callTypeP}
                        />

                        <Input
                          name="pcall"
                          label="Contact Number"
                          className="mb-3 col-md-4"
                          defaultValue={mentorData.callP}
                        />
                      </div>
                    </>
                  )}
                </div>

                <div className="row">
                  <Input
                    name="guardian"
                    label="Name of Parent/Guardian"
                    className="mb-5 col-md-6"
                    defaultValue={mentorData.parentGuardian}
                  />
                </div>
                <p className="fw-bold mb-3">
                  Applicant: I hereby acknowledge that all information on and
                  with this application is true and accurate, and that I have
                  read and understand my responsibilities as a stipend
                  recipient.
                </p>
                <div className="row">
                  <Input
                    name="signature"
                    label="Sign with your initials"
                    className="mb-3 col-md-4"
                  />
                  <Input
                    name="date"
                    label="Date"
                    type="date"
                    className="mb-3 col-md-4"
                    value={format(new Date(), "yyyy-MM-dd")}
                    readOnly
                  />
                </div>

                <div className="mb-3">
                  <button
                    type="submit"
                    disabled={botSubmit}
                    // onClick={() => handleSubmit(onSubmit)}
                    className="btn btn-primary mr-1"
                  >
                    {botSubmit ? "Already submitted by bot!" : "Submit"}
                  </button>
                  <button
                    type="button"
                    onClick={() => reset()}
                    className="btn btn-secondary"
                  >
                    Reset
                  </button>
                </div>
              </form>
              {/* <TestSet /> */}
              <ChatbotMentor
                validationSchema={validationSchema_m}
                setFormEnd={setBotSubmit}
              />
            </FormProvider>
          </div>
        </div>
      </div>
    )
  );
};
export default MentorForm;
